$notifications-respond-width: 615px;

.notification-bar-container{
  overflow-y: auto;
  right: 0;
  left: auto;
  max-height: 400px;
  @media screen and (max-width: $notifications-respond-width){
    position: fixed;
    left: 0;
  }
  padding-top: 0;

  .no-notifications{
    padding: 10px;
    .fa{
      color: $highlight-color;
    }
  }
}

.notification-row {
  font-size: 0.9em;
  background-color: $background-color;
  @include transition(0.5s);
  .row{
    &:hover{
      .close-btn{
        display: block;
      }
    }

    &.notification-read{
      background-color: $inverted-text-color;
      @include transition(0.5s);
    }

    border-top: 1px solid $border-color;
    margin-left: 0;
    margin-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .notification-image {
    width: 75px;
    height: 50px;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: $notifications-respond-width){
      width: 50px;
      height: 35px;
    }
  }

  .notification-content{
    margin-top: 7px;
    div.info{
      width: 400px;
      display: inline-block;
    }
    .user-image{
      width: 30px;
      height: 30px;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
    .user-action{
      a{
        color: $highlight-color;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .message{
      font-weight: bold;
    }
  }

  .col-xs-3{
    padding-right: 5px;
  }

  .notification-functions{
    position: relative;
    > div {
      display: inline-block;
      vertical-align: middle;
    }
    .close-btn{
      display: none;
      position: absolute;
      top: -5px;
      right: 5px;
      padding: 0;
      color: darken($fade-color, 10%);

      &:hover{
        color: darken($fade-color, 20%);
      }
    }

    .from-now{
      float: right;
      margin-right: 10px;
      position: relative;
      top: 12px;
      font-size: 0.9em;
    }
  }
}

.notifications-dropdown-container{
  min-width: 500px;
  float: right;

  @media screen and (max-width: $notifications-respond-width){
    min-width: 100vw;
    float: left;
  }

  .notifications-footer{
    border-top: 1px solid $border-color;
  }
}