.more-property-info-dialog {
  width: 450px;

  @media screen and (max-width: 450px) {
    width: 100%;
  }

  .occupant-row {
    margin-top: 10px;
  }

  .occupant-row-attribute {
    max-width: 200px;
  }

  .last-edited-time {
    color: $light-highlight-color;
    font-size: 9px;
  }
}