.cancel-appointment-modal-container, .reject-requests-modal-container {

  .text-area{
    padding: 15px;
  }

  textarea.flat-input {
    width: 100%;
    height: 100px;
  }

  p,
  h2 {
    text-align: center;
  }

  h2 {
    color: #4D4D4D;
    line-height: 50px; 
    vertical-align: middle;
  }
}