.react-modal-background {
  align-items: center;
  background: #909292;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2000;
}

.react-modal-container {
  background: #fff;
  height: auto;
  width: 700px;
  overflow: auto;
}

.react-modal-header,
.react-modal-body,
.react-modal-footer {
  padding: 20px;
}

.react-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.react-modal-header {
  border-bottom: 1px solid #e6e6e6;
}