.listing-view-container{
  padding: 20px 0 0 0;
  margin: auto;
  text-align: center;

  .scheduling-errors-container{
    .btn-primary{
      margin-top: 10px;
    }
  }

  .loading-bar-container{
    margin: auto;
    background-color: transparent;
  }

  .loading-listing-container{
    margin-top: 20px;
  }

  .row-label{
    font-weight: bold;
  }

  .listing-container{
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;

    @media screen and (min-width: 650px){
      width: 565px;
    }

    @media screen and (max-width: 649px){
      width: 100%;
    }

    display: inline-block;
    vertical-align: middle;
    text-align: left;

    .listing-header-info{
      font-size: 1.1em;
      font-weight: bold;

      .listing-header-community-name{
        .fa {
          color: $icon-color;
        }
      }
    }

    .show-map-link{
      margin-left: 10px;
      .fa {
        color: $highlight-color;
      }
    }

    .listing-details-tab-content{
      .row{
        margin-top: 10px;
      }

      p{
        white-space: pre-line;
      }
    }
  }

  show-responsive{
    text-align: center;
    display: inline-block;
    vertical-align: top;

    @media screen and (max-width: 980px){
      display: block;
    }
  }

  scheduling-container{
    display: inline-block;
    vertical-align: top;
  }

  .schedule-container{
    display: block;
    width: 400px;
    margin-left: 30px;

    @media screen and (max-width: 962px){
      width: 100%;
      margin-left: 0;
    }

    .appointment-status-message {
      text-align: left;
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold;
    }

    .rescheduling-container{
      font-size: 1.2em;
      padding-left: 50px;
    }

    .renter-activation-prompt {
      margin-top: 30px;
      background-color: $background-color;
      border: 1px solid $border-color;
      padding: 20px;
      font-size: 16px;
    }

    .no-times-work {
      padding-left: 45px;
      text-align: left;
      font-size: 14px;
      font-weight: normal;
      margin-top: 10px;
    }
  }

  .listing-map-container{
    height: 350px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .angular-google-map-container {
      height: 350px;
    }
  }
}

@import "./autoAcceptSchedule";
@import "./primeTimeSchedule";
@import "./chooseTimesSchedule";
@import "./bookedAppointment";
@import "./requestedAppointment";
@import "./imageCarousel";
@import "./listingApplicationStatus";
@import "./renterNotInterestedModal";
@import "./renterApplicationModal";
@import "./appointmentRequirementsModal";
@import "./appointmentBookedModal";
@import "./schedulingSignup";
@import "./largeCarouselModal";
@import "./largeImageCarousel";
@import "./renterActivationContainer";
@import "./_listingDetailsSections.scss";
