.listing-ready-modal-container{
  text-align: center;
  .h1{
    color: $highlight-color;
  }

  .contact-us-link{
    padding-bottom: 30px;
  }

  .share-buttons{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .share-button{
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;

    .share-button-icon{
      cursor: pointer;
      margin: auto;
      text-align: center;
      font-size: 36px;
      width: 56px;
      height: 56px;
      border: 1px solid $close-button-color;
      border-radius: 50%;

      line-height: 56px;

      color: $close-button-color;

      &:hover{
        color: $highlight-color;
        border-color: $highlight-color;
      }
    }

    .share-button-text{
      margin-top: 5px;
      max-width: 110px;
      font-size: 16px;
    }
  }
}