@import '../../core/styles/_colors.scss';

.table-pagination-controls {
    padding: 8px 18px;

    .range {
        display: inline-block;
        margin-left: 8px;
        width: 120px;
    }

    .rows-per-page {
        label {
            color: $text-secondary;
            margin-right: 4px;
            margin-bottom: 0;
            font-weight: normal;
        }

        .MuiSelect-select:focus {
            background-color: $action-focus;
        }

        .MuiFilledInput-root:hover {
            background-color: $action-active;
        }
    }

    .results-navigation {
        .MuiIconButton-root {
            color: $slate-600;

            &:hover{
                background-color: $action-active;
            }
        }

        .MuiIconButton-root:disabled {
            color: $action-disabled
        }
    }
}