.messages-module {
  .unread-manager-section {
    font-size: 12px;
    font-weight: bold;
  }

  .dashboard-clickable {
    height: 32px;
  }
}
