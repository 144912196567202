.chat-window-container{
  z-index: 1002;
  @include transition(0.1s ease-in);
  border-left: 2px solid $border-color;
  opacity: 0;
  position: fixed;
  top: 50px;
  bottom: 0;
  right: -320px;
  width: 320px;
  background-color: $inverted-text-color;

  &.move-to-top {
    top: 0;
  }

  &.show{
    right: 0;
    opacity: 1;
    @include transition(0.1s ease-out);
  }

  .chat-window-inbox-container{
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    .inbox-header {
      padding-top: 10px;
      text-align: center;
      margin-bottom: 10px;
    }

    .close-btn{
      position: absolute;
      top: 0;
      right: 0;
      color: darken($border-color, 20%);
      &:hover{
        color: darken($border-color, 30%);
      }
    }

    .inbox-conversations{
      overflow-y: auto;
      position: absolute;
      bottom: 0;
      top: 80px;
      left: 0;
      right: 0;
      width: 100%;

      &.show-manager-picker {
        top: 40px;
      }

      .no-conversations-container{
        margin-left: 10px;
        font-size: 14px;
      }

      .more-conversations-container{
        text-align: center;
        margin-top: 10px;
        .btn{
          width: 150px;
        }
      }
    }
  }

  @import "./_conversationRow.scss";
}