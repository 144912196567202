.add-resident-package-modal {
  position: relative;
  width: 800px;
  padding: 15px;

  .messaging-body {
    padding: 15px 15px 5px;
  }
  .messaging-footer {
    .fa {
      font-size: 18px;
    }
    .md-button {
      transition: opacity .25s ease-in-out;
      &[disabled] {
        opacity: .5;
      }
    }
  }
  .sms-interface {
    .messaging-footer {
      padding-left: 15px;
    }
  }
  #bulk-formatting-options {
    margin-left: 10px;
  }
  .attachment-wrapper {
    margin-left: 15px;
  }

  div.protip-container {
    font-size: 12px;
    font-weight: bold;
    a {
      cursor: pointer;
      color: $highlight-color;
    }
  }

  .ta-scroll-window,
  textarea.chat-textarea {
    border-radius: 6px;
    border: 1px solid $background-color !important;
    width: 100% !important;
    padding: 10px;
    margin-top: 10px;
    cursor: text;
    font-size: 13px;
    &::placeholder {
      opacity: .5;
    }
  }
  .ta-scroll-window > .ta-bind {
    padding: 0;
  }
  .textarea-wrapper {
    position: relative;
    margin: 15px 15px 5px;
    .char-count {
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }

  .char-count {
    &.is-over-max {
      color: $danger-color;
    }
  }

  share-listing-menu,
  quick-replies, .conversation-text-area-footer-icon {
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
  }

  .send-message-icons {
    padding-left: 25px;
  }

  .cant-send-alert {
    font-size: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
  }

  md-input-container {
    padding-bottom: 0;
  }

  .attachments-picker-container {
    margin-top: 20px;
  }

  .fallback-toggle {
    font-size: 14px;
    a {
      cursor: pointer;
      margin-left: 10px;
      color: $highlight-color;
    }
  }
}
