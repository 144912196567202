.floorplan-editor-container{
  ul.dropdown-menu{
    max-height: 200px;
    overflow-y: auto;
  }

  .btn.dropdown-toggle{
    text-align: left;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 13px;
  }

  textarea{
    width: 340px;
    height: 100px;
    border: none;
    resize: none;
    margin-top: 8px;

    &::placeholder {
      color: $placeholder-text-color;
    }
  }

  .char-count {
    padding: 10px 0 10px 150px;
    &.is-over-max {
      color: $danger-color;
    }
  }

  .ide-document-form .ide-document-form-row {
    padding-top: 8px;
  }

  .ide-document-form .ide-document-form-row {
    padding-top: 8px;
  }

  .ide-document-form-label{
    width: 150px;
  }

  #basics, #floorplan-basics-header, button.btn-default.dropdown-toggle.ng-binding {
    color: $inactive-color;
  }

  .floorplan-beds-baths-row {
    .beds-label{
      width: 150px;
    }

    .baths-label{
      width: 66px;
    }

    .listing-beds{
      width: 120px;
    }

    .listing-baths{
      width: 120px;
    }
  }

  .floorplan-description-row{
    .ide-document-form-column{
      vertical-align: top;
    }
  }

  .floorplan-description-form{
    margin-bottom: 20px;
  }
  
  .no-left-spacing{
    left: auto !important;
  }
}
