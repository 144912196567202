user-interactions-card {
  width: 100%;
}

.user-interactions-card-container {
  width: 100%;

  a {
    color: $dark-text-color;
  }
  .listing-info-container{
    position: relative;
    top: -1px;
    cursor: pointer;
    margin-left: 5px;

    .listing-image{
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 75px;
    }

    .listing-info{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: $inverted-text-color;
      font-size: 0.9em;
      padding: 1px 2px 1px 2px;
      -webkit-font-smoothing: antialiased;
    }
  }
}
