.users-settings-container{

  a{
    cursor: pointer;
  }

  .table > tbody > tr > td{
    vertical-align: middle; 
  }

  md-input-container {
    padding: 0px;
  }
  
  md-select {
    position: relative; 
    bottom: 6px;
  }
  .admin-users-team-header{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: $medium-text-color;
    max-width: 200px;
    margin: auto;

    label {
      position: relative;
      right: 20px;
    }

    input.form-control{
      font-size: 14px;
      height: 32px;
    }

    .edit-team-name-link{
      margin-left: 5px;
    }
  }

  h4{
    text-align: center;
    font-weight: normal;
  }

  .users-settings-add-team{
    input.form-control{
      font-size: 14px;
      height: 32px;
    }
  }

  .cancel-team-name-edit{
    font-size: 14px;
  }

  .admin-users-team-add-user {
    width: 100%;
    button {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}