.preferences-form {
  .row-label {
    font-size: 12px;
  }

  .dropdown-row {
    padding: 5px 20px;
  }
}

.account-preferences-hours-container, .account-preferences-scheduling-container, .account-preferences-prescreening-container {
  max-width: 500px;

  select {
    width: 100%;
    margin-right: 10px;
  }

  md-switch {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .requirement-button {
    padding-left: 5px;
    padding-right: 5px;
    min-width: 45px;
  }

  .account-preferences-hour-rows {
    height: 308px;
  }
}

.account-preferences-notifications-container {
  max-width: 575px;
  md-switch {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.account-preferences-scheduling-container {
  max-width: 625px;

  .col-header {
    font-size: 14px;
    padding-left: 10px;

    i.red {
      color: $danger-color;
    }

    i.yellow {
      color: $bright-highlight-color;
    }
  }
}