.new-lead-modal-dialog-container {
  width: 500px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  md-toolbar {
    height: 120px;
    min-height: 120px;

    h2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 275px;
      overflow: hidden;
    }
  }

  .md-toolbar-tools {
    height: 120px;
    max-height: 120px;
  }

  .day-btn {
    min-width: 22px;
    min-height: 0;
    line-height: normal;
    font-size: 10px;
    padding: 0;
    margin: 0;
  }

  .note-time {
    margin-left: 10px;
  }

  .lead-attribute-label {
    font-weight: bold;
    width: 75px;
    text-align: left;
    color: $placeholder-text-color;
  }

  .lead-attribute-value {
    font-size: 12px;
    margin-left: 10px;
    text-align: left;
    color: $dark-text-color;
    font-weight: bold;
  }

  md-card {
    box-shadow: none;
  }

  .notes-input {
    min-width: 300px;
  }

  .lead-attribute {
    max-width: 400px;
  }
}