.property-report-stages-container {
  position: absolute;
  top: 142px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: auto;
}

.property-report {
  padding-top: 40px;
  overflow-x: hidden;
  background: $background-color;
  height: 100%;

  .property-report-filters-modes {
    position: relative;
    top: -10px;
    width: 350px;
    margin-left: 10px;

    md-tabs-canvas.md-center-tabs .md-tab {
      min-width: 110px;
      text-transform: none;
      font-size: 12px;
    }
  }

  .tooltip.prospect-export-failure-tooltip .tooltip-inner {
      min-width: 100px;
      max-width: 100%;
  }

  .property-report-results-count {
    margin: 10px  0 10px 40px;
    font-weight: bold;
    padding: 8px 0;
  }

  .property-report-table-container {
    margin-left: 10px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 172px;
    overflow-y: auto;
    overflow-x: auto;
    padding-bottom: 20px;

    /* This is used to prevent material from hijacking button content style and making it look "out of place" */
    .edit-price-button {
      color: inherit;
    }

    .edit-price-loading {
      /* Added negative margin to properly position loading icon, had issues with this. */
      margin-top: -34px;
      .md-inner .md-gap {
        border-top-color: $fade-color;
        border-bottom-color: $fade-color;
      }

      .md-inner .md-left .md-half-circle {
        border-left-color: $fade-color;
        border-top-color: $fade-color;
      }

      .md-inner .md-right .md-half-circle {
        border-right-color: $fade-color;
        border-top-color: $fade-color;
      }

    }
    .price-button {
      height: 30px;
      min-height: 25px;
      padding: 0 2px;
      margin: 0 2px;
      display: flex;
      font-size: inherit;
      font-family: inherit;
      justify-content: center;
      align-items: center;
      line-height: 16px;
    }

    .rent-matrix-button {
      width: 30px;
      max-width: 30px;
      min-width: 30px;
      min-height: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
    }

    .unit-price-container {
      display: flex;
      align-items: center;
    }

    .unit-price-container .unit-price {
      line-height: 30px;
      margin-right: 4px;
    }

    .edit-price-symbol {
      margin: 0 2px;
    }

    .edit-price {
      display: flex;
      align-items: center;
    }

    .editing-price-action-button {
      width: 25px;
      min-width: 30px;
    }

    .edit-price-input {
      width: 80px;
      max-width: 80px;
    }

    .unit-visibility-container {
      width: 100px;
      height: 20px;
      display: inline-flex;
      align-items: center;
    }

    .edit-visibility-input {
      max-width: 80px;
      align-self: flex-start
    }

    .edit-visibility-loading {
      height: 40px;
      margin-left: 10px;
      align-self: flex-end;
      top: 4px;
    }

    table {
      border: 1px solid $border-color;
      background: $white;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
      min-width: 1000px;

      td, th {
        white-space: nowrap;
        vertical-align: middle;
      }
    }

    md-checkbox {
      margin: 0;
    }

    .tooltip {
      .tooltip-arrow {
        border-right-color: #545454;
      }
      .tooltip-inner {
        background-color: #545454;
        min-width: 300px;
      }
    }

    .sort-ascending:before {
      content: '\25B2';
    }

    .sort-descending:before {
      content: '\25BC';
    }
  }

  .property-report-filters-container {
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;

    md-input-container {
      padding-bottom: 15px;
    }
    .refresh-units-button
    {
      margin-left: 10px;
      font-size: 14px;
    }
    .property-report-message-banner {
      color: $highlight-color;
      font-size: 10px;
      padding-bottom: 10px;
      min-height: 20px;
      .warning {
        color: $danger-color !important;
      }
    }
  }

  .waitlist-filter {
    padding-bottom: 0;
  }

  .prospect-icon-container {
    width: 15px;
    display: inline-block;
    vertical-align: middle;

    .fa-mobile {
      font-size: 15px;
      position: relative;
      top: 1px;
    }
  }

  i.export-log-success {
    color: #212121;
  }

  i.export-log-failed {
    color: $danger-color;
  }

  .icon-td {
    min-width: 110px;
  }

  .report-message {
    text-align: center;
  }

  .disabled-cursor {
    cursor: not-allowed
  }
}
