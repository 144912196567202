.scheduling-error-overlay-container {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $inverted-text-color;

  text-align: center;

  z-index: 1000;

  .md-big {
    max-width: 400px;
  }
}