.typing-indicator {
  $ti-color-bg: #E6E7ED;

  background-color: $ti-color-bg;
  width: auto;
  border-radius: 25px;
  padding: 10px;
  display: table;
  position: relative;
  animation: 2s bulge infinite ease-out;

  &:before, &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -1px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $ti-color-bg;
  }
  &:after {
    height: 5px;
    width: 5px;
    left: -5px;
    bottom: -5px;
  }
  span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}){
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}
@keyframes blink{
  50% {
    opacity: 1;
  }
}
@keyframes bulge{
  50% {
    transform: scale(1.05);
  }
}