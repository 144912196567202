.kanban-section { 
    overflow: hidden;
    max-height: none;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 4px;

    &.active-section {
        overflow-y: auto;
        position: relative;

        .kanban-section-inner {
            padding-bottom: 10px;
        }
    }
}