@import 'src/LegacyAngularApp/legacy-angular-app/core/styles/_colors.scss';

.dialog-profile-header {
    display: flex
}

.dialog-profile-header-text {
    margin-left: 16px;
}

.dialog-profile-header-text{
    .prospect-name {
        font-size: 16px;
        color: rgba($black, .87)
    }

    .connected-profile-count {
        font-size: 14px;
        line-height: 20px;
        color: rgba($black, .64)
    }
}

.profile-status {
    &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid $slate-400;
        display: inline-block;
        margin-right: 7px;
    }

    &.red:before {
        background-color: $danger-color;
        border-color: $danger-color;
    }
      
    &.yellow:before {
        background-color: $bright-highlight-color;
        border-color: $bright-highlight-color;
    }
      
    &.green:before {
        background-color: $dark-highlight-color;
        border-color: $dark-highlight-color;
    }
}