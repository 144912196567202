.new-team-member-modal{
  a{
    cursor: pointer;
  }

  .modal-header{
    background-color: $background-color;
  }

  .modal-body{
    padding-top: 10px;
    .create-by-selector{
      margin-bottom: 10px;
      .username-selector{
        margin-left: 5px;
      }
    }

    input.form-control{
      background-color: $border-color;
      color: $medium-text-color;
      border: none;
      border-radius: 6px;
      height: 30px;
    }
  }

  .add-user-form-row{
    margin-bottom: 10px;
  }

  .modal-footer{
    .alert{
      margin-top: 20px;
      text-align: center;

      &.username-matches-error{
        margin-top: 0;
      }
    }
  }
}