md-dialog.manager-preferences-modal-container {
  width: 700px;
  position: absolute;
  top: 60px;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;

  max-height: 550px;

  md-toolbar.md-default-theme {
    background-color: $inverted-text-color;
    border-bottom: 1px solid $border-color;
  }

  md-sidenav.md-sidenav-left {
    width: 170px;
    min-width: 170px;
    max-width: 170px;
    background-color: $background-color;

    md-content.md-default-theme {
      background-color: $background-color;
    }
  }

  .content-container {
    overflow-x: hidden;
  }

  .manager-select {
    padding: 10px;
  }

  .preferences-tab {
    p {
      color: $lighter-text-color;
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .md-actions {
    border-top: 1px solid $border-color;
    background-color: $background-color;
  }
}

.manager-scheduling-preferences-container {
  max-width: 400px;
  margin: auto;

  .md-label {
    font-size: 14px;
  }

  .scheduling-sliders {
    margin: auto;
    max-width: 360px;
  }

  .col-labels {
    margin-right: 40px;
  }

  .col-sliders {
    text-align: center;
  }

  .sliders {
    margin-top: 20px;
  }

  .slider-row {
    font-size: 14px;
    height: 60px;
  }
}

.pill {
  text-align: center;
  margin: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 165px;
  height: 80px;
  border: 1px solid $border-color;
  border-radius: 8px;
  font-size: 16px;
  padding: 8px;
  line-height: 30px;
  cursor: pointer;
  font-weight: bold;

  color: $dark-text-color;

  &:hover {
    background-color: $background-color;
  }

  &.selected {
    background-color: $highlight-color;
    color: $inverted-text-color;
    border: none;
  }
}

.vertical-tabs {
  .vertical-tab {
    height: 45px;
    background-color: transparent;
    text-align: left;
    line-height: 45px;
    padding-left: 20px;
    cursor: pointer;

    &:hover {
      background-color: darken($background-color, 5%);
    }

    &.vertical-tab-selected {
      background-color: $highlight-color;
      color: $inverted-text-color;

      cursor: default;
    }
  }

  .vertical-tab-text {
    font-size: 14px;
  }
}