.connect-calendar-container{
  .fa-google{
    color: #0266C8;
  }

  .connect-calendar-header{
    color: $highlight-color;
    font-weight: bold;
    border-bottom: 1px solid $border-color;
    text-align: left;
    padding-bottom: 15px;
  }

  .connect-calendar-row{
    text-align: left;
    margin-bottom: 10px;
    > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .connect-calendar-icon{
    width: 32px;
    text-align: right;
    padding-right: 10px;
  }

  .connect-calendar-name{
    width: 80px;
    text-align: left;

    .connect-calendar-company-name{
      font-size: 1.2em;
    }

    .connect-calendar-product-name{
      color: $medium-text-color;
    }
  }

  .connect-calendar-button{
    button {
      padding-top: 3px;
      width: 85px;
      height: 28px;
      font-weight: bold;
    }
  }
}