.appointments-container{
  height: 100%;

  .renter-appointments-agenda-view{
    display: none;

    @media screen and (max-width: 450px){
      display: block;
    }

    text-align: left;
    padding-top: 50px;
    max-width: 450px;

    color: $medium-text-color;

    .header-text{
      text-align: left;
      font-size: 18px;
    }

    .no-appointments-row{
      padding-left: 10px;
      text-align: left;
    }

    .small-listing-preview-container{
      width: 110px;
      margin-right: 10px;
    }

    .condensed-renter-appointment-container{
      > div {
        display: inline-block;
        vertical-align: middle;
      }

      padding: 15px;
    }

    .condensed-listing-info-price{
      font-size: 16px;
    }

    .condensed-appointment-functions{
      @media screen and (min-width: 380px){
        float: right;
        text-align: right;
      }
    }
  }

  loading-bar{
    position: fixed;
    bottom: 5px;
    left: 70px;
    z-index: 999;

    @media screen and (max-width: 850px){
      left: 5px;
      bottom: 30px;
    }
  }

  .h4, .h5{
    text-align: center;
  }

  .header-text {
    background: $border-color;
    font-weight: bold;
    padding: 5px;
    font-size: 14px;
    text-align: center;
  }

  .header-text-small{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
  }

  .no-appointments-row{
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .map-row{
    display: none;

    @media screen and (min-width: 450px){
      display: block;
    }
  }

  .map-col-sidebar{
    position: relative;

    .fa-calendar, .fa-clock-o {
      color: $highlight-color;
    }

    border-left: solid 1px $border-color;

    .all-appointments-view{
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      overflow-y: auto;
    }

    .confirmed-appointments, .pending-appointments, .visited-appointments {

    }
  }

  renter-appointment-view{
    .listing{
      margin: auto;
      margin-top: 5px;
    }
  }

  .selected-appointment-view{
    margin-left: 4px;
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 6px;

    .back-button{
      border-bottom: 1px solid $border-color;
      padding: 5px;
      font-size: 14px;
    }

    .appointment-buttons{
      padding: 10px;
      text-align: center;

      .btn{
        margin-top: 5px;
        width: 100%;
      }
    }
  }

  .appointment-date{
    font-weight: bold;
    text-align: center;
    font-size: 16px;
  }

  .appointment-time{
    text-align: center;
    font-size: 1.1em;
  }

  .no-pending-appointment-modal-container{
    background: $inverted-text-color;
    width: 250px;

    .modal-body{
      > div{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .search-logo img{
        width: 115px;
      }
    }
  }
}
