@import "../_colors.scss";
.ProspectsMissingFieldsForm {
  &__error {
    border: 1px solid #fbaeae;
    background-color: #fffafa;
    border-radius: 4px;
    padding: 8px;
  }

  &__header {
    display: flex;
    height: 40px;
    margin-bottom: 5px;

    &__icon-container {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__text-container {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    font-size: 24px;
  }

  &__content {
    padding: 8px;
  }
}
