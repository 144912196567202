.team-member-appointments-container{
  margin-bottom: 10px;
  width: 280px;

  .team-member-appointments-header{
    font-size: 18px;
    font-weight: bold;
    color: $highlight-color;
    margin-bottom: 5px;
  }

  .user-listing-card-container{
    width: 280px;
  }

  .team-member-appointments-section-header{
    font-size: 14px;
    color: $medium-text-color;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .formatted-time-col{
    color: $medium-text-color;
    font-weight: bold;
  }

  .request-count-display{
    top: -2px;
    position: relative;
    font-size: 14px;
    color: $medium-text-color;
    display: inline-block;
  }

  .team-member-appointments-badge{
    width: 20px;
    height: 20px;
    background-color: $danger-color;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    color: $inverted-text-color;
    display: inline-block;
  }

  .team-member-appointments-section{
    margin-bottom: 20px;
  }
}