.marketing-tools-craigslist-form {
  .marketing-tools-craigslist-row {
    > div:first-child {
      width: 200px;
    }

    > div:nth-child(2) {
      width: 250px;
    }
  }
}
