.preview-container{
  margin: auto;
  .listing{
    margin: auto;
  }

  .save-button{
    margin-top: 15px;
    text-align: center;

    .btn-save{
      min-width: 200px;
    }
  }

  .loader{
    text-align: center;
    margin-top: 10px;
  }

  .loading-bar-container{
    margin: auto;
  }
}