.schedule-container {
  margin-bottom: 20px;

  @media screen and (max-width: 900px) {
    border-bottom: 1px solid $border-color;
  }

  .scheduling-offered-by-container {
    margin-left: 4px;
  }
}

.scheduling-view {
  padding: 5px;

  .btn-primary {
    background-color: $inverted-text-color;
    color: $highlight-color;
    border: 1px solid $highlight-color;

    &.selected {
      background-color: $highlight-color;
      color: $inverted-text-color;
      outline: none;
    }
  }

  @media screen and (max-width: 425px) {
    width: 320px;

    .md-button {
      max-width: 240px;
    }

    .have-account-link {
      display: none;
    }

    .btn-social {
      padding-left: 20px;
    }
  }

  .scheduling-view-header {
    max-width: 320px;
    margin-bottom: 30px;
    text-align: left;

    @media screen and (max-width: 425px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    h2 {
      text-align: left;
    }
  }

  .scheduling-view-section {
    max-width: 320px;
    position: relative;
    text-align: left;

    @media screen and (max-width: 400px) {
      max-width: 100%;
    }
  }

  .scheduling-view-content {
    @media screen and (max-width: 425px) {
      max-width: 100%;
    }
  }

  .scheduling-view-icon {
    background-color: $inverted-text-color;
    left: -50px;
    top: 8px;
    position: absolute;
    text-align: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 26px;
    line-height: 28px;

    color: $fade-color;
    border: 1px solid $fade-color;

    @include transition(0.5s);

    &.completed {
      @include transition(0.5s);
      color: $highlight-color;
      border: 1px solid $highlight-color;
    }

    .fa {
      font-size: 24px;
    }

    .fa-calendar {
      font-size: 20px;
    }
  }

  .scheduling-switch-views-link {
    margin-top: 10px;
  }

  .date-selection-btn {
    width: 85px;
    height: 60px;
    margin: 5px;
  }

  .time-selection-btn {
    width: 85px;
    height: 35px;
    margin: 5px;
  }

  .btn-social {
    width: 150px;
    margin: 5px;
  }

  md-input-container {
    padding-bottom: 0;
  }

  .scheduling-requirements-section {
    margin-bottom: 10px;

    p {
      margin-bottom: 0;
    }
  }

  .move-in-date-row {
    margin-top: 5px;
  }

  .left-checkbox {
    margin-right: 19px;
  }

  .submit-buttons {
    width: 100%;
    margin-top: 10px;

    .md-button {
      margin-left: 0;
      margin-bottom: 10px;
      width: 100%;
      min-width: 100%;
    }
  }

  .scheduling-view-line {
    top: 16px;
    left: -34px;
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: $border-color;
  }

  .complete-check {
    color: $highlight-color;
  }

  .error-messages-container {
    color: $danger-color-soft;
  }

  .selected-time-display-auto-accept {
    button {
      margin: 0;
      width: 100%;
      min-width: 100%;
    }
  }

  .agree-to-terms{
    font-size: 10px;
  }
}

.scheduling-offered-by-container {
  text-align: left;
  margin-top: 20px;
  margin-left: 40px;
  margin-bottom: 10px;
}

@import "schedulingManager";
@import "schedulingErrorOverlay";
