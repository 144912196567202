.lead-history-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .lead-history-tables {
    overflow-y: auto;

    position: absolute;
    top: 75px;
    bottom: 0;
    left: 0;
    right: 0;

    td {
      width: 110px;

      &.checkbox-td {
        width: 45px;
      }

      &.indicator-td {
        width: 60px;
      }
    }
  }
}

.lead-history-table-container {
  .icon-td {
    width: 80px;
    .fa {
      font-size: 14px;
    }

    .prospect-icon-container {
      width: 15px;
      display: inline-block;
    }

    .fa-tablet {
      position: relative;
      top: 1px;
    }
  }

  .name-td {
    width: 150px;
  }

  .checkbox-td {
    width: 45px;
  }
}