.slide-out-panel {
  position: absolute;

  top: 0;
  bottom: 0;
  left: -350px;
  width: 500px;

  background-color: $inverted-text-color;
  z-index: 1003;

  border-right: 2px solid $border-color;

  overflow: auto;

  @include transition(left 0.25s);

  &.is-open {
    @include transition(left 0.25s);

    left: 150px;
  }

  &.is-open-multiteam {
    @include transition(left 0.25s);

    left: 215px;
  }
}
