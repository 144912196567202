.add-activity-modal-container {
  .add-note-input-container {
    margin: 0 20px;

    .add-note-input {
        width: 450px;
    }
  }

  .add-reminder-input-container {
    .add-reminder-input {
      width: 450px;
    }
  }
}
