.user-listing-card-container{
  background-color: $inverted-text-color;
  color: $medium-text-color;
  margin-left: 0;
  margin-right: 0;
  //border: 1px solid $border-color;
  width: 350px;
  height: 75px;

  a{
    cursor: pointer;
  }

  .col-xs-8{
    padding: 0;
    cursor: pointer;
    max-width: 217px;

  }

  .col-xs-4{
    padding: 0;
  }

  .listing-info-container{
    position: relative;
    top: -1px;
    cursor: pointer;

    .listing-image{
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 75px;
    }

    .listing-info{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: $inverted-text-color;
      font-size: 0.9em;
      padding: 1px 2px 1px 2px;
      -webkit-font-smoothing: antialiased;
    }
  }
}