.todo-list-item-container {
  width: 100%;
  background-color: #F6EDA8;
  padding: 10px 15px 10px 10px;
  position: relative;

  $box-shadow: 2px 2px 2px #ccc;

  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;

  &:hover {
    background-color: darken(#F6EDA8, 10%);
  }

  .todo-list-item-click-watcher {
    background-color: transparent;
    position: absolute;
    left: 35px;
    right: 0;
    bottom: 0;
    top: 0;
    cursor: pointer;
  }

  .fa-caret-right, .fa-caret-down {
    font-size: 18px;
  }

  .todo-list-item-name {
    padding-left: 15px;
    padding-right: 5px;
  }

  .todo-list-item-arrow {
    cursor: pointer;
    &:hover {
      color: $highlight-color;
    }
  }

  .todo-list-item-conversation {
    position: relative;
    height: 0;
    margin: 0;
    -webkit-transform: translateZ(0);

    md-card {
      height: 100%;
      position: relative;
    }

    @include transition(height 0.6s);

    &.expanded {
      @include transition(height 0.6s);
      margin: 10px 5px;
      height: 75vh;
      padding-bottom: 25px;
    }

    .todo-list-item-goto {
      padding-right: 8px;
      text-align: right;
      font-size: 14px;
    }
  }
}