.open-house-modal-container {
  .modal-header {
    text-align: center;
    h4 {
      color: $highlight-color;
    }
  }

  .modal-footer {
    border: none;
  }

  .allow-other-requests-toggle {
    text-align: center;
    font-size: 14px;
    padding-bottom: 5px;

    .checkbox-label {
      margin-right: 10px;
    }
  }

  .day-rows {
    text-align: center;
  }

  .day-row {
    text-align: center;
    border-bottom: 1px solid $border-color;
  }

  time-slider {
    .slider-container, .available-check {
      display: inline-block;
    }

    .available-check {
      margin-left: 25px;
    }
  }

  .alert {
    max-width: 600px;
    margin: auto;
    text-align: center;
  }

  .times-display {
    margin-bottom: 5px;
    font-size: 1.1em;
  }

  .day-title {
    font-size: 1.2em;
    font-weight: bold;
    padding: 5px;
  }

  .slider-horizontal {
    width: 300px;
  }

  .loading {
    text-align: center;
  }
}