.sync-calendar-dialog {
  .sync-calendar-content {
    margin: auto;
    text-align: center;
    max-width: 375px;
  }

  .sync-calendar-label {
    font-size: 16px;
    text-align: left;
  }

   .sync-calendar-button {
     text-align: left;
   }

  .fa-calendar {
    margin-right: 6px;
    color: $highlight-color;
    font-size: 20px;
  }
}