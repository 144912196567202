.deactivate-listing-modal-container{
  .modal-header{
    text-align: center;
    padding-bottom: 0;
    border-bottom: none;
    h5{
      font-size: 14px;
      font-weight: bold;
      color: $highlight-color;
    }
  }

  .modal-body{
    padding-top: 5px;
  }

  .deactivate-message-container{
    textarea{
      width: 100%;
      height: 125px;
      resize: none;
      font-size: 14px;
      padding: 5px;
    }
  }

  .modal-footer {

    .danger {
      color: $danger-color;
    }

    .cancel-appointments-checkbox {
      font-size: 90%;
    }
  }
}