.selected-result-container{
  overflow-y: auto;
  z-index: 100;

  .actions{
    text-align: center;
  }

  .schedule-button{
    background-color: $highlight-color;
    width: 100%;
    border: 1px solid $medium-text-color;
    border-radius: 0;
    height: 40px;
  }

  .btn-link{
    font-size: 1.0em;
  }

  .location-section-header{
    h4{
      margin-bottom: 5px;
      margin-left: 10px;
    }
    border-bottom: 1px solid $border-color;

    .fa{
      color: $icon-color;
    }
  }
}