.auth-modal{
  text-align: center;
  color: $medium-text-color;

  .auth-header{
    font-size: 14px;
    text-align: left;
    border-bottom: none;
    padding: 10px 10px 0 10px;

    h3{
      margin-bottom: 0;
      margin-top: 0;
    }

    > div{
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }

  .auth-body{
    padding: 0 20px 10px 20px;

    h3{
      margin-top: 0;
    }

    .divider-right{
      border-right: 1px solid $border-color;
    }

    .btn-social{
      max-width: 200px;
      margin: 0 auto 6px auto;

      &:hover, &:focus{
        color: #fff;
      }
    }

    .loading-indicator{
      margin-top: 10px;
    }

    .or{
      color: $fade-color;
      font-size: 1.3em;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .form-control{
      background-color: $border-color;
      box-shadow: none;
      border-radius: 6px;
      height: 40px;
      border: none;
      font-size: 1.2em;
      max-width: 268px;
      margin: 5px auto 5px auto;

      &:hover, &:active, &:focus{
        border: none;
      }
    }

    .error-message{
      margin-top: 10px;
    }

    .login-button-container{
      text-align: center;
    }

    .btn-login{
      font-size: 1.3em;
      width: 100px;
    }

    .btn-social{
      :first-child{
        border-right: none;
      }
    }

    .forgot-password-instructions{
      margin-bottom: 10px;
    }

    .forgot-password-email-field{
      margin-bottom: 10px;
    }

    .forgot-password-buttons{
      margin-bottom: 10px;

      .cancel-button-container{
        margin-top: 5px;
      }
    }

    .back-to-login-link{
      margin-top: 10px;
    }

    .loading-bar-container{
      margin: auto;
    }
  }

  .icon{
    display: inline-block;
    background: url('/images/icon.png');
    background-size: cover;
    width: 80px;
    height: 80px;
  }
}

.signup-modal{
  .modal-body {
    .btn-login{
      margin-top: 10px;
    }

    .alert{
      margin-top: 10px;
    }

    .agree-to-terms{
      text-align: center;
      margin-top: 15px;
    }

    .checkbox-row{
      margin: auto;
      text-align: left;

      .checkbox-container{
        text-align: right;
      }

      .checkbox-label{
        padding: 0;
      }
    }

    .company-account{
      margin-top: 15px;
      .checkbox-label{
        font-size: 1.2em;
      }

      .company-info{
        margin-top: 10px;
      }
    }
  }
}