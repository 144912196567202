.global-nav-header-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  height: 74px;
  
  &.offset-for-sidenav {
    top: 0;
  }

  .MuiAppBar-root {
    background-color: #FFFFFF;
    flex-direction: unset;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
  }

  .global-nav-header-app-bar {
    min-height: 100%;
    max-height: 100%;
  }

  .global-nav-header-title {
    color: #697FF7;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
  }

  .MuiFormControl-root {
    margin: 0;
  }

  .global-nav-header-leasing-team-selection-wrapper {
    .MuiFormControl-root {
      margin-top: -6px;
    }

    .MuiInputBase-root {
      width: 240px;
      height: 40px;
      border-radius: 4px;
      padding: 0px;
      margin: 15px 20px 0 20px;
    }

    #global-nav-header-leasing-team-selection {
      padding-left: 8px;
    }
    .MuiAutocomplete-inputRoot:focus-within {
      border: 1px solid #97A6FC;
      background: #FFFFFF;
    }
    .MuiIconButton-root{
      right: 8px;
      top: 4px;
    }

    .MuiInputBase-root,
    .MuiOutlinedInput-input{
      outline: none;
      border-radius: 4px;
      background: #F5F5F5;
      &:focus {
        background: #ffffff;
      }
    }

    input,
    input:hover{
      cursor: pointer;
    }

    fieldset,
    fieldset:hover,
    .MuiInputBase-root{
      border: none;
    }

    .MuiSvgIcon-root,
    #global-nav-header-leasing-team-selection{
      color: #000000;
    }

    .MuiIconButton-root[title=Clear] {
      display: none;
    }
  }
}

@media print {
  .global-nav-header-container {
    position: unset;
    right: unset;
    left: unset;
  }
}