.rent-matrix-modal-container {
  width: 1000px;
  min-height: 625px;

  table {
    font-size: 10px;

    th {
      height: 31px;
    }

    td {
      background: $inverted-text-color;
    }
  }

  .is-move-in-date {
    background-color: rgba(255, 226, 0, 0.23921568627450981) !important;
  }

  .scrollable-table-wrapper {
    max-width: 1000px;
    overflow: auto;
  }

  .fixed-width-row {
    min-width: 90px;
    font-weight: bold;
  }

  .table-fixed-wrapper {
    width: 90px;
    height: 100%;
    position: absolute;
  }
}
