.dashboard-container {
  padding: 20px;
  background-color: $background-color;
  font-size: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;

  h2 {
    text-align: center;
  }

  .knock-form-subheader {
    font-size: 12px;
    font-weight: bold;
    margin-left: 15px;
  }

  .knock-form-header {
    text-align: left;
    text-transform: none;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .residents-follow-up {
    display: flex
  }

  .residents-follow-up-icon {
    font-size: 20px;
  }

  .residents-follow-up-title {
    margin-top: 7px;
    margin-left: 6px;
  }

  .bell-icon {
    font-size: 20px;
  }

  .dashboard-column:nth-child(1) {
    margin-right: 25px;
  }

  .dashboard-module {
    color: $dark-text-color;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
    border-radius: 4px;
    background-color: $inverted-text-color;
    min-height: 20%;
    padding: 20px;
    margin: 20px;
    font-size: 12px;
    width: 250px;

    a {
      font-size: 11px;
    }
  }

  .dashboard-module-header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative;
    left: -3px;

    .dashboard-module-header-text {
      margin-left: 5px;
    }
  }

  .dashboard-module-footer {
    margin-top: 20px;
    text-align: center;
  }

  .dashboard-clickable {
    border: none;
    background-color: $inverted-text-color;
    box-sizing: border-box;
    display: flex;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .todo-module-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 300px;
    bottom: 0;
    top: 90px;

    .content-container {
      text-align: center;
    }

    .header-container {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }

  .panel-heading, .panel-collapse, .panel-default, .panel-group {
    border: none;
    padding: 0;
  }

  .panel-title {
    border: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: inherit;
    background-color: $background-color;
    display: flex;
    flex-direction: row;
  }

  .panel-title:before {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #DDDDDD;
    padding-right: 10px;
    margin: auto 10px auto auto;
  }

  .panel-title:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #DDDDDD;
    padding-right: 10px;
    margin: auto auto auto 10px;
  }

  .panel-body {
    border: none;
    padding: 0;
    background-color: $background-color;
  }

  .panel-body:after {
    display: none;
    border: none;
  }

  .panel, .panel-group .panel-heading + .panel-collapse > .panel-body {
    border: none;
    box-shadow: none;
  }

  a.accordion-toggle {
    cursor: pointer;
    text-decoration: none;
    color: $medium-text-color;
  }

  .panel-collapse .collapse > .panel-body {
    border: none;
  }
}

@import "prospectTodoCard";
@import "residentTodoCard";
@import "residentFollowupList";
@import "modules/messages";
@import "modules/renewals";
@import "modules/stats";
@import "modules/tours";
