.resident-report-kanban-container {
    .kanban-container {
        background: $background-color;
    }
  
    .kanban-status {
        position: relative;
  
        button.md-button.md-flat.md-primary {
            background-color: $trello-gray;
            color: $dark-text-color;
            text-align: left;
            text-transform: capitalize;
            font-weight: 700;
            font-size: 13px;
            margin: 0;
    
            &:before {
                content: '[+]';
                position: absolute;
                left: 8px;
                top: 0;
                font-weight: 400;
                font-size: 14px;
            }
    
            &.active {
                &:before {
                    content: '[-]';
                }
            }
    
            &:not(.active) {
                &:hover {
                    background-color: $trello-gray-hover;
                }
            }
        }
    }
}

.toggle-section {
    padding: 0 !important;
    min-width: 100%;
    min-height: 100%;

    .status-heading {
        margin-left: 34px;
    }
}

.status-select {
    position: absolute;
    right: 16px;
    top: 8px;
    padding: 0;
    margin: 0;
    background: $white;
}
