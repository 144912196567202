$stripes-background-size: 20px;

.loading-bar-container{
  background-color: $inverted-text-color;
  width: 200px;
  height: 20px;
  border-radius: 2px;

  > div {
    display: inline-block;
    vertical-align: middle;
  }

  .loading-text{
    padding-left: 4px;
    padding-right: 6px;
  }

  .progress-bar-container{
    $bar-height: 8px;
    .loading-progress-bar{
      background-color: $fade-color;
      height: $bar-height;
      width: 115px;
      border-radius: $bar-height / 2;
      position: relative;

      .progress-indicator{
        position: absolute;
        height: $bar-height;
        border-radius: $bar-height / 2;
        background-color: $highlight-color;
        background-size: $stripes-background-size $stripes-background-size;
        background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%, transparent 25%,
                transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
                transparent 75%, transparent);

        animation: progress-bar-stripes 1s linear infinite;
        -webkit-animation: progress-bar-stripes 1s linear infinite;
      }
    }
  }
}

@-webkit-keyframes progress-bar-stripes {
  from  { background-position: 0 0; }
  to    { background-position: $stripes-background-size 0; }
}

@keyframes progress-bar-stripes {
  from  { background-position: 0 0; }
  to    { background-position: $stripes-background-size 0; }
}