.office-hours-container, .manager-hours-container{
  .timezone-row {
    max-width: 150px;
  }

  .time-slider-container{
    margin-bottom: 10px;
    text-align: center;

    &.no-margin {
      margin-bottom: 5px;
    }

    > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .office-hours-tz{
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .modal-header{
    text-align: center;
  }

  .office-hours-row{
    padding: 0;
    min-height: 50px;
  }

  time-slider{
    .slider-container{
      min-width: 300px;
      margin-left: 20px;
    }

    .slider-container, .available-check {
      display: inline-block;
      text-align: center;
    }
  }

  .alert{
    max-width: 600px;
    margin: auto;
    text-align: center;
  }

  .times-display{
    margin-bottom: 5px;
  }

  .day-title{
    font-weight: bold;
    min-width: 100px;
    text-align: left;
  }

  .slider-horizontal{
    width: 300px;
    .slider-selection {
      background-image: linear-gradient($highlight-color 0%, $dark-highlight-color 100%);
    }
  }

  .loading{
    text-align: center;
  }

  .btn-save{
    margin-top: 5px;
    text-align: center;
  }
}