.appointment-booked-modal-container{
  text-align: center;
  color: $medium-text-color;

  h1{
    color: $highlight-color;
    font-size: 36px;
  }

  .appointment-booked-time{
    font-size: 18px;
  }

  .appointment-booked-add-calendar{
    margin-top: 5px;
  }

  .appointment-booked-calendar-header{
    color: $lighter-text-color;
    font-size: 16px;
  }

  .appointment-booked-send-message{
    font-size: 20px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 15px;

    .fa{
      color: $highlight-color;
    }
  }

  .appointment-booked-calendar-rows{
    margin: 10px auto;
    font-size: 18px;
    font-weight: bold;
    width: 120px;
    text-align: left;

    .appointment-booked-calendar-row{
      margin-top: 10px;
      > div{
        display: inline-block;
        vertical-align: middle;
      }

      .calendar-provider-icon{
        margin-right: 5px;
        width: 25px;
        height: 32px;

        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}