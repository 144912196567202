.sms-template-preview-container {
  md-dialog {
    width: 388px;
    border-radius: 46px;
  }
  .sms-template-preview {
    width: 310px;
    height: 653px;
    background-size: 100% 100%;
    font-family: Helvetica Neue;

    section {
      width: 250px;
      height: 495px;
      margin: 50px auto;
      background-color: white;
      position: relative;
      top: 55px;

      .chat-header {
        width: 250px;
        color: #0E7AFE;
        border-bottom: #E5E5EA solid 1px;

        .mock-details-button {
          margin-right: 5px;
        }

        .mock-message-button {
          position: relative;
          bottom: 5px;
        }

        .fa-angle-left {
          font-size: 28px;
          margin-right: 5px;
          margin-left: 5px;
        }
      }

      .mock-chat-input {
        width: 250px;
        height: 30px;
        position: absolute;
        bottom: 0;
        border-top: #E5E5EA solid 1px;

        .fa {
          font-size: 18px;
        }

        .input-icons{
          margin-right: 5px;
          margin-left: 5px;
        }

        input {
          width: 194px;
        }
      }
    }

    .chat-bubble {
      display: inline-block;
      max-width: 230px;
      word-wrap: break-word;
      margin-left: 5px;
      margin-bottom: 5px;
      line-height: 14px;
      position:relative;
      top: 10px;
      padding:10px 10px;
      background:#E5E5EA;
      border-radius:12px;
      color: black;

      p {
        font-size: 12px;
        margin: auto;
      }
    }
  }
}
