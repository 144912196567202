.prospect-results, .message-results {
  border-bottom: 1px solid $border-color;
}
.conversation-search-result-container {
  overflow: hidden;

  cursor: pointer;

  .fa-quote-left, .fa-quote-right {
    color: #aaa;
  }

  .conversation-inbox-row-container {
    padding: 0 10px;
    height: 45px;
  }

  .owner-field {
    text-align: right;
    margin-right: 20px;
  }
}