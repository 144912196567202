.units-page-container {
  table {
    display: table;
    position: relative;
    min-width: 1200px;
    max-height: 70vh;

    tbody {
      overflow: scroll;
    }

    thead > tr, tbody > tr {
      display: table-row;

      th {
        background-color: #fff;
        position: -webkit-sticky;
        position: sticky;
        top: -2px;
        z-index: 99;
      }

      td {
        z-index: 0;
      }

      th, td {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }
}
