.image-uploader-container{
  .image-uploader-drop-field{
    display: inline-block;
    width: 100%;
    height: 100px;
    background-color: $inverted-text-color;
    border: 1px solid $highlight-color;
    text-align: center;
    cursor: pointer;

    &:hover{
      background-color: $background-color;
    }

    &.drag-over{
      border: 2px dashed $highlight-color;
    }

    .image-uploader-text{
      width: 100%;
      vertical-align: middle;
      display: inline-block;
      line-height: 100px;

      color: $medium-text-color;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .image-success {
    color: $highlight-color;
  }

  a.retry-button {
    color: $bright-highlight-color;
    &:hover {
      color: darken($bright-highlight-color, 5%);
    }
  }

  .image-uploader-preview-container {
    text-align: center;
  }

  .image-progress {
    width: 40px;
    height: 40px;
    margin-left: -8px;
    margin-top: -20px;
  }

  .image-uploader-descriptions-helper-text {
    text-align: center;
    font-family: 'Open Sans';
    letter-spacing: 0.15px;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  .character-count-text-field-char-count {
    text-align: right;
    margin-top: 6px;
    font-family: 'Open Sans';
    font-style: normal;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.4);
  }

  .MuiInputBase-root.character-count-text-field-input {
    font-family: 'Open Sans';
    font-size: 16px;
    letter-spacing: 0.15px;
    &::placeholder{
      font-style: italic;
    }
  }

  .character-count-text-field .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl{
    border:0;
  }

  .character-count-text-field-input+fieldset>legend>span{
    font-size: 13px;
  }

  .character-count-text-field-input-label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.64);
    font-family: 'Open Sans';
  }
  
  .image-uploader-previews-container{
    display: flex;
    justify-content: center;
  }
  .image-uploader-previews{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    flex-flow: wrap;
    width: 760px;
    justify-content: space-between;

    > div {
      display: inline-block;
      vertical-align: top;
      padding: 8px;
    }

    .image-uploader-single-image-preview{
      position: relative;
    }

    .image-uploader-preview-item{
      position: relative;
      margin-bottom: 16px;
      width: 220px;
      height: 220px;
      background-position: center;
      background-size: cover;
    }

    .image-uploader-preview-remove{
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: $inverted-text-color;
      color: $medium-text-color;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      @include transition(0.2s);

      &:hover{
        color: $danger-color;
        width: 25px;
        height: 25px;
        line-height: 25px;
        @include transition(0.2s);
      }
    }
  }
}
