div.signup {
  .password-strength-indicator {
    position: absolute;
    top: 10px;
    right: 0;
    width: 5px;
    padding: 0 15px;

    span {
      display: block;
      width: 5px;
      height: 5px;
      margin-bottom: 2px;
      background: #ebeef1;
      border-radius: 5px;
    }
  }

  .has-feedback {
    .form-control-feedback {
      top: 0;
      left: 0;
      width: 46px;
      height: 46px;
      line-height: 46px;
      color: #555;
    }

    .form-control {
      padding-left: 42.5px;
    }
  }
}