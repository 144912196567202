.ide-navigation{
  position: fixed;
  left: 150px;

  padding: 15px 20px 20px 20px;

  font-size: 14px;

  &.offset-for-multiteam{
    left: 215px;
  }

  .ide-navigation-header{
    font-weight: bold;
    border-bottom: 2px solid $fade-color;
    padding-bottom: 5px;
    width: 125px;
  }

  .ide-navigation-links{
    padding-top: 5px;
    min-width: 150px;
  }

  .ide-navigation-link{
    padding-top: 4px;
    padding-bottom: 4px;
    position: relative;

    a{
      font-weight: bold;
      color: $medium-text-color;
      cursor: pointer;
    }

    &.ide-preview-link{
      a{
        color: $highlight-color;
      }
    }
  }

  .idk-navigation-link-status{
    position: absolute;
    right: 0;
    top: 0;

    .fa-check-circle-o{
      color: $highlight-color;
    }

    .fa-times-circle-o{
      color: $danger-color;
    }
  }

  .ide-navigation-functions{
    text-align: center;
    margin-top: 10px;

    button{
      min-width: 125px;
    }

    .ide-navigation-messages{
      margin-top: 10px;

      .ide-navigation-messages-save-success{
        color: $highlight-color;
      }

      .ide-navigation-messages-save-error{
        color: $danger-color;
      }
    }
  }

}
