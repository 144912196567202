.renter-not-interested-modal-container{
  .modal-header{
    text-align: center;
    h4{
      color: $highlight-color;
    }
  }

  .renter-not-interested-instructions{
    font-weight: bold;
    font-size: 13px;
    text-align: center;

    margin-bottom: 20px;
  }

  .renter-not-interested-remove-button{
    text-align: center;
  }
}