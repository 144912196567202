.bouncy-house-overlay{
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $inverted-text-color;
  z-index: 1000;

  .bouncy-house-overlay-content-offset{
    position: relative;
    top: -50px;

    .saving-text {
      padding-top: 10px;
      font-size: 20px;
    }
  }

  .icon-bounce{
    width: 50px;
    vertical-align: bottom;
  }

  .bouncy-house-overlay-text{
    margin-bottom: 25px;
    font-size: 18px;
  }

  .bouncy-house-overlay-content{
    padding: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
}
