.appointment-requirements-modal{
  font-size: 14px;

  input.flat-input{
    width: 160px;
  }

  .modal-header{
    border-bottom: none;
    padding-bottom: 0;

    h3{
      color: $highlight-color;
      text-align: center;
      margin-top: 10px;
    }
  }

  .appointment-requirement-row{
    margin-bottom: 10px;
  }

  .appointment-requirement-row-reason{
    margin-right: 10px;
  }

  .appointment-requirement-row-content{
    margin-top: 5px;

    .pet-selection{
      margin-bottom: 5px;
    }

    &.radio-row{
      display: block;
      margin-top: 10px;
    }
  }

  .requirement-radio-selection{
    margin-right: 15px;

    input{
      margin-right: 5px;
    }
  }

  textarea{
    height: 80px;
    width: 100%;
  }

  .modal-footer{
    text-align: center;
    button{
      width: 160px;
    }
  }
}