.new-event-dialog-container{

  .new-event-buttons {
    padding-bottom: 10px;
  }

  .new-event-button{
    margin-top: 10px;
    button{
      width: 180px;
    }
  }

  .radio-margin {
    margin-right: 5px;
  }

  .editable-label {
    cursor: pointer;
    border-style: dashed;
    border-width: 0px 0px 1px 0px;
  }

  .busy-form-row{
    margin-top: 10px;
  }
  
  .event-time-info {
    text-align: end;
  }
  
  .event-label {
    text-align: end;
    font-weight: bold;
  }

  .event-input {
    text-align: start;
  }
  
  .boldable-info {
    margin-left: 2px;
    margin-right: 2px;
    font-weight: bold;
  }

  .submit-busy-time-row{
    margin-top: 25px;
  }
}