.payments-settings-container{

  .payments-configuration-display{
    font-size: 1.1em;

    .payments-configuration-row{
      margin-top: 5px;
    }

    .edit-link{
      margin-left: 10px;
    }
  }
}