.currency-input-container{
  position: relative;
  left: -10px;

  .currency-input-dollar-sign{
    position: relative;
    left: 25px;
  }

  input.flat-input{
    padding-left: 30px;
  }
}