.property-report {
    property-report-pagination {
        md-checkbox:not([disabled]).md-primary.md-checked .md-icon {
            background-color: $denim-500;
        }

        .property-report-table-container {
            display: block;
            position: static;

            .pointer {
                cursor: pointer;
            }
        }
    }
}
