.transfer-appointments-modal-container{
  .modal-header{
    background-color: $background-color;
    text-align: center;
  }

  .transfer-buttons{
    margin-top: 5px;
    text-align: center;
    .btn{
      margin-top: 10px;
    }
  }
}