.todo-list-item-functions-container {
  .fa-calendar-plus-o {
    color: $highlight-color;
  }

  .fa-clock-o {
    color: $bright-highlight-color;
  }

  .fa-check-square {
    color: $alternate-highlight-color;
  }

  .fa-trash-o {
    color: $danger-color;
  }

  button.md-button.md-dropdown-button {
    min-width: 155px;
    max-height: 34px;
    min-height: 34px;
    height: 34px;
    margin: 0;

    > * {
      max-height: 34px;
    }

    .md-dropdown-label {
      position: relative;
      top: -1px;
    }
  }

  .md-menu {
    padding: 0;
  }

  .md-tooltip.md-show {
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
  }
  .dropdown-menu-label {
    padding: 2px 10px;
    color: $inverted-text-color;
    text-transform: uppercase;
    font-size: 10px;
  }

  .prospect-functions-col {
    margin-left: 20px;
    position: relative;
    top: -3px;
  }

  button.md-button.md-button-round.md-flat {
    background-color: $inverted-text-color;
    width: 30px;
    height: 30px;
    max-width: 30px;
    max-height: 30px;
    position: relative;
    top: 9px;

    .custom-tooltip .md-content {
      border-radius: 4px;
      padding: 8px;
      font-size: 14px;
      width: 300px;
    }

    &:hover {
      background-color: $background-color;
    }

    &.selected {
      color: $inverted-text-color;
      background-color: $danger-color;

      &:hover {
        background-color: darken($danger-color, 10%);
      }
    }
  }
}
