.account-view-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  md-tabs {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    div[md-tabs-template] {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .account-profile-row {
    max-width: 500px;

   .user-image {
      cursor: pointer;
   }
  }

  p {
    font-size: 12px;
    max-width: 650px;
  }

  .knock-form-header {
    text-align: left;
  }
}

@import "accountPreferences";
@import "accountIntegrations";
