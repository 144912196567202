.account-container{

  position: relative;
  height: 100%;
   md-tabs
    {
      height: 100%;

      md-tab-content > .account-tab
      {
          height: 100%;
      }
    }

  .settings-container.sidenav-form-layout{
    .settings-header{
      text-align: center;
    }

    .sidebar-header{
      position: fixed;
      top: 60px;
      left: 170px;
      border-bottom: 2px solid $border-color;
      font-weight: bold;
      font-size: 1.25em;
      min-width: 120px;

      &.no-offset {
        left: 15px;
      }
    }

    .main-sidebar{
      top: 85px;

      &.no-offset {
        left: 15px;
      }

      .fa{
        color: $highlight-color;
      }
      a{
        color: $medium-text-color;

        &.active{
          font-weight: bold;
        }
      }
    }

    .password-form{
      .form-group{
        margin-bottom: 0;
        input{
          margin-top: -1px;
        }
      }
    }
  }

  .general-settings-body{

    md-icon {
      padding-top: 5px;
    }

    h3{
      color: $highlight-color;
    }

    div{
      margin-left: 10px;
    }

    .cc-email-input {
        padding-bottom: 26px;
    }

    .submit-buttons{
      text-align: center;
    }
  }
}


