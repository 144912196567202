.payment-plan-entry-form{
  padding-top: 15px;
  input.form-control, select{
    height: 32px;
    border-radius: 0;
  }

  input{
    &.error{
      border-color: $danger-color;
    }
  }

  select{
    border: none;
    outline: 1px solid $fade-color;
    outline-offset: -1px
  }

  .input-error{
    color: $danger-color;
  }

  .required-field{
    color: $danger-color;
  }

  .col-xs-3{
    padding-right: 0;
  }

  .payment-plan-entry-field{
    margin-bottom: 15px;
  }

  .security-code{
    position: relative;
    .security-code-image{
      position: absolute;
      right: 1px;
      top: 1px;
    }
  }
}