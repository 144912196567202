.conversation-container {
  $notification-message-background: #d7e2f7;
  $knockbot-chat-background: #794cff;
  $manager-chat-background: rgba($highlight-color, 0.05);
  $not-manager-chat-background: rgba($alternate-highlight-color, 0.05);
  $activity-background: rgba($bright-highlight-color, 0.05);
  $manager-chat-border: rgba($highlight-color, 0.2);
  $not-manager-chat-border: rgba($alternate-highlight-color, 0.2);
  $activity-border: rgba($bright-highlight-color, 0.2);

  height: 100%;

  color: $medium-text-color;
  background-color: $background-color;

  $conversation-input-height-tall: 180px;
  $conversation-input-height-short: 110px;

  $email-input-height-tall: 100%;

  $messaging-as-height: 25px;

  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

  .conversation-messaging-as {
    background-color: #2EA683;
    color: $inverted-text-color;
    font-size: 12px;
    height: $messaging-as-height;
    padding: 3px;
    padding-left: 8px;

    @media screen and (max-device-width: 600px) {
      bottom: $conversation-input-height-short;
    }
  }

  .from-to {
    font-size: 11px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .subject-line {
    height: 30px;
    background-color: $inverted-text-color;

    input.flat-input {
      min-width: 400px;
    }

    .subject-display {
      font-size: 11px;
    }

    .subject-line-editing {
      button {
        font-size: 11px;
        padding: 0;
        margin: 0;
      }
    }
  }

  .conversation-messages {
    flex-grow: 1;

    padding-left: 15px;
    padding-right: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 10px;

    &.show-online-banner {
      top: 32px;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    .chat-message-container {
      display: flex;
      width: 100%;

      .ai-bot-image {
        width: 14px;
        height: 14px;
        vertical-align: text-bottom; 
        margin-right: 5px;
      }

      &.is-manager,
      &.is-knockbot,
      &.is-current-user,
      &.is-knock,
      &.is-pms {
        justify-content: flex-end;

        .time-stamp-source-container,
        .message-failed-stamp {
          justify-content: flex-end;
        }

        .message-attachment {
          justify-content: flex-end;
          text-align: right;
        }
      }

      .media-label {
        font-size: 12px;
        font-weight: bold;
      }

      .media-icon, media-label {
        &:hover {
          color: $icon-color;
        }
      }

      .message-bubble {
        display: table;
        border-radius: 4px;
        background-color: $inverted-text-color;
        font-size: 13px;

        b, i, u, p, ul, ol {
          font-size: 13px;
        }

        @include transition(border-color 0.5s);

        &.is-editing {
          border-color: $danger-color;
          background-color: $inactive-color;

          .conversation-message-text {
            color: $fade-color;
          }
        }

        .message-content {
          .message-iframe-container {
            iframe {
              width: 100%;
              border: none;
              overflow: hidden;
            }
          }

          .message-iframe-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $gray-lighter;
            padding: 10px 0;
            margin-bottom: 20px;

            .reply-button {
              max-height: 30px;
              min-height: 30px;
              line-height: 30px;
              height: 30px;
              font-size: 12px;
              margin: 0;
            }
          }

          .message-iframe-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #afafaf;
            margin-top: 10px;

            .full-message-button {
              font-size: 10px;
              min-width: 14px;
              min-height: 14px;
              line-height: 14px;
              padding: 5px;
              margin: 0;
              border: solid 1px $border-color;
              i {
                font-size: 9px !important;
              }
            }

          }
        }

        &.is-knockbot {
          margin-left: auto;
          background-color: $knockbot-chat-background;
        }

        &.is-manager,
        &.this-team-member,
        &.is-knock,
        &.is-pms {
          margin-left: auto;
          background-color: $alternate-highlight-color;
        }

        &.not-manager,
        &.other-team-member {
          margin-right: auto;
          background-color: $inverted-text-color;
        }

        &.is-html {
          min-width: 75%;
          background-color: white;
          color: $dark-text-color;
          padding: 12px;

          p {
            margin-bottom: 0;
          }
        }

        &.is-html.not-html {
          max-width: 75%;
        }

        &.not-html {
          max-width: 85%;
          padding: 12px;
        }

        &.is-email.not-manager {
          min-width: 75%;
          padding: 12px;
        }

        &.in-transit {
          background-color: transparent;
        }
      }

      .message-attachment {
        clear: both;
        margin-top: 25px;
        margin-bottom: 5px;
        padding-top: 10px;

        img {
          border-radius: 4px;
          max-width: 400px;
          max-height: 400px;
        }
      }

      .message-fail-container {
        i {
          margin-left: 10px;
          color: $danger-color;
          font-size: 18px;
        }
      }

      .time-stamp-source-container {
        margin: 5px 0;

        .message-failed-text {
          color: $danger-color;
          font-size: 10px;
        }

        .message-failed-stamp {
          padding-left: 5px;
        }
      }
    }

    @media screen and (max-device-width: 600px) {
      bottom: $conversation-input-height-short;
    }

    &.show-subject {
      top: 50px;
    }

    &.show-messaging-as {
      bottom: $conversation-input-height-tall + $messaging-as-height;

      @media screen and (max-device-width: 600px) {
        bottom: $conversation-input-height-short + $messaging-as-height;
      }
    }

    &.is-editing {
      bottom: $conversation-input-height-tall + $messaging-as-height;
    }

    .notification-container {
      &.is-manager,
      &.is-knockbot,
      &.is-knock,
      &.is-pms {
        justify-content: flex-end;
      }

      &.not-manager {
        justify-content: flex-start;
      }

      .notification-message-container {
        display: table;
        border-radius: 4px;
        background-color: $notification-message-background;
        border: none;
        padding: 5px;

        .image-message-container {
          margin: 5px;

          .fa {
            padding-right: 5px;
          }

          .notification-message {
            font-size: 13px;
            color: $dark-text-color;

            small {
              display: block;
              color: $dark-text-color;
              font-weight: lighter;
            }
          }
        }
      }

      .conversation-message-timestamp {
        position: relative;
        bottom: 6px;
        white-space: pre;
      }
    }

    .activity-container {
      .activity-message-container {
        display: table;
        border-radius: 4px;
        margin-left: auto;
        padding: 5px;
        background-color: $bright-highlight-color;
        color: $inverted-text-color;

        .image-message-container {
          margin: 5px;
          font-size: 13px;

          .activity-header {
            font-weight: bold;
          }

          .activity-message {
            font-weight: lighter;
          }
        }
      }

      .conversation-message-timestamp {
        position: relative;
        bottom: 6px;
        left: 5px;
        margin-left: auto;
      }
    }

    .call-container {
      .recording-message-container {

        &.is-outgoing {
          margin-left: auto;
        }

        .call-message-container {
          display: table;
          background-color: $inverted-text-color;
          border-radius: 10px;
          padding: 16px;

          .call-type {
            margin: 0 0 10px 5px;
            color: $dark-text-color;
            font-size: 13px;
            font-weight: bold;

            &.is-not-recorded {
              padding-bottom: 10px;
            }
          }

          .audio-progress-container {
            padding : 10px;
          }

          .call-status-Message {
            margin-top: 16px;
          }

          &.is-outgoing {
            margin-left: auto;
            border: solid 1px $manager-chat-border;
            background-color: $alternate-highlight-color;

            .call-type {
              color: $inverted-text-color;
            }

            .call-details {
              color: $inverted-text-color;

              select {
                background: grey;
              }
            }

            .audio-player-container {
              md-slider.md-primary .md-track.md-track-fill {
                background-color: $inverted-text-color;
              }

              md-slider {
                .md-track {
                  background-color: $accent-color;
                }

                .md-thumb {
                  &:after {
                    border-color: $inverted-text-color;
                    background-color: $inverted-text-color;
                  }
                }
              }

              span {
                color: $inverted-text-color;
              }
            }
          }

          .audio-player-container {
            padding-right: 5px;
            padding-bottom: 5px;

            button.md-button.md-flat.md-primary {
              background-color: $inverted-text-color;
            }

            button.md-primary.md-fab:not([disabled]):hover {
              background-color: $fade-color;
            }

            md-slider.md-primary .md-track.md-track-fill {
              background-color: $dark-text-color;
            }

            md-slider .md-track {
              background-color: $accent-color;
            }

            md-slider .md-thumb {
              &:after {
                border-color: $accent-color;
                background-color: $accent-color;
              }
            }

            span {
              color: $dark-text-color;
            }

            .remaining-time {
              margin-left: 10px;
              margin-right: 5px;
            }

            .current-time {
              margin-right: 5px;
            }

            .fa-play,
            .fa-pause {
              position: relative;
              right: 4px;
              bottom: 6px;
              color: $accent-color;
            }

            .fa-pause {
              right: 6px;
            }
          }

          .call-details {
            padding: 10px 5px 5px 10px;

            .fa-spin {
              animation-duration: 1s;
            }

            select {
              border-radius: 4px;
              padding: 0px 12.5px;
            }

            hr {
              margin-top: 0px;
              margin-bottom: 10px;
            }
          }
        }
      }

      .conversation-message-timestamp {
        i {
          color: $highlight-color;
        }

        &.is-manager,
        &.is-knockbot,
        &.is-knock,
        &.is-pms {
          float: right;
        }

        button {
          padding: 1px 3px;
        }
      }
    }

    .conversation-message-text {
      @include transition(color 0.5s);
      color: $dark-text-color;
      font-size: 13px;

      &.is-manager,
      &.is-knockbot,
      &.is-knock,
      &.is-pms {
        color: $inverted-text-color;
      }
    }
    
    .conversation-message-icons {
      position: relative;
      top: 3px;
      color: $placeholder-text-color;
      font-weight: lighter;
      font-size: 10px;
    }

    .conversation-message-was-delivered {
      color: $success-color;
    }

    .conversation-message-was-sent {
      color: $slate-600;

      img {
        margin-bottom: 2px;
      }
    }

    .conversation-message-was-open {
      color: #15986D;

      img {
        margin-bottom: 5px;
      }
    }

    .conversation-message-was-delivered-success {
      color: $denim-500;
      height:12px; 
      width:12px;   
    }
    .conversation-message-timestamp {
      position: relative;
      margin-left: 5px;
      margin-right: 2px;
      color: rgba(0, 0, 0, 0.87);
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      text-align: right;

      .hide-text, .show-text, .removed-text {
        color: $denim-500;
        font-weight: 600;
        cursor: pointer;
        font-size: 10px;
        line-height: 133%;
        letter-spacing: 0.4px;
      }

      .removed-text {
        color: rgba(0, 0, 0, 0.87);
        cursor: default;
      }
    }
  }

  .conversation-input {

    height: $conversation-input-height-tall;
    max-height: $conversation-input-height-tall;

    @media screen and (max-device-width: 600px) {
      height: $conversation-input-height-short;
      max-height: $conversation-input-height-short;
    }

    background-color: transparent;

    &.call {
      .emojiPickerIconWrap, .emoji-picker-button, .new-emoji-picker-button {
        display: none;
      }

      .conversation-text-area-footer {
        padding-left: 5px;
      }

      .conversation-text-area-body {
        overflow: hidden;
      }
    }

    &.email {
      height: auto;
      max-height: $email-input-height-tall;

      .emojiPickerIconWrap, .emoji-picker-button, .new-emoji-picker-button {
        display: none;
      }

      .conversation-text-area-footer {
        padding-left: 5px;
      }
    }
  }

  .conversation-input-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: $inverted-text-color;

    .conversation-tools {
      height: 40px;

      md-select {
        position: relative;
        top: -5px;
      }

      md-select .md-select-value {
        border: none;
      }

      border-bottom: 1px solid $border-color;

      button {
        position: relative;
        left: 10px;
      }

      .share-listing-menu,
      .canned-responses,
      .attachment-selector {
        font-size: 11px;

        &:hover {
          color: $highlight-color;
          cursor: pointer;
        }
      }

      .canned-responses {
        position: absolute;
        left: 180px;
      }

      .share-listing-menu {
        position: absolute;
        left: 200px;
      }

      .attachment-selector {
        position: absolute;
        left: 240px;
      }

      .char-count {
        &.is-over-max {
          color: $danger-color;
        }
      }

      .share-listing-menu {
        padding-left: 12px;
      }
    }
  }

  .conversation-textarea-wrapper {
    textarea {
      padding: 10px;
      width: 100%;
      height: 95px;
      resize: none;
      border: none;
      font-size: 14px;

      &.drag-over {
        border: 1px dashed $highlight-color;
      }
    }

    .emoji-picker-button {
      left: 10px;
      top: -32px;

      &:hover {
        color: $highlight-color;
      }
    }

    &.is-manager {
      .emoji-picker-button {
        left: 135px;
      }
    }
  }

  .typing-indicator-wrapper {
    padding: 10px 10px 10px 15px;
    margin-left: auto;
  }

  .user-online-indicator {
    .fa {
      margin-right: 5px;
    }

    &.online {
      .fa {
        color: $highlight-color;
      }
    }
  }

  .editing-message-alert {
    color: $danger-color;
  }
  .transcript-heading-margin{
    float: right;
    margin-top: 5px;
    margin-right: 16px;
  }

  .transcript-msg-margin{
    margin-left: 100px;
  }
  .trans-heading{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 266%;
  }

  .panel-default > .panel-heading {
    color: #000000;
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 0px 0px 0px 4px;
    
  }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 0;
    border-color: #ffffff;
    border-bottom-color: #ffffff;
  }

  a.accordion-toggle {
    text-decoration: none;
  }

  .layout-align-start-center{
    align-items: baseline;
  }

  .transcript-text{
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 12px;
    line-height: 133%;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 16px;
  }

  .transcript-left{
    font-weight: 600;
  }

  .transcript-right{
    font-weight: 400;
  }

  .panel-body {
    padding: 3px;
  }

  .panel{
    box-shadow: none;
  }

  .panel-group {
    margin-bottom: 0px;
  }

  .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 0px solid #ddd;
  }

  .spinner{
    margin-left: 50%;
  }
  .no-data-text{
    margin-left: 25%;
  }

  .transcript-bottom{
    font-size: 16px;
    float: right;
    margin-right: 16px;
    margin-top: 5px;
    color: #000000;
  }

  .transcript-info{
    font-size: 16px;
    color: #EE223D;
  }

  .transcript-error-heading{
    font-size: 16px;
    color: #70000B;
    font-weight: 600;
  }

  .transcript-error-text{
    font-size: 14px;
    color: #70000B;
    font-weight: 400;
  }

  .error-div{
    width: 365px;
    background-color: #FFF5F6;
    display: flex;
    padding: 10px;
  }

  .icon-info{
    width: 10%;
  }

  .error-info{
    width: 90%;
  }

  .retry-button{
    float: right;
    font-size: 14px;
    margin-right: 12px;
  }
}
