.ide-document{
  padding-top: 15px;

  width: 500px;
  position: absolute;
  left: 240px;

  .ide-document-section{
    margin-bottom: 30px;

    .ide-document-section-header{
      text-align: center;
      font-size: 14px;
      color: $highlight-color;
      font-weight: bold;

      padding-bottom: 20px;
    }
  }

  .ide-document-form{
    font-size: 14px;
    background-color: $inverted-text-color;
    min-height: 100px;
    border: 1px solid $border-color;

    input, textarea{
      outline: none;
      padding-left: 2px;

      &:focus{
        outline: 1px solid $border-color;
      }
    }

    .ide-document-form-row{
      border-bottom: 1px solid $border-color;
      min-height: 40px;
    }

    .ide-document-form-column{
      display: inline-block;
      vertical-align: middle;

      input{
        min-height: 40px;
        border: none;
        margin: 0;
      }
    }

    .ide-document-form-label{
      font-weight: bold;
      padding-left: 10px;
    }
  }

  .ide-document-form-checkboxes{

    .ide-document-section-header{
      color: $medium-text-color;
      text-align: left;
      padding-bottom: 5px;
    }

    .ide-checkboxes-subitem{
      margin-top: 15px;

      .ide-checkboxes-subitem-header{
        font-style: italic;
      }

      .ide-checkboxes-subitem-content{
        input{
          padding: 5px;
          border: 1px solid $border-color;
        }
      }
    }

  }

}