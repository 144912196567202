.manager-login {
  background: $background-color;

  .knock-promo {
    width: 380px;
    background: $white;
    border: 1px solid $border-color;
    padding: 12px;
    border-radius: 4px; 
    
    .knock-promo-inner {
      background: url('/images/icon.png') no-repeat;
      background-position: center left;
      background-size: 50px;
      padding-left: 62px;
      min-height: 50px;
      width: 100%;
    }
  }

  .hr-sect {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      color: rgba(0,0,0,0.26);
      font-weight: bold;
      font-size: 10px;
      margin: 8px 0px;
  }

  .hr-sect:before,
  .hr-sect:after {
      content: "";
      flex-grow: 1;
      background: rgba(0,0,0,0.12);
      height: 1px;
      font-size: 0px;
      line-height: 0px;
      margin: 0px 8px;
  }

  .bold-text {
    font-weight: bold;
    color: rgba(0,0,0,0.40);
  }

  .login-prompt-container {
    padding-top: 120px;
  
    .knock-form-subheader {
      font-size: 16px;
      margin-bottom: 16px;
    }
    
    .knock-alert-error {
      padding-bottom: 16px;
    }
  }
  
  .knock-form {
    width: 380px;
    padding: 12px 12px 24px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
    background: $white;
    
    .sign-in-button-container {
      padding-bottom: 15px;
      text-align: center;
    }
  
    .manager-forgot-pw {
      padding-top: 12px;
    }
  }  
}
