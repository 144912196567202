.user-phone-modal{
  .modal-header{
    border-bottom: none;
    padding-bottom: 0;

    > div{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
    }

    .icon{
      background: url("/images/icon.png");
      background-size: cover;
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }
  }

  .message-container{
    text-align: center;
  }

  .phone-form-container{
    text-align: center;
    input{
      height: 40px;
      background-color: $background-color;
      border: none;
      width: 150px;
      margin: auto;
      border-radius: 8px;
      text-align: center;
    }

    button{
      margin-top: 20px;
      width: 100px;
    }
  }
}