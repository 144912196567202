.merge-step-container {
  align-items: center;
  display: flex;
  margin: 20px 0 10px;
}

.merge-step {
  display: flex;
  font-weight: normal;

  & + & {
    margin-left: 20px;
  }

  .step-icon {
    align-items: center;
    border-radius: 50%;
    border: 1px solid #808080;
    display: flex;
    height: 16px;
    justify-content: center;
    position: relative;
    width: 16px;
    font: Medium 12px/15px Montserrat;

    span {
      color: #808080;
    }

    i {
      display: inline-block;
    }
  }

  span {
    display: inline-block;
    margin-left: 10px;
  }

  &.completed,
  &.active {
    font: Bold 12px/16px Montserrat;

    .step-icon {
      background-color: #2eb88f;
      color: #fff;
      border: none;

      span {
        color: #444;
      }
    }
  }

  &.completed {
    font: Medium 12px/15px Montserrat;
  }
}

.merge-options-label {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.merge-emphasized {
  font-weight: 600;
}

.merge-options-list {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;

  table {
    width: 100%;
  }

  tr {
    height: 40px;
    transition: all 0.25s ease-in-out;
  }

  tr:nth-child(even) {
    background: #f0f0f0;
  }

  tr.selected,
  tr:hover {
    background: #2EB88F;
    border-radius: 2px;
    box-shadow: 0px 3px 6px #2EB88F54;
    color: #fff;
    cursor: pointer;

    td a {
      transition: color 0.25s ease-in-out;
      color: #fff;
    }
  }

  td {
    padding: 0 10px;
  }
}

table.merge-field-table {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  flex: 1;

  tbody {
    display: block;
    overflow-y: auto;
    height: 100%;
  }

  tr {
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    padding: 5px;
  }

  tr:last-of-type {
    border-bottom: none;
  }

  td,
  th {
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1 1 100%;
    height: 40px;
  }

  td {
    cursor: pointer;
  }

  /* Add margin to left/right of middle column */
  td:nth-of-type(2) {
    margin: 0 15px;
  }

  td:first-of-type,
  th:first-of-type {
    color: #808080;
    cursor: default;
    flex: 0 0 180px;
    justify-content: flex-start;
  }

  td.locked,
  td.disabled {
    color: #999999;
    background: #fff;
    cursor: default;
    pointer-events: none;
    border-radius: 2px;
  }

  td.active {
    background: #2eb88f;
    color: #fff;
    border-radius: 2px;
  }

  td.active.locked,
  td.active.disabled {
    background: #2eb88f88;
  }

  th {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
  }

  .icon-container {
    align-items: center;
    display: flex;
    height: 15px;
    justify-content: center;
    margin-right: 10px;
    width: 15px;
  }
}