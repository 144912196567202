.sidenav-form-layout{

  .post-button-fixed{
    position: fixed;
    top: 60px;
    z-index: 999;

    @media screen and (max-width: 1199px){

      right: 30px;
    }

    @media screen and (min-width: 1200px){
      left: 1050px;
    }
  }

  .uploading-listing-container{
    text-align: center;
    .loading-bar-container{
      text-align: center;
    }
  }

  .main-sidebar{
    position: fixed;
    top: 60px;
    left: 165px;
    font-size: 1.2em;

    .navigation-row{
      padding: 5px;
    }

    completion-status{
      margin-left: 10px;
    }

    a{
      cursor: pointer;
    }
  }

  .form-container{
    padding: 0 20px 20px 20px;
    max-width: 600px;
    margin: auto;
  }

  .main-view{
    @media screen and (min-width: 500px){
      left: 180px;
      top: 45px;
      right: 15px;
    }

    @media screen and (max-width: 501px){
      top: 210px;
    }

    position: absolute;
    max-width: 1000px;

    padding: 15px;
    border: 1px solid $border-color;

    h3{
      text-align: center;
      margin-bottom: 25px;
      .fa{
        color: $highlight-color;
      }
    }

    .alert{
      margin-top: 25px;
      text-align: center;
      font-size: 1.1em;
    }

    .small-number-entry{
      max-width: 100px;
    }

    .form-control{
      box-shadow: none;
      border-radius: 0px;
      height: 40px;
      border-color: $fade-color;
      font-size: 1.1em;
    }

    label{
      color: $medium-text-color;
      font-size: 1.2em;
      font-weight: normal;
    }
  }

}