.large-carousel-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $inverted-text-color;
  z-index: 15000;

  .large-carousel-modal-close{
    position: fixed;
    top: 10px;
    right: 10px;
    font-size: 36px;
  }
}
