.photo-drop-zone{
  height: 100px;

  div{
    display: inline-block;
  }

  &.drag-enter{
    border: $highlight-color 2px dashed;
  }

  .btn-primary{
    margin-left: 5px;
  }
}

.photo-drop-preview{
  background-color: $background-color;
  display: inline-block;
  margin: 5px;
  border: 1px solid $border-color;

  .btn-link{
    color: $danger-color;

    &:hover{
      color: darken($danger-color, 15%);
    }
  }
}

.photo-drop-preview-image{
  max-height: 120px;
}