$kanban-column-width: 265px;

.kanban-columns {
    display: flex;
    height: 100%;
}

.kanban-column {
    width: $kanban-column-width;
    min-width: $kanban-column-width;
    max-height: calc(100% - 20px - 20px);
    overflow-y: auto;
    position: relative;
    padding: 0 6px 20px;
}