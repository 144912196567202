.requested-appointment-display-container{
  .request-sent-header{
    color: $highlight-color;
  }

  .request-instructions{
    margin-bottom: 20px;
  }
  .request-sent-functions{
    margin-top: 10px;
    > div {
      display: inline-block;
    }
  }

  hr{
    margin-top: 10px;
    margin-bottom: 0;
  }

  .proposed-time-row{
    margin-top: 5px;
    .col-xs-5{
      vertical-align: middle;
      text-align: left;

      .btn{
        position: relative;
        top: -7px;
      }
    }

    .col-xs-7{
      vertical-align: middle;
      text-align: right;
    }
  }
}