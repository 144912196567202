.user-interactions-details-container, .user-interactions-lead-details-container, .user-interactions-resident-details-container {
  overflow-x: hidden;

  .user-profile-section {
    font-size: 14px;
    color: $medium-text-color;
    padding-left: 15px;
  }

  .read-only-container {
    margin-bottom: 10px;
  }

  .profile-label {
    width: 160px;
    color: $lighter-text-color;
  }

  .profile-data {
    a.function-link {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .user-info-row {
    font-size: 14px;
    .user-info-row-label {
      font-weight: bold;
    }
  }
}

.user-interactions-lead-details-container, .user-interactions-resident-details-container {
  width: 300px;
}

.prospect-loss-reasons {
    margin-bottom: 5px;

    label {
        color: rgba(0, 0, 0, 0.54);
        transform: scale(0.75);
        margin-left: -6px;
    }
    ul {
        padding-left: 5px;
        list-style-type: none;
        margin-bottom: 0;
    }
}

.profile-view-resident-in-onesite-link-container {
  padding: 0px 5px 0px;
}