md-backdrop {
  &.md-menu-backdrop {
    z-index: 15002;
  }

  &.md-select-backdrop {
    z-index: 15003;
  }
}

md-tabs {
  md-ink-bar {
    color: $highlight-color;
    background: $highlight-color;
  }
}

md-tooltip .md-content {
  background-color: $main-text-color;
}

md-toast {
  z-index: $z-top;
}

md-card {
  md-card-content {
    padding: 5px;
  }
}

md-input-container {
  &.no-padding {
    padding-bottom: 0;
  }
}

button {
  &.md-copy-btn {
    margin-top: 0;
    margin-bottom: 0;
  }
}

md-backdrop.md-dialog-backdrop {
  z-index: 15000;
}

.md-dialog-container {
  z-index: 15001;
}

.md-autocomplete-suggestions-container {
  z-index: 15002 !important;
}

md-option {
  height: 28px;
  .md-text {
    font-size: 14px;
  }
}

md-dialog {
  width: 500px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  md-dialog-content {
    padding: 15px;
  }

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

md-toolbar.user-image-toolbar {
  height: 120px;
  min-height: 120px;

  h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 275px;
    overflow: hidden;
  }

  .md-toolbar-tools {
    height: 120px;
    max-height: 120px;
  }
}

md-toolbar.property-image-toolbar {
  height: 95px;
  min-height: 95px;

  h2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 275px;
    overflow: hidden;
  }

  .md-toolbar-tools {
    font-size: 14px;
    height: 95px;
    max-height: 95px;
  }
}

.property-image {
  width: 80px;
  height: 50px;
  margin-right: 20px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.large-property-image {
    width: 210px;
    height: 125px;
  }
}

.md-close-button {
  color: $close-button-color;
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

md-toolbar:not(.md-menu-toolbar) {
  background-color: $background-color;
  color: $medium-text-color;

  .md-close-button {
    right: 10px;
  }

  .md-button {
    color: $highlight-color;
  }

  .user-image {
    margin-right: 20px;
  }
}

button.md-button {
  &.md-big {
    height: 50px;
    width: 250px;
    font-size: 18px;
  }

  &.md-dropdown-button {
    padding-left: 10px;
    background-color: $inverted-text-color;
    font-size: 12px;

    &:hover {
      background-color: $background-color;
    }
  }

  &.md-flat {
    text-transform: none;

    &.md-primary {
      background-color: $highlight-color;
      color: $inverted-text-color;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        background-color: darken($highlight-color, 10%);
      }
    }

    &.md-primary-dark {
      background-color: $dark-highlight-color;
      color: $inverted-text-color;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        background-color: darken($dark-highlight-color, 10%);
      }
    }

    &.md-warn {
      background-color: $accent-color-bright;
      color: $inverted-text-color;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        background-color: darken($accent-color-bright, 10%);
      }
    }

    &.md-danger {
      background-color: $danger-color;
      color: $inverted-text-color;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        background-color: darken($danger-color, 10%);
      }
    }

    &.md-primary-accent {
      background-color: $accent-color;
      color: $inverted-text-color;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        background-color: darken($accent-color, 10%);
      }

      &.md-focused {
        background-color: $accent-color;
      }
    }

    &.md-primary-alt {
      background-color: $alternate-highlight-color;
      color: $inverted-text-color;
      padding-left: 15px;
      padding-right: 15px;

      &:hover {
        background-color: darken($alternate-highlight-color, 10%);
      }

      &.md-focused {
        background-color: $alternate-highlight-color;
      }

      &.md-button-round {
        background-color: $inverted-text-color;
        color: $alternate-highlight-color;
      }
    }

    &.md-raised {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);

      &.facebook-btn {
        background-color: $facebook-blue;
        color: $inverted-text-color;

        &:hover {
          background-color: darken($facebook-blue, 10%);
        }
      }

      &.linkedin-btn {
        background-color: $linkedin-blue;
        color: $inverted-text-color;

        &:hover {
          background-color: darken($linkedin-blue, 10%);
        }
      }
    }

    &.md-button-round {
      padding: 0;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
      max-width: 25px;
      max-height: 25px;
      line-height: 25px;
      border-radius: 50%;
    }
  }

  &.icon-button {
    width: 48px;
    height: 46px;
    min-height: 46px;
    min-width: 48px;
    color: $inverted-text-color;

    &.small {
      width: 32px;
      height: 30px;
      min-width: 32px;
      min-height: 30px;
      line-height: 32px;
      margin: 0 2px;

      .fa-comment {
        position: relative;
        left: -5px;
        top: -2px;
      }

      .fa-edit {
        position: relative;
        top: -1px;
      }
    }

    &[disabled] {
      background-color: $inactive-color;
      color: rgba(0,0,0,0.26);

      &:hover {
        background-color: $inactive-color;
      }
    }
  }
}

button.md-button.function-button {
  margin: 0 3px;
  min-height: 30px;
  height: 30px;
  line-height: 30px;
  min-width: 50px;
  width: 50px;
  text-transform: none;

  &.md-warn.md-warn-light {
    background-color: #fbb03b;
    color: $inverted-text-color;

    &:hover {
      background-color: darken(#fbb03b, 10%);
    }
  }
}

button.md-button.md-dropdown-button {
  min-width: 140px;
  text-align: left;

  .arrow-span {
    text-align: right;
    position: absolute;
    right: 0;
    padding-right: 10px;
    font-size: 16px;
  }

  &.md-warn-light {
    background-color: $bright-highlight-color;
    color: $inverted-text-color;

    &:hover {
      background-color: darken($bright-highlight-color, 10%);
    }
  }

  &.md-dull {
    background-color: #999;
    color: $inverted-text-color;

    &:hover {
      background-color: darken(#999, 10%);
    }
  }
}

md-menu-item {
  .md-button {
    &.md-warn-light {
      color: $bright-highlight-color;

      md-icon {
        color: $bright-highlight-color;
      }
    }

    &.md-dull {
      color: #999;

      md-icon {
        color: #999;
      }
    }
  }
}

.md-select-menu-container {
  z-index: 15003
}

md-checkbox {
  line-height: 18px;
}

md-radio-button {
  margin: 10px;
}

md-chip {
  font-size: 14px;
}

.md-chip-input-container {
  input {
    font-size: 14px;
    font-family: $font-family;
  }
}

[ng-messages] {
  color: $danger-color;
}

button {
  &.small-btn {
    min-width: 45px;
    min-height: 0;
    line-height: normal;
    font-size: 9px;
    padding-bottom: 5px;
    margin: 0;
  }
}

.user-image {
  margin-right: 10px;
  position: relative;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  background: no-repeat center;
  background-size: cover;
  border-radius: 50%;

  &.chat-thread-user-image {
    width: 30px;
    height: 30px;

    min-width: 30px;
    min-height: 30px;
  }

  &.x-small-user-image {
    width: 35px;
    height: 35px;

    min-width: 35px;
    min-height: 35px;
  }

  &.small-user-image {
    width: 45px;
    height: 45px;

    min-width: 45px;
    min-height: 45px;
  }

  &.medium-user-image {
    width: 50px;
    height: 50px;

    min-width: 50px;
    min-height: 50px;
  }

  .edit-user-image {
    position: absolute;
    bottom: -5px;
    right: -10px;
  }

  .user-image-badge {
    position: absolute;
    bottom: -5px;
    right: -10px;

    color: $inverted-text-color;
    background-color: $accent-color-bright;
    width: 25px;
    height: 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    margin-right: 5px;
  }
}

.short-row {
  padding-bottom: 5px;
}

.md-actions {
  .save-success {
    margin-right: 10px;
    color: $highlight-color;
  }

  .save-error {
    margin-right: 10px;
    color: $danger-color;
  }
}

.button-badge {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 14px;
  line-height: 25px;
  color: $inverted-text-color;
  background-color: $background-color;
  border-radius: 50%;

  &.button-badge-right {
    top: 0;
    right: 0;
  }
}

.mdDataTable {
  .column {
    padding-left: 20px;
  }

  td {
    cursor: pointer;
  }
}

.md-open-menu-container {
  z-index: 15002;
}

.st-sort-ascent:before{
  content: '\25B2';
}

.st-sort-descent:before{
  content: '\25BC';
}

textarea.chat-textarea {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  resize: none;
  border: none;
  height: 100px;
  font-size: 14px;
}

div.md-open-menu-container {
  -webkit-transform: scale(1, 1) !important;
  transform: scale(1, 1) !important;
}

.md-datepicker-calendar-pane {
  //make sure the popover is almost on top
  z-index: 999999;
}
