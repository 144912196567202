body > .popover {
  width: 384px;
}

.reminder-popup-container {
  th {
    text-align: left;
    border: none;
  }
}

.full-calendar-container{
  $accepted-color: $highlight-color;
  $pending-color: $accepted-color;
  $external-color: #b5b1b1;

  position: absolute;
  top: 30px;
  bottom: 15px;
  left: 15px;
  right: 15px;

  .fc-time-grid-event.fc-short .fc-title, .fc-time-grid-event.fc-short .fc-time {
    font-size: 1em;
  }

  .popover {
    width: 384px;
  }

  .loading-container{
    margin: auto;
    text-align: center;

    .loading-bar-container{
      margin: auto;
      background-color: transparent;
    }
  }

  .calendar-event-pending, .calendar-event-placeholder, .fc-helper{
    background-color: $pending-color;
    border-radius: 0;
    cursor: pointer;
    opacity: 0.5;

    border: none;
    border-left: 1px solid $inverted-text-color !important;
    border-bottom: 1px solid $inverted-text-color !important;

    &.highlighted{
      opacity: 1;
    }
  }

  .calendar-event-now{
    background-color: $danger-color;
    color: $danger-color;
    border: none;
    opacity: 1;

    .fc-time{
      color: transparent;
    }
  }

  .calendar-event-accepted, .calendar-event-openhouse, .calendar-event-primetime{
    background-color: $highlight-color;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 6px solid rgba(0,0,0,0.2) !important;
    font-size: 9px;
    border-radius: 0;
    cursor: pointer;
  }

  .calendar-event-officehours{
    background-color: $background-color;
    opacity: 1;

    &.calendar-event-officehours-team {
      opacity: 0.2
    }
  }

  .calendar-event-external, .calendar-event-busy{
    background-color: $external-color;
    cursor: pointer;
    border-radius: 0;
    color: $main-text-color;
    font-size: 9px;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 6px solid $main-text-color;
   }

  .full-calendar{
    float: left;
    .fc-view-container{
      .fc-bg{
        background-color: $inverted-text-color;
      }
    }

    .fc-widget-header{
      background-color: $highlight-color;
      color: $inverted-text-color;
    }

    .fc-toolbar{
      margin-bottom: 5px;
    }

    .fc-button-group{
      .fc-button{
        background: $inverted-text-color;
        width: 55px;
        height: 28px;
        font-size: 12px;
        border: 1px solid $border-color;

        &.fc-state-active{
          box-shadow: none;
          background-color: $highlight-color;
          color: $inverted-text-color;
          text-shadow: none;
          border: none;
        }
      }
    }

    .fc-center{
      h2{
        font-size: 20px;
        color: $lighter-text-color;
      }
    }

    .fc-prev-button, .fc-next-button{
      background: $inverted-text-color;
      border: none;
      box-shadow: none;
      width: 25px;
      height: 25px;
      font-size: 16px;
      color: $medium-text-color;
      padding: 0;
      line-height: 25px;

      &:hover{
        color: $highlight-color;
      }
    }
  }

  .calendar-row{
    width: 100%;
    height: 100%;
    min-width: 1000px;
  }

  .full-calendar-col{
    float:left;
    position: absolute;
    left: 300px;
    bottom: 0;
    top: 0;
    right: 0;
    min-width: 600px;
  }

  .sidebar-col{
    float: left;
    width: 310px;
    height: 100%;
    position: relative;
  }

  .request-view-container{
    position: relative;
    height: 100%;
    width: 100%;

    hr{
      color: $medium-text-color;
    }

    .h3{
      text-align: left;
    }
    background-color: $background-color;
    padding-left: 10px;
  }

  .calendar-header-btn{
    background-color: $highlight-color;
    font-size: 12px;
    border: none;
    height: 28px;
    color: $inverted-text-color;
  }

  .team-member-show-everyone{
    color: $lighter-text-color;
    margin-bottom: 15px;
    font-size: 14px;

    button {
      color: $medium-text-color;
      border-radius: 0;
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  .team-appointments-container{
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    width: 300px;
    bottom: 15px;
  }
}

@import 'sidebarAppointment';
@import './_teamMemberAppointments.scss';
