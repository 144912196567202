.large-image-carousel-container {
  text-align: center;

  .large-image-carousel-count{
    font-size: 18px;
    color: $highlight-color;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .image-picker-button {
    position: absolute;
    left: 50%;
    bottom: 10px;
    width: 150px;
    margin-left: -75px;

    .btn {
      border: 1px solid $inverted-text-color;
    }
  }

  .large-image-carousel-arrow{
    position: fixed;
    width: 70px;
    height: 200px;
    margin-top: -100px;
    top: 50%;
    font-size: 72px;
    color: $highlight-color;

    &.arrow-left {
      left: 0;
    }

    &.arrow-right {
      right: 0;
    }
  }

  .large-image-carousel-display-container {
    position: fixed;
    top: 65px;
    left: 70px;
    right: 70px;
    bottom: 115px;

    .image-display {
      position: absolute;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition(0.75s);

      &.show {
        opacity: 1;
        @include transition(0.75s);
      }
    }
  }

  .image-gallery-container {
    position: fixed;
    bottom: 30px;
    left: 70px;
    right: 70px;

    .image-gallery-display{
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      height: 52.5px;
      width: 52.5px;
      background: center no-repeat $fade-color;
      background-size: cover;

      &.selected{
        box-shadow: inset 0 0 0 1px $highlight-color;
      }
    }
  }
}