.string-array-input-container {
  input {
    width: 464px;
  }

  .remove-button {
    position: relative;
    top: 10px;
    padding: 0;
    margin: 0;

    i {
      color: $icon-color;
    }
  }
}
