.notification-preferences-container{
  font-size: 14px;

  .container > div {
    margin-bottom: 8px;
  }

  .notification-preferences-row{
    margin-top: 10px;
  }

  .save-button-row{
    margin-top: 20px;
    text-align: left;
    button{
      text-align: center;
      min-width: 100px;
    }
  }
}
