.prospect-send-message-modal {
  position: relative;
  width: 1024px;

  share-listing-menu,
  quick-replies,
  .messaging-footer-icon,
  .conversation-text-area-footer-icon {
    margin-left: 5px;
    &:hover {
      cursor: pointer;
    }
    .fa {
      font-size: 18px;
    }
  }

  .send-message-icons {
    padding-left: 25px;
  }

  .cant-send-alert {
    font-size: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
  }

  md-input-container {
    padding-bottom: 0;
  }

  .ta-editor.ta-html,
  .ta-scroll-window.form-control,
  .ta-root.focussed > .ta-scroll-window.form-control,
  .ta-scroll-window > .ta-bind {
    min-height: 120px;
  }

  .typing-indicator-wrapper {
    position: absolute;
    top: 18px;
    right: 24px;
    z-index: 2;
  }

  #bulk-formatting-options {
    margin-left: 12px;
    .btn-group {
      margin-left: -4px;
      & > .btn {
        color: $medium-dark-text-color;
        cursor: pointer;
        &.active {
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.125);
        }
      }
    }
  }
}

.messaging-subject {
  width: 100%;
  input {
    border-radius: 6px;
    border: 1px solid #F4F8F9 !important;
    width: 100% !important;
    padding: 10px;
  }
  input::placeholder {
    color: #D0D2D3;
  }
}

.messaging-body {
  textarea {
    display: none;
  }

  .placeholder-text {
    color: $placeholder-text-color;
  }
}

.messaging-footer {
  padding: 0 0 0 10px;
  font-size: 12px;
  min-height: 43px;

  .knock-alert.knock-alert-error {
    font-size: 12px;
  }

  .formatting {
    -khtml-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .md-button {
    width: 88px;
    margin-left: 10px;
    margin-right: 0;
    transition: opacity .25s ease-in-out;
    &[disabled] {
      opacity: .5;
    }
  }

  .messaging-footer-icon {
    margin-left: 5px;
    .fa {
      font-size: 18px;
      cursor: pointer;
    }
  }
}

.messaging-footer-attachments {
  .attachment-wrapper {
    margin-bottom: 4px;
    margin-left: 8px;
  }
  .active-attachment {
    background-color: $background-color;
    padding: 5px 45px 5px 15px;
    border: 1px solid $border-color;
    border-radius: 1px;
    position: relative;
    min-width: 300px;
    display: inline-block;
    .disable-attachment {
      display: inline-block;
      padding: 2px;
      position: absolute;
      top: 3px;
      right: 3px;
      cursor: pointer;
    }
  }
}
