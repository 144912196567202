.community-details-container {
  padding: 20px 15px 40px 15px;

  .contact-section {
    margin-left: 10px;
  }

  .image-carousel-container {
    max-width: 600px;
    margin-top: 10px;
  }

  .community-details-wrapper {
    margin: auto;
    max-width: 600px;

    .no-listing-message {
      font-size: 14px;
      color: $medium-text-color;
      text-align: center;
    }

    .lead-form-container {
      .listing-message {
        margin-top: 10px;
        text-align: center;
      }

      .expand-toggle {
        color: $highlight-color;
        cursor: pointer;
      }
    }

    .success-message, .fail-message {
      margin-top: 10px;
      font-size: 14px;
    }

    .success-message {
      color: $highlight-color;
    }

    .fail-message {
      color: $danger-color;
    }
  }

  .fa-building-o {
    color: $highlight-color;
  }

  .md-title {
    font-size: 16px;
  }

  .listing-rows {
    margin-top: 10px;
  }

  .listing-row {
    margin: 5px 10px;

    button {
      margin: 10px 0;
    }
  }

  .target {
    display: none;

    &.no-listing {
      display: block;
    }
  }

  .fa-align-left{
    color: #5cb8e6;
  }

  .fa-bell{
    color: #9D73C7;
  }

  .fa-paw{
    color: #8C6239;
  }

  .fa-car{
    color: #5BD4C0;
  }

  .fa-tint{
    color: #809AE5;
  }

  .fa-lightbulb-o{
    color: #EB982C;
  }

  .fa-check-circle-o{
    color: #69D169;
  }
}
