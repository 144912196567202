.resident-report-stages-container {
    position: absolute;
    top: 172px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: auto;
}

.consent-button {
    background-color: #5e2cd6 !important;
}