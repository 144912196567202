.image-carousel-container{
  margin-top: 20px;

  &:hover {
    .image-display-larger {
      display: block;
    }
  }

  .image-display-larger{
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 80px;
    height: 35px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    display: none;
    color: $inverted-text-color;

    &:hover {
      color: $medium-text-color;
    }
  }

  .image-display-container{
    background-color: $background-color;
    height: 350px;
    position: relative;

    .image-display{
      cursor: pointer;
      position: absolute;
      background: center no-repeat $background-color;
      background-size: contain;
      width: 100%;
      height: 100%;
      opacity: 0;
      @include transition(0.75s);

      &.show{
        opacity: 1;
        @include transition(0.75s);
      }
    }

    .image-display-controls{
      color: $inverted-text-color;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 5px;

      background-color: rgba(0, 0, 0, 0.75);

      > div {
        display: inline-block;
        vertical-align: middle;
      }

      .image-index-display{
        padding-left: 10px;
        padding-right: 10px;
      }

      .fa{
        font-size: 2em;
      }
    }
  }

  .image-gallery-container{
    min-height: 50px;
    margin-top: 5px;

    .image-gallery-display{
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      height: 52.5px;
      width: 52.5px;
      background: center no-repeat $fade-color;
      background-size: cover;

      &.selected{
        box-shadow: inset 0 0 0 1px $highlight-color;
      }
    }
  }
}