.printable-container {
  padding: 20px;
  font-size: 12px;
  font-family: Arial;
  color: #000;
  max-width: 100vw;

  .printable-section {
    margin-bottom: 20px;
    word-break: break-word;
  }

  .printable-header {
    text-decoration: underline;
    font-weight: bold;
  }
  .printable-with-bottom-border {
    border-bottom: 1px solid #d6d6d6;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
