.about-container{
  padding-top: 90px;
  margin: auto;
  font-size: 14px;

  h1,p{
    text-align: center;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .tab-container {
    position: relative;
    bottom: 40px;
  }

  md-tab-content,
  md-tabs-canvas {
    max-width: 1100px;
    margin: 0 auto;
  }

  md-tabs-wrapper {
    background-color: $highlight-color;
    width: 100%;
  }

  md-tabs {
    color: white;

    .md-tab {
      color: white;
    }

    md-ink-bar {
      background: #006837;
    }
  }

  .about-container {
    padding: 0;
    min-height: 100vh;
  }

  .about-team-member-department {
    color: $highlight-color;
  }

  .about-team-member-company {
    font-size: 12px;
    color: $highlight-color;
  }

  .about-team-members{
    margin: -10px auto 0 auto;
    text-align: center;
  }

  .about-team-member{
    padding: 20px 20px 0 20px;
    text-align: center;
    display: inline-block;
    border: 2px solid #F2f2f2;
    border-radius: 5px;
    height: 250px;
    width: 180px;
    margin: 15px;
  }

  .fa-linkedin-square {
    color: $linkedin-blue;
  }
  .fa-envelope {
    color: #808080;
  }
  .fa-youtube-square {
    color: $youtube-red-light;
  }
  .fa-twitter {
    color: $twitter-blue;
  }
  .fa-globe {
    color: #5BB2CF;
  }

  .about-team-member-socialmedia {
    margin-top: 10px;
    >div {
      display: inline-block;
    }
  }

  .about-team-member-name{
    font-size: 16px;
    margin-top: 15px;
  }

  .about-team-member-image{
    width: 120px;
    height: 120px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 100px;
    margin: 0 auto;
  }
}
