.manager-onboarding-modal-container{
  border: 1px solid $border-color;

  border-radius: 6px;
  width: 220px;

  z-index: 1000;
  position: fixed;

  left: 10px;
  bottom: -500px;
  @include transition(1s);

  &.show{
    @include transition(1s);
    bottom: 10px;
  }

  .manager-onboarding-header{
    background-color: $highlight-color;
    color: $inverted-text-color;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
    height: 50px;

    .manager-onboarding-header-text{
      line-height: 50px;
      display: inline-block;
      vertical-align: middle;
      font-size: 1.3em;
    }

    .close-btn{
      position: absolute;
      top: -5px;
      right: -5px;
      color: $inverted-text-color;
      &:hover{
        color: $border-color;
      }
    }
  }

  .manager-onboarding-body{
    padding: 15px 0 15px 20px;
    background-color: $inverted-text-color;

    .manager-onboarding-tour-links{
      text-align: center;
      margin: 10px 0 0 -20px;

      a{
        margin-left: 6px;
        margin-right: 6px;
      }
    }

    .manager-onboarding-requirement{
      height: 26px;
      > span{
        display: inline-block;
        vertical-align: middle;
      }

      .fa-check{
        color: $success-color;
      }

      .fa-exclamation{
        color: $danger-color;
      }

      .fa-cog{
        color: $medium-text-color;
      }

      .manager-onboarding-requirement-text{
        font-size: 1.1em;
        color: $dark-text-color;
        font-weight: bold;
        margin-right: 0;
      }
    }
  }
}