.prospect-unit-preference-container {
  padding-top: 5px;

  .unit-preference-label {
    font-size: 9px;
    padding-left: 4px;
    padding-bottom: 10px;
    font-weight: bold;
    min-height: 22px;
  }

  &.has-selection {
    .multiSelect {
      > button {
        color: $inverted-text-color;
      }

      > button::after {
        color: #666;
      }
    }
  }

  .multiSelect {
    .checkboxLayer {
      position: static;
    }

    .acol {
      min-width: 6px;
    }

    .checkBoxContainer {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 300px;
      cursor: pointer;

      & * {
        cursor: pointer;
      }
    }

    .multiSelectFocus {
      background: none !important;
      color: $text-color !important;
      border: 1px solid #fff !important;
    }

    .multiSelectItem {
      min-height: initial;
      position: static;

      &:hover {
        background: none !important;
        color: $text-color !important;
        border: 1px solid #fff !important;
      }

      &:not(.multiSelectGroup).selected {
        background: none;
        border: 1px solid #fff !important;
        position: relative;
      }

      &.selected {
        .acol label {
          color: $highlight-color;
        }
      }
    }

    > button {
      color: rgba(0, 0, 0, 0.26);
      border: none;
      border-bottom: 1px solid $border-color;
      background: none;
      border-radius: 0;
      width: 100%;
      text-align: left;
      padding: 2px 2px 0 4px;
      font-size: 12px;
      vertical-align: bottom;
      min-height: 30px !important;
      box-sizing: border-box;

      .buttonLabel {
        color: #666;
        border: 1px solid $border-color;
        padding: 0;
        border-radius: 4px;

        .caret {
          display: none;
          width: 0;
        }

        .item-text {
          padding: 4px;
          position: relative;
          top: -1px;
        }

        .close-icon {
          position: relative;
          top: -1px;
          font-size: 14px;
          padding-right: 4px;
          border-right: 1px solid $border-color;

          &:hover {
            color: $dark-text-color;
          }
        }
      }

      .caret {
        transform: translate3d(0, 1px, 0);
        width: 24px;
        border: none;
      }

      &::after {
        content: '\25BC';
        transform: scaleY(0.6) scaleX(1);
        height: 10px;
        display: block;
        text-align: end;
        margin-right: 6px;
        position: relative;
        top: -8px;
      }
    }
  }
}