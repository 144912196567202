.conversation-inbox-container {
  $conversation-inbox-width: 100%;
  $conversation-inbox-height: 100%;

  width: $conversation-inbox-width;
  height: $conversation-inbox-height;

  color: $medium-text-color;

  background-color: $inverted-text-color;

  -webkit-overflow-scrolling: auto;

  border-right: 1px solid $border-color;

  &.is-renter {
    @include transition(right 0.25s);
    left: auto;

    &.is-open {
      @include transition(right 0.25s);
      right: 0;
    }

    right: -$conversation-inbox-width;
    top: 50px;

    @media screen and (max-device-width: 600px) {
      right: -100%;
    }
  }

  md-tabs {
    margin-top: 15px;
    position: absolute;
    bottom: 0;
    top: 85px;
    left: 0;
    right: 0;
  }

  .search-input-container {
    width: 50%;
    margin-top: 10px;
    button {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      background: transparent;
      padding: 0 8px;
      height: 100%;
      font-size: 20px;
    }
  }

  .details-view {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;

    .conversation-details-container {
      position: absolute;
      top: 50px;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .back-button {
      height: 50px;
      background-color: $inverted-text-color;
      border-bottom: 1px solid $border-color;
      position: absolute;
      top: 0;
      margin: auto;
      z-index: 100000;
      width: 325px;

      a {
        color: $highlight-color;
        font-size: 16px;

        .fa-angle-left {
          font-size: 22px;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .inbox-content {
    overflow-x: hidden;

    .filter-container {
      padding-top: 20px;
      padding-bottom: 10px;

      select {
        margin: 8px 0
      }

      md-input-container {
        width: 100%;
      }
    }
  }

  .inbox-type-tabs {
    md-tabs-wrapper {
      margin-left: 8px;
    }
    .md-tab {
      text-transform: none;
      font-size: 11px;
      padding: 10px 10px;

      .tab-header {
        font-size: 13px;
      }

      .nav-link-badge {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }

  md-tab-item {
    flex-basis: 33%;
  }

  .selected-conversation-functions {
    border-bottom: 1px solid $border-color;
    padding: 5px 0;

    md-checkbox {
      margin-right: 0;
      margin-left: 10px;

      .md-icon {
        transform: scale(0.8);
      }
    }
  }

  .selected-conversation-functions-button {
    font-size: 14px;
    cursor: pointer;

    .fa-caret-down {
      margin-left: 5px;
    }
  }

  .conversation-rows, .search-result-rows {
    position: absolute;
    bottom: 0;
    top: 46px;
    left: 0;
    right: 0;
    padding-bottom: 20px;

    overflow-y: auto;

    &.no-filters {
      top: 75px;
    }
  }

  .escape-help {
    font-size: 10px;
    color: $main-text-color;
  }

  .search-help {
    font-size: 12px;
    color: $dark-highlight-color;
  }

  .search-result-rows {
    top: 100px;
  }

  .no-results, .results-header {
    font-size: 13px;
  }
  .loader-spacing {
    margin: 15px 0 5px;
  }
}

.hidden-badge-paren {
  color: $danger-color;
}

.conversation-pagination {
  position: absolute;
  top: 0;
  right: 20px;
  button {
    color: $lighter-text-color;
    font-size: 18px;
    text-align: center;
    width: 40px;
    height: 32px;
    i {
      vertical-align: middle;
    }
    &:hover:not([disabled]) {
      color: $dark-text-color;
    }
    &.conversations-loading {
      color: $lighter-text-color !important;
    }
  }
}