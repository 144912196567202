.auto-respond-editor-container{
  text-align: center;
  .auto-accept-instructions{
    text-align: center;
  }

  .auto-accept-row{
    text-align: right;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .tooltip{
    width: 320px;
  }

  .help-box{
    cursor: help;
  }

  .btn-save{
    margin-top: 15px;
  }

  .slider-container {
    .slider {
      min-width: 320px;
      margin-top: 10px;
    }
  }

  .custom-requirements-container {
    text-align: left;
  }
}