.date-selector-container{
  position: relative;
  left: -17px;

  input.flat-input{
    padding-left: 30px;
    cursor: pointer;
    display: inline;
  }

  &.hide-icon{
    left: 0;
    input.flat-input{
      padding: 8px;
    }
  }

  .fa{
    position: relative;
    left: 25px;
  }
}