.cke_combopanel,
.cke_panel,
.cke_notifications_area,
.cke_dialog_container {
  z-index: 16000 !important;
}

.cke_chrome {
  border: none !important;
  border-radius: 0 !important;
}

.cke_inner > .cke_bottom {
  background: transparent !important;
}

.cke_notification{
  pointer-events: auto;
  position: relative;
  margin: 10px;
  width:300px;
  color:white;
  text-align:center;
  opacity:.95;
  filter:alpha(opacity = 95);
  -webkit-animation:fadeIn .7s;
  animation:fadeIn .7s
}

.cke_notification_message a{
  color:#12306f
}

@-webkit-keyframes fadeIn{
  from{
    opacity:.4
  }

  to{
    opacity:.95
  }
}

@keyframes fadeIn{
  from{
    opacity:.4
  }
  
  to{
    opacity:.95
  }
}

.cke_notification_success{
  background:#72b572;
  border:1px solid #63a563
}

.cke_notification_warning{
  background:#c83939;
  border:1px solid #902b2b
}

.cke_notification_info{
  background:#2e9ad0;
  border:1px solid #0f74a8
}

.cke_notification_Info span.cke_notification_progress {
  background-color:#0f74a8;
  display:block;
  padding:0;
  margin:0;
  height:100%;
  overflow:hidden;
  position:absolute;
  z-index:1
}

.cke_notification_message{
  position:relative;
  margin:4px 23px 3px;
  font-family:Arial,Helvetica,sans-serif;
  font-size:12px;
  line-height:18px;
  z-index:4;
  text-overflow:ellipsis;
  overflow:hidden
}

.cke_notification_close{
  display: none !important;
}

/* Hide the image upload by url button (removing it from the toolbar causes drag and drop to not work) */
.cke_button__image{
  display: none !important;
}

.cke_autocomplete_panel{
  z-index: 15001 !important;
}

.cke_dialog_background_cover{
  display: none;
}
