.basics-container{
  .col-sm-9{
    position: relative;
  }

  .form-group{
    position: relative;
    min-height: 25px;
    margin-top: -1px;

    @media screen and (max-width: 768px){
      min-height: 60px;
    }
  }

  .full-width-input{
    position: absolute;
    left: 0px;
    right: 0px;
  }

  .unit-type-dropdown {
    position: absolute;
    left: 0px;
    .dropdown-toggle {
      box-shadow: none;
      min-width: 150px;
      height: 40px;
      color: $medium-text-color;
      text-align: left;
    }
  }

  .address-fields{
    .street-address{
      position: absolute;
      left: 0px;
      border-right: none;
      width: 75%;
      &:hover{
        border-right: none;
      }
    }

    .unit{
      position: absolute;
      right: 0px;
      width: 25%;
    }
  }

  .state-dropdown{
    ul.dropdown-menu{
      max-height: 200px;
      overflow-y: auto;
    }
  }

  .city-state-zip-fields{
    .city{
      width: 55%;
      position: absolute;
      left: 0px;
      border-right: none;
      &:hover{
        border-right: none;
      }
    }

    .state-dropdown{
      width: 20%;
      height: 40px;
      position: absolute;
      left: 55%;
      &:hover{
        border-right: none;
      }
      .btn{
        text-align: left;
        width: 100%;
        height: 100%;
        border-right: none;
      }
    }

    .zip{
      width: 25%;
      position: absolute;
      right: 0px;
    }
  }

  .bedrooms, .bathrooms, .square-feet, .available-date{
    input{
      max-width: 120px;
      position: absolute;
      left: 0px;
    }
  }

  .square-feet{
    input {
      max-width: 150px;
    }
  }

  .pets-group{
    padding-top: 10px;
  }

  .pet-notes, .listing-description{
    padding-left: 0px;
  }

  .btn-save{
    padding-left: 0px;
    .btn-success{
      margin-top: 10px;
    }
  }
}