.calendar-popover-container{
  .calendar-popover-accept-request{
    border-top: 1px solid $border-color;
    padding-top: 10px;
    margin-top: 10px;
    text-align: right;
  }

  .listing-index-function-badge.left-badge {
    left: inherit;
    right: 130px;
    z-index: 1000;
  }

  .popover-listing-container {
    position: relative;
  }

  .calendar-popover-functions{
    position: absolute;
    top: 3px;
    right: 2px;

    > div {
      height: 25px;
      width: 25px;
    }

    .manual-appt-button {
      position: relative;
      top: 5px;
      i {
        color: $alternate-highlight-color;
        font-size: 18px;
      }
    }

    .transfer-button{
      a{
        color: $highlight-color;
        font-size: 18px;
      }
    }

    .cancel-button{
      a{
        color: $danger-color-soft;
        font-size: 18px;
      }
    }

    .close-button{
      a{
        font-size: 18px;
        color: $lighter-text-color;

        &:hover{
          color: $medium-text-color;
        }
      }
    }
  }
}

.prime-time-popup-container {
  .listing-index-function-badge.left-badge {
    z-index: 1000;
    top: 40px;
    left: 8px;
  }
}

.visit-popup-container {
  .transfer-button {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}
