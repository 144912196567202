listing-result {
  $tag-color: #ECC335;
  $schedule-now-color: $highlight-color;
  $open-house-color: #6284CF;

  .listing {
    cursor: pointer;
    margin-top: 5px;
    width: 300px;
    min-height: 176px;
    background-size: cover;
    background-color: $fade-color;
    position: relative;
  }

  .tag-button{
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 5px;

    .fa-certificate{
      color: $tag-color;
      font-size: 2.5em;
    }
    .fa-tag{
      color: #fff;
      font-size: 1.5em;
      margin-top: 6px;
      margin-left: 1px;
    }
  }

  .listing-info{
    font-size: 0.9em;
    padding: 2px 6px 2px 6px;
    position: absolute;
    bottom: 0;
    width: 300px;
    background: rgba(0, 0, 0, 0.4);
    color: $inverted-text-color;

    .info-price{
      font-size: 1.6em;
      font-weight: 600;
    }

    .info-status-tag{

      padding: 3px 4px 3px 4px;
      border-radius: 3px;
      margin-top: 1px;

      &.schedule-now{
        background: $schedule-now-color;
      }

      &.open-house{
        background: $open-house-color;
      }
    }
  }
}
