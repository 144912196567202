.available-units-dialog {
  width: 1200px;
  min-height: 600px;
}
.available-units-dialog, .md-open-menu-container {
  md-toolbar {
    min-height: 42px;
    .menu-header {
      min-height: 30px;
      font-size: 14px;
      background-color: $border-color;

      .menu-title {
        text-align: center;
      }

      .close-button {
        position: relative;
        left: -3px;
        font-size: 18px;
      }
    }
  }
  md-dialog-content{
    padding: 3px;
    .available-units-container {
      height: 100%;
      min-width: 600px;
      md-input-container {
        min-width: 99px;
      }
      .quote-container{
        width: 140px;
      }
      .property-dropdown {
        width: 160px;
        .tooltip {
          max-width: 180px;
        }
      }
      .property-paste-btn {
        display: flex;
        justify-content: space-between;
        width: 134px;
        padding: 4px 10px;
        background-color: $white;
        color: $denim-600;
        border: 1px solid $denim-600;
        font-size: 13px;
        font-weight: 600;
        font-family: 'Open Sans';
        letter-spacing: 0.4px;
        line-height: 22px;
        margin-top: 20px;
        .fa-paste {
          margin-left: 5px;
        }
      }
  
      a {
        color: $medium-text-color;
  
        &:hover {
          color: $highlight-color;
          cursor: pointer;
        }
      }
  
      button.md-button.md-flat.md-button-round {
        margin: 0;
  
        &.md-primary-alt {
          background-color: $alternate-highlight-color;
          color: $inverted-text-color;
        }
      }
  
      button.appfolio-button{
        background-color: #673BED;
      }
  
      .office-business-svg{
        height: 15px;
        width: 20px;
        left: 2px;
      }
  
      .rent-matrix-button {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
        min-height: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
      }
  
      .table-container {
        table {
          margin-bottom: 0;
        }
        overflow-y: auto;
      }
  
      .unit-price-container {
        display: flex;
      }
    }
  }
}
