.profile-editor-container{
  font-size: 1.1em;
  border-top: 1px solid $border-color;
  color: $medium-text-color;

  .loader{
    text-align: center;
    .loading-bar-container{
      margin: auto;
      background-color: transparent;
    }
  }

  .profile-phone-verified{
    color: $highlight-color;
    .fa{
      position: relative;
      top: 1px;
      font-size: 1.3em;
    }
  }

  .profile-display-row{
    margin: 20px auto 35px auto;
    max-width: 400px;
    padding-left: 10px;

    .profile-editor-display-container {
      > div {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .profile-image{
      position: relative;
      width: 125px;
      height: 125px;
      background-size: cover;
      background-position: center;
      border-radius: 50%;

      .profile-image-editor-toggle{
        position: absolute;
        bottom: -30px;
      }

    }

    .profile-info{
      margin-left: 20px;

      .profile-name{
        font-weight: bold;
        font-size: 1.3em;
      }
    }
  }

  .profile-photo-editor-row{
    max-width: 800px;
    margin: auto;
  }

  .profile-editor{
    background-color: $inverted-text-color;

    .profile-editor-row{
      margin: auto;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 10px;
      width: 100%;
      border-bottom: 1px solid $border-color;
      > div{
        max-width: 800px;
        margin: auto;
      }

      .profile-editor-row-header{
        color: $highlight-color;
        font-weight: bold;
        max-width: 800px;

        > a{
          cursor: pointer;
        }
      }

      .profile-editor-row-body{
        margin-top: 15px;
      }

      form{
        div.form-group{
          margin-bottom: 0;
          height: 30px;
          margin-left: 0;
          margin-right: 0;
          > label, > div {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
            max-height: 30px;
            .btn-primary{
              width: 78px;
              height: 32px;
            }
            .btn-link{
              padding: 0;
              &.edit {
                width: 50px;
              }
            }
          }
        }
      }
    }
  }
}
