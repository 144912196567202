$slate100: #F1F1F4;

.unsubscribe-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $slate100;

  width: 100%;
  height: 100%;

  .unsubscribe-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;

    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 75px;
    margin-right: 75px;

    @media only screen and (max-width: 768px) {
      margin-left: 16px;
      margin-right: 16px;
    }

    .icon-container {
      margin-bottom: 24px;
      margin-top: 24px;
    }
  }
}
