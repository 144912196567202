.reset-forgotten-password-container{
  padding-top: 50px;
  max-width: 500px;
  margin: auto;

  .reset-password-functions{
    text-align: center;
  }

  .passwords-no-match{
    position: relative;
    top: 10px;
  }

  .password-reset-success{
    a{
      text-decoration: underline;
    }
  }
}