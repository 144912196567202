.export-calendar-modal-container{
  .modal-header{
    padding: 10px 10px 10px 15px;
    background-color: $background-color;
  }

  .modal-footer{
    background-color: $background-color;
  }

  .loader{
    text-align: center;
    .loading-bar-container{
      margin: auto;
    }
  }

  .calendar-url-container{
    .input-group{
      margin: auto;
      max-width: 585px;
    }
  }

  input.form-control{
    height: 32px;
    padding: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    background-color: $border-color;
  }

  .alert{
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    border: none;
    padding: 5px;
  }
}