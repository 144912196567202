.listing-saving-overlay{
  text-align: center;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $inverted-text-color;
  z-index: 1000;

  .listing-saving-overlay-content-offset{
    position: relative;
    top: -50px;
  }

  .icon-bounce{
    width: 50px;
    vertical-align: bottom;
  }

  .loading-bar-container{
    margin: auto;
    margin-top: 10px;
    width: 500px;

    .loading-progress-bar{
      width: 300px;
    }
  }

  .listing-saving-overlay-text{
    font-size: 18px;
  }

  .listing-saving-overlay-content{
    padding: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
}

.listing-editor-container{

  @import "./_listingEditorBasics.scss";

  .listing-leasing-form{
    textarea{
      width: 340px;
      height: 100px;
      border: none;
      resize: none;
      margin-top: 8px;
    }

    .listing-lease-lengths-row{
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .ide-document-form-column{
      width: 330px;

      input{
        width: 330px;
      }

      .dollar-sign{
        position: relative;
        left: 25px;
      }

      &.listing-monthly-rent{
        position: relative;
        left: -15px;

        input {
          width: 200px;
          padding-left: 30px;
        }

      }

      &.ide-document-form-label{
        width: 150px;
      }
    }

    .listing-sublet-row{
      padding-top: 8px;
    }
  }

  .listing-application-form{
    .listing-application-instructions-row {
      .ide-document-form-label {
        padding-top: 8px;
        width: 150px;
      }

      .ide-document-form-column {
        vertical-align: top;

        .url-validation{
          color: $danger-color;
        }

        .application-url {
          width: 340px;
        }
      }
    }

    textarea{
      width: 340px;
      height: 100px;
      border: none;
      resize: none;
      margin-top: 8px;
    }
  }

}
