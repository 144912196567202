.refer-tooltip {
  margin-left: 7px;

  .fa {
    font-size: 14px;
    color: $dark-text-color;
  }

  a {
    font-size: 14px;
    text-decoration: underline !important;
  }
}