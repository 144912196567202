.request-account-modal-container {
  max-width: 415px;

  .close-button {
    position: absolute;
    right: 10px;
    top: 5px;

    i {
      font-size: 18px;
    }
  }

  md-input-container {
    padding-bottom: 5px;
  }

  md-dialog-content {
    padding: 20px 50px;

    .knock-form-subheader {
      max-width: 225px;
      text-align: center;
      margin: auto;
    }
  }

  .continue-button-container {
    button {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .sign-in-text, .terms-text {
    margin-bottom: 20px;
  }

  .request-account-footer {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .request-success {
    text-align: center;
  }

  .demo-button-container {
    padding-bottom: 30px;

    .demo-radio-container {
      margin-bottom: 20px;
    }
  }
}
