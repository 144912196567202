.phone-verification-modal{
  .modal-header{
    background-color: $background-color;
  }

  .instructions{
    .resend-btn{
      margin-top: 10px;
    }
  }

  .verification-buttons{
    margin-right: 10px;
    margin-left: 10px;
  }

  .sms-btn{
    display: inline-block;
  }

  .call-btn{
    display: inline-block;
  }

  .code-entry-container{
    margin: auto;

    .code-message-container{
      text-align: right;
    }

    .code-input-container{
      padding-left: 0;
      padding-right: 0;

      input{
        height: 32px;
      }
    }
  }
}
