.kanban-headers {
    display: flex;
    padding: 10px 0;
    margin: 0 0 20px;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;

    .kanban-header {
        width: 265px;
        min-width: 265px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: 5px;
            width: 20px;
            height: 20px;
            border-right: 1px solid $darker-border-color;
            border-top: 1px solid $darker-border-color;
            transform: rotate(45deg);
            top: calc(50% - 10px);
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }

        .kanban-header-inner {
            text-align: center;
            padding: 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;

            .column-heading {
                color: $dark-text-color;
                font-size: 16px;
            }
        }

        .section-heading-holder {
            position: absolute;
            bottom: -66px;
            left: 6px;
            width: 245px;
            height: 36px;
            line-height: 36px;
            background: $trello-gray;
            box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
            display: none;
            z-index: 1;
            text-transform: capitalize;
            padding-left: 34px;
            font-weight: 700;
            font-size: 13px;
            text-align: left;
            color: $dark-text-color;
            cursor: pointer;

            &:before {
                content: '[-]';
                position: absolute;
                left: 8px;
                top: 0;
                font-weight: 400;
                font-size: 14px;
            }

            &.active {
                display: block;
            }

            .kanban-header-inner {
                padding-left: 0;
            }
        }
    }
}