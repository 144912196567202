.upload-recording-container {
  width: 100px;

  label {
    margin-right: 5px;
  }

  .recording-upload {
    width: 0px;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
