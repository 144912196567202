.prospect-event-card-container {
  border-radius: 6px;
  border: 1px solid $border-color;

  width: 280px;
  margin-bottom: 10px;

  font-size: 12px;

  .appointment-time {
    color: $highlight-color;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .event-function {
    padding: 5px;
  }

  .shown-units-title {
    margin-right: 5px;
  }

  .edit-shown-units {
    margin-left: 5px;
  }

  >.complete-appointment-link {
    color: $accent-color-dark;
  }
}
