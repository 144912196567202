.renter-profile-modal-container {
  width: 500px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  md-toolbar {
    height: 120px;
    min-height: 120px;

    h2 {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 275px;
      overflow: hidden;
    }
  }

  .md-toolbar-tools {
    height: 120px;
    max-height: 120px;
  }

  .renter-attribute {
    max-width: 400px;
  }

  .renter-attribute-label {
    font-weight: bold;
    width: 150px;
    text-align: left;
    color: $placeholder-text-color;
  }

  .renter-attribute-value {
    font-size: 12px;
    margin-left: 10px;
    text-align: left;
    color: $dark-text-color;
    font-weight: bold;
  }

  md-card {
    box-shadow: none;
  }
}