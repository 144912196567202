.RentMatrixModal {
  width: unset;
}

.RentMatrixModal__content {
  overflow: auto;

  caption {
    text-align: left;
    position: sticky;
    left: 0;
    width: 250px;
  }

  th, td {
    white-space: nowrap;
  }

  thead {
    th:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      background-color: white;
    }
  }

  tbody {
    th {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    tr:first-child {
      th {
        border-top: 0;
      }
    }

    tr:nth-child(even) > th {
      background-color: #fff;
    }
  }

  .entrataRentMatrixStartDatePicker {
    padding-bottom: 12px;
  }

  .entrataRentMatrixStartDateText {
    padding-top: 8px;
  }
}

.dropdown-menu.ng-valid-date {
  z-index: 15002;
}
