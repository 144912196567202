.kanban-container {
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 20px 20px;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}