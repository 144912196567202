md-dialog.renter-auth-modal-container {
  max-width: 415px;
  max-height: 100%;

  md-dialog-content {
    .form-content {
      padding: 20px 35px;
    }
  }

  .md-tab {
    width: 50%;
  }

  md-pagination-wrapper {
    width: 100%;
  }

  md-tab-item {
    max-width:none !important;
  }

  .facebook-btn, .linkedin-btn {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    i.fa {
      margin-right: 5px;
    }
  }

  .form-or {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }

  .create-account-button-container {
    text-align: center;

    button {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .sign-in-text, .forgot-password {
    margin-bottom: 20px;
  }

  .terms-text {
    text-align: center;
  }
}