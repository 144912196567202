.community-basics-form{

  .community-username-available{
    color: $highlight-color;
  }

  .community-username-unavailable{
    color: $danger-color;
  }

  .community-username-invalid{
    color: $danger-color;
  }

  ul.dropdown-menu{
    max-height: 200px;
    overflow-y: auto;
  }

  .btn.dropdown-toggle{
    text-align: left;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 13px;
  }

  .single-field-row{
    .ide-document-form-column.ide-document-form-label{
      width: 150px;
    }

    .ide-document-form-column{
      input {
        width: 250px;
      }

      .knock-email-input {
        width: 100px;

        &.has-knock-email {
          width: 250px;
        }
      }
    }
  }

  .state-dropdown, .unit-type-dropdown{
    &:hover{
      border-right: none;
    }
  }

  .community-address-row{
    .ide-document-form-label{
      width: 150px;
    }

    .community-street-address{
      input{
        width: 300px;
      }
      width: 300px;
    }
  }

  .community-city-state-zip-row{
    .ide-document-form-label{
      width: 150px;
    }

    .community-city{
      width: 190px;
    }

    .community-state{
      width: 70px;
    }

    .community-zip{
      width: 70px;
    }
  }

  .community-pets-row{
    padding-top: 8px;

    .ide-document-form-label{
      width: 150px;
    }

    .community-pets-checkboxes{
      width: 200px;
    }

    .ide-document-form-link{
      font-style: italic;
    }
  }

  .community-description-row, .community-pet-notes-row{
    .ide-document-form-label{
      padding-top: 8px;
      width: 150px;
    }

    .ide-document-form-column{
      vertical-align: top;

      .url-validation {
        color: $danger-color;
      }

      .video-url-input {
        width: 340px;
      }
    }

    textarea{
      width: 340px;
      height: 100px;
      border: none;
      resize: none;
      margin-top: 8px;
    }
  }

}
