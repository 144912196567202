.renter-review-container{
  padding-top: 50px;
  width: 100%;
  height: 100%;
  background: $inverted-text-color;
  background-size: cover;

  .fancy-radio{
    width: 20px;
    height: 20px;
  }

  .radio-button-col{
    text-align: center;

    md-radio-button {
      position: relative;
      left: 5px;
      top: -2px;
      margin-top: 0;
    }
  }

  .renter-review-rows {
    background-color: $inverted-text-color;
    max-width: 350px;
    padding: 20px;
    margin: auto;
    color: $medium-text-color;
  }

  h3, h4{
    text-align: center;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .header-subtext{
    text-align: center;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .renter-review-listing-container {
    margin-bottom: 10px;
  }

  .view-listing-link{
    text-align: right;
  }

  .review-header-row{
    margin-bottom: 5px;
  }

  .review-header{
    text-align: center;
    line-height: 14px;
  }

  .col-xs-3{
    vertical-align: middle;
  }

  .review-section-label{
    strong {
      line-height: 20px;
    }
  }

  .review-options-container{
    font-size: 14px;
  }

  .review-comments-container{
    font-size: 14px;
    margin-top: 10px;
  }

  textarea{
    margin-top: 10px;
    width: 100%;
    height: 90px;
    padding: 10px;
    resize: none;
    background-color: $background-color;
    border: none;
    border-radius: 6px;
    font-size: 14px;
  }

  .review-send-review{
    margin-top: 5px;
    button {
      margin-left: 0;
      width: 100%;
      height: 40px;
    }
  }

  .star-rating-container{
    width: 100%;
    text-align: center;
    margin-top: 6px;
  }

  .renter-review-saved{
    text-align: center;
    h2{
      color: $inverted-text-color;
    }

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 15px;

    height: 130px;

    margin: auto;

    background-color: rgba(0, 0, 0, 0.5);
  }
}