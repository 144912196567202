.prime-time-schedule-container{
  h5{
    font-weight: bold;
  }

  .selected-time{
    font-size: 18px;
    margin-top: 5px;

    .knock-strong{
      text-decoration: underline;
    }
  }

  .prime-time-rsvp-functions-container{
    margin-top: 10px;
    margin-bottom: 20px;

    > div {
      display: inline-block;
      vertical-align: middle;
      margin: 3px;
    }
  }

  .time-selection-btn{
    padding: 0;
    margin: 5px;
    width: 80px;
    height: 35px;
    text-align: center;
    opacity: 1;
    @include transition(0.2s);

    &.not-selected{
      opacity: 0.5;
      @include transition(0.2s);
    }
  }
}

