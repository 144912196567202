.on-off-switch-container{
  $height: 26px;
  $width: $height * 2 - 8;

  @mixin transition($seconds){
    -webkit-transition: $seconds;
    -moz-transition: $seconds;
    -ms-transition: $seconds;
    -o-transition: $seconds;
    transition: $seconds;
  }

  cursor: pointer;

  &.on{
    .switch-background{
      background-color: $highlight-color;
      @include transition(0.25s);
    }

    .switch-foreground{
      right: 0;
      @include transition(0.25s);
    }
  }

  &.off{
    .switch-background{
      background-color: $inverted-text-color;
      @include transition(0.25s);
    }

    .switch-foreground{
      right: $height - 7;
      @include transition(0.25s);
    }
  }

  .switch-background{
    width: $width;
    height: $height;
    border-radius: $height / 2;
    position: relative;
    border: 1px solid $border-color;
  }

  .switch-foreground{

    background-color: $inverted-text-color;
    position: absolute;
    height: $height - 2;
    width: $height - 2;
    border-radius: 50%;
    border: 1px solid $border-color;
    box-shadow: 0px 1px 1px $border-color;
  }
}