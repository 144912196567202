.preferences-tab {
  .knock-email-editor-container {
    text-align: left;
    padding-bottom: 40px;

    .knock-email-editor-row > p {
      text-align: left;
      font-size: 12px;
    }

    .mailer-creates-leads {
      text-align: left;
      font-size: 10px;
    }

    .email-form {
      font-size: 14px;
    }

    textarea.flat-input {
      width: 400px;
      height: 100px;
    }

    input.flat-input {
      width: 335px;
    }

    .btn-copy {
      position: relative;
      top: -2px;
    }

    .knock-email-editor-row {
      margin-top: 10px;
    }
  }
}
