@import 'src/LegacyAngularApp/legacy-angular-app/core/styles/_colors.scss';

.create-quote-dialog {
    .create-quote-dialog-content {
        max-height: 438px;
        overflow-y: auto;
    }

    .create-quote-dialog-actions {
        padding: 16px 24px;
    }

    .concession-checkbox {
        svg {
            height: 24px;
            width: 24px;
        }
    }

    .unit-details {
        margin: 10px 0;

        .unit-details-list {
            padding-top: 4px;
        }

        .unit-details-title {
            display: inline-block;
            width: 184px;
        }
    }

    .lease-details {
        margin-bottom: 16px;


        .lease-details-row {
            display: flex;
            justify-content: space-between;
            margin-top: 17px;
        }

        .lease-term-select {
            border-color: $slate-500;
        }

        .date-picker,
        .lease-term-select,
        .pro-rated-days {
            width: 233px;
        }

        .lease-details-display-title,
        .lease-details-display-value {
            display: inline-block;
            width: 184px;
        }

        .lease-details-options {

            .lease-term-select-wrapper {
                margin-bottom: 5px;
            }

            #lease-term-select-label {
                font-size: 14px;
                margin-top: 4px;
                margin-bottom: 0;
                margin-left: 4px;
            }

            .lease-term-select {
                margin-top: 4px;
            }

            .pro-rated-days .option-label {
                margin: 4px;
            }

            .date-picker {
                label {
                    font-size: 14px;
                    transform: none;
                    margin-bottom: 0;
                    left: 4px;
                }

                input {
                    height: 24px;
                }

                .calendar-icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                }
            }

            .MuiInputBase-input {
                line-height: 24px;
                padding: 9px 0 7px;
            }

            .MuiInputBase-root .MuiSvgIcon-root {
                height: 24px;
                width: 24px;
                color: $text-secondary;
           }
        }
    }

    .select-from-rent-matrix-button,
        .generate-concessions-button {
            padding: 6px 4px;
        }

    .quote-summary-close-button {
        float: right;
        margin-left: auto;
    }

    .quote-summary-display-title,
    .quote-summary-display-value {
        display: inline-block;
        width: 184px;
    }

    .quote-summary-view-pdf-link {
        display: inline-block;
        margin-top: 16px;
        margin-bottom: 16px;

        svg {
            margin-right : 8px;
            vertical-align: middle;
        }
        &:hover, &:focus, &:active {
            color: $denim-500;

            .document-icon path {
                stroke: $denim-500;
            }
        }
    }
}
