.todo-icon-container {
  width: 15px;

  .todo-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid $inverted-text-color;

    &.red {
      background-color: $danger-color;
    }

    &.yellow {
      background-color: $bright-highlight-color;
    }

    &.green {
      background-color: $dark-highlight-color;
    }
  }
}
