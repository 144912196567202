.share-listing-modal-container{
  .modal-header{
    text-align: center;
    .share-icon{
      color: $highlight-color;
      margin-right: 10px;
    }

    > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .modal-body{
    padding: 15px;
    .share-method-label{
      margin-top: 5px;
    }
    .share-methods-container{
      .share-method-row{
        > div {
          display: inline-block;
          vertical-align: middle;
        }
        .share-method-field-container{
          width: 65%;
          float: left;
          padding: 5px;
          input{
            height: 32px;
          }
        }

        .share-method-button-container{
          width: 35%;
          padding: 5px 5px 5px 0;
          button{
            width: 100%;
          }
        }
      }
    }
  }

  .modal-footer{
    text-align: center;
    padding: 0;
    border-top: none;
    .alert{
      margin: 10px;
    }
  }
}

.share-listing-dialog-container{
  i{
    color: $highlight-color;
  }

  p,
  .send-text-button,
  .success-message{
    text-align: center;
  }
}