.top-nav-bar {
  .menu-icon-button{
    margin-left: 12px;
  }
}
.MuiPopover-paper{
  .MuiListItem-root {
      color: rgba(0,0,0, 0.87);
      cursor: pointer;
      padding: 8px 0px 8px 16px;
      line-height: 150%;
      &:hover {
        background-color: #F5F6FF;
        color: inherit;
        text-decoration: none;
      }
  }
  .MuiTypography-body1 {
    line-height: 150%;
    letter-spacing: 0.15px
  }
}