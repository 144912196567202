.craigslist-share-modal-container{
  color: $medium-text-color;
  .modal-header{
    text-align: center;
    h4 {
      color: $highlight-color;
    }
  }

  .craigslist-share-instructions{
    text-align: center;
  }

  .craigslist-share-markup-content{
    margin-top: 10px;
  }

  #copy-and-post-to-cl {
    background-color: $alternate-highlight-color;
    margin-top: 5px;
  }
}