.conversation-page-container {
  height: 100%;
  width: 100%;

  .conversation-panel-wrapper {
    position: absolute;
    top: 0;
    left: 325px;
    bottom: 0;
    right: 0;

    &.is-renter {
      top: 50px;
      left: 0;
      right: 325px;
    }

    @media screen and (max-device-width: 600px) {
      right: 0;
    }
  }
}
