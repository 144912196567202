.payment-data-entry-container{
  padding: 75px 10px 20px 10px;
  max-width: 700px;
  margin: auto;

  .payment-data-entry-header{
    > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .plan-name-and-logo{
    > div {
      display: inline-block;
      vertical-align: middle;
    }

    .plan-name-and-back-link{
      margin-left: 15px;

      .dark-link{
        margin-left: 3px;
        text-decoration: none;
      }
    }
  }

  .plan-name{
    margin: 0;
  }

  .plan-logo{
    background-position: center;
    background-size: cover;
    width: 111px;
    height: 95px;
  }

  .plan-duration-selector{
    margin-left: 70px;
    .payment-duration{
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      border: 1px solid $border-color;

      width: 150px;
      height: 94px;
      padding: 10px 0 10px 0;
      margin-left: 20px;

      h3{
        margin-top: 0;
      }

      &.selected{
        background-color: $inverted-text-color;
        border: 1px solid $dark-highlight-color;
      }

      .payment-duration-note-top{
        h5 {
          color: $highlight-color;
          font-weight: bold;
          margin: 0 0 5px 0;
        }
      }

      .payment-duration-note-bottom{
        font-size: 1.15em;
      }
    }
  }

  .payment-plan-details{
    margin-top: 20px;
    padding: 15px 20px 20px 20px;
    background-color: $inverted-text-color;
    font-size: 1.15em;

    .payment-plan-details-row {
      border-bottom: 1px solid $border-color;
      padding: 10px 0;

      .line-item {
        position: relative;

        .line-item-price {
          position: absolute;
          right: 0;
        }
      }

      &.total{
        font-size: 1.5em;
        font-weight: bold;
        border-bottom: none;
        padding-top: 20px;
        padding-bottom: 0;
      }
    }
  }

  .payment-plan-data-entry{
    margin-top: 20px;
    padding: 15px 20px 20px 20px;
    background-color: $inverted-text-color;
    font-size: 1.15em;

    .payment-plan-select-billing-method{
      border-bottom: 1px solid $border-color;
      padding-bottom: 15px;

      .select-card{
        margin-top: 10px;
      }
    }

    .payment-plan-entry-review-button{
      text-align: center;
      padding-top: 20px;
      .review-order-btn{
        font-size: 1.2em;
        width: 200px;
      }
    }
  }

  .payment-plan-data-review{
    margin-top: 20px;
    padding: 15px 20px 20px 20px;
    background-color: $inverted-text-color;
    font-size: 1.15em;

    .payment-review-edit-row{
      position: relative;
      .edit-button{
        position: absolute;
        right: 0;
      }
    }

    .payment-review-bullets{
      margin-top: 15px;

      ul{
        padding-left: 20px;
        li{
          margin-bottom: 5px;
        }
      }
    }

    .payment-review-disclaimer{
      background-color: $background-color;
      font-size: 0.9em;
      border: 1px solid $fade-color;
      padding: 15px 20px;
      line-height: 120%;
      margin-top: 40px;
    }

    .payment-submit-button-container{
      text-align: center;
      margin-top: 35px;

      .secure-checkout-icon{
        margin-top: 15px;
        .fa-circle-thin{
          color: $border-color;
        }
      }

      .payment-submit-btn{
        background-color: #F6E111;
        font-weight: bold;
        width: 225px;
        font-size: 1.2em;
        border: 1px solid #E9AC1A;

        &:hover{
          background-color: #F8D30F;
        }
      }
    }
  }
}