search-results-sidebar{
  box-shadow: -3px 1px 3px darken($border-color, 20%);
}

.results-sidebar-container{
  background-color: $inverted-text-color;
  padding: 10px 0;
  height: 100%;
  position: relative;
  border-left: 2px solid $border-color;

  .listings-hidden-container{
    margin-top: 35px;
    text-align: center;
    font-size: 14px;

    .listings-hidden-row{
      margin-bottom: 15px;

      &.listings-hidden-image{
        background-image: url(/images/landing/landing-logo.png);
        background-position: center;
        background-size: cover;
        width: 115px;
        height: 93px;
        margin: auto;
      }
    }
  }

  .listing-container{
    background-color: $inverted-text-color;
    width: 100%;
    overflow-y: auto;
    position: absolute;
    top: 45px;
    bottom: 6px;
  }

  .pagination-container{
    .current-page-display{
      text-align: center;
      font-weight: bold;
    }

    .btn{
      width: 50%;
    }
  }

  .form-group{
    margin-left: 0;
    margin-right: 0;

    select{
      height: 30px;
    }

    label{
      font-weight: normal;
      padding: 0;
      margin-top: 6px;
    }
  }

  .result-count{
    margin-top: 7px;
    font-size: 12px;
  }
}