@import "../../core/styles/_colors.scss";

.connected-profiles-button.MuiButton-containedPrimary, 
.connected-profiles-button.MuiButton-containedPrimary:hover,
.connected-profiles-button.MuiButton-containedPrimary:active,
.connected-profiles-button.md-focused, 
.connected-profiles-button.Mui-focusVisible.Mui-focusVisible {
    background-color: rgba($egyptian-500, .08);
    border-radius: 16px;
    box-shadow: none;
    color: rgba($main-text-color, .87);
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    padding: 3px 4px;
    width: 75px;
}

.connected-profiles-button.MuiButton-containedPrimary:hover {
    background-color: rgba($egyptian-500, .2);
}

.connected-profiles-button.MuiButton-containedPrimary:active {
    opacity: .75;
}
