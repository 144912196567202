.saved-home-row{
  padding: 12px;
  margin-left: 0px;

  .saved-home-info{
    font-size: 1.1em;
    margin-left: 10px;
  }

  .saved-home-buttons{
    text-align: right;

    .btn-primary{
      vertical-align: middle;
      min-width: 90px;
    }
  }

  .saved-home-functions{
    margin-left: 10px;
  }

  .saved-home-image{
    max-width: 60px;
  }
}