.listing-application-status{
  max-width: 255px;
  margin: auto;
  color: $medium-text-color;
  margin-top: 15px;

  .renter-applied-display-header{
    font-weight: bold;
    font-size: 14px;
  }

  .renter-applied-resend-instructions{
    margin-top: 5px;
    font-weight: bold;
  }
}