.pending-appointment-modal{
  .appointment-container{
    margin: auto;
  }

  a{
    cursor: pointer;
  }

  .renter-prompt{
    text-align: center;
    > div {
      display: inline-block;
      vertical-align: middle;
    }

    .renter-image{
      background-size: cover;
      background-position: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .renter-prompt-message{
      margin-left: 10px;
    }
  }

  .listing-prompt{
    margin-top: 10px;
    text-align: center;
    > div {
      display: inline-block;
      vertical-align: middle;
    }

    .listing-image{
      width: 100px;
      height: 75px;
      border-radius: 6px;
      background-position: center;
      background-size: cover;
    }

    .listing-info{
      margin-right: 10px;
      text-align: left;

      .view-listing{
        margin-left: 5px;
      }
    }
  }
}