.calendar-container{
  $cell-width: 80px;
  $border-right-settings: 1px solid darken($border-color, 5%);
  $border-bottom-settings: 1px solid darken($border-color, 25%);
  $accepted-color: #449D44;
  $pending-color: #91CCC0;

  color: $medium-text-color;
  width: 100%;
  position: absolute;
  top: 50px;
  bottom: 0px;

  .data-rows{
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    position: absolute;
    top: 47px;
    bottom: 0px;
  }

  .calendar-row{
    border-bottom: $border-bottom-settings;
    width: 100%;
    float: left;
    white-space: nowrap;
    height: auto;
    background-color: $inverted-text-color;

    .cell{
      border-right: $border-right-settings;
      text-align: center;
      position: relative;
      display: inline-block;
      width: $cell-width;
      height: 29px;

      &.header-cell{
        border-right: none;
        font-weight: bold;
      }

      &.time-cell{

      }
    }
  }

  .calendar-event{
    color: $inverted-text-color;
    background-color: $highlight-color;
    width: $cell-width;
    position: absolute;
    text-align: left;
    left: 0;
    padding-left: 4px;
    white-space: pre-wrap;
    z-index: 100;
    font-size: 0.9em;

    &.pending{
      background-color: $pending-color;
    }
  }

  .modal {
    z-index: 101;
  }
}