div.meeting-details-wrapper {
	margin-top: 75px;
}

div#media {
	width: 800px; 
	padding: 10px; 
	margin: 10px; 
	border: solid;

	div#my-media-container {
		width: 530px;
		display: inline-block;	
		border: solid;
	}

	div#participant-media-container {
		width: 230px;
		display: inline-block;	
		border: solid;
	}

	video {
		width: 100%;
	}
}

div#video-controls {
	width: 300px; 
	padding: 10px; 
	margin: 10px; 
	border: solid;
}

input#participant-name {
	width: 100%;
}