.sidebar-appointment-container {
  width: 280px;
  margin-bottom: 10px;
  min-height: 105px;

  a.view-shown {
    color: $highlight-color;
  }

  hr {
    margin: 2px 0 2px 0;
  }

  .user-message {
    margin: 5px 0 0 5px;
    color: $dark-text-color;
  }

  .sidebar-appointment-header {
    .start-time-display {
      width: 60px;
      height: 60px;
      color: $inverted-text-color;
      line-height: 14px;
    }
  }

  .sidebar-user-interaction {
    margin-top: 5px;
    margin-left: 5px;

    .popover{
      width: 200px;
    }
  }

  .manual-appt-button,
  .propose-times-button,
  .cancel-button,
  .transfer-button {
    i {
      color: $medium-text-color;
      font-size: 14px;
    }
  }

  .request-reject-button {
    position: relative;
    top: 4px;
    right: 2px;
    font-size: 14px;
    color: $medium-text-color;
  }

  .sidebar-appointment-confirmed-icons {
    width: 45px;
    position: relative;
    left: 220px;
    bottom: 20px;
  }

  .sidebar-appointment-community-info,
  .sidebar-appointment-listing-info {
    margin: 5px;
    a {
      color: $dark-text-color;
      &.view-shown {
        color: $highlight-color;
      }
    }
  }

  .sidebar-appointment-community-info.confirmed,
  .sidebar-appointment-listing-info.confirmed {
    position: relative;
    bottom: 10px;
  }

  .sidebar-requested-appointment-times,
  .sidebar-proposed-appointment-times {
    margin-left: 5px;
    margin-bottom: 5px;

    .cancel-button {
      color: $dark-text-color;
    }

    .time-left-message,
    .cancel-button {
      font-size: 10px;
      margin-right: 5px;
      margin-top: 3px;
    }
  }

}
