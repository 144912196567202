.listing-basics-form{

  ul.dropdown-menu{
    max-height: 200px;
    overflow-y: auto;
  }

  .btn.dropdown-toggle{
    text-align: left;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 13px;
  }

  .state-dropdown, .unit-type-dropdown{
    &:hover{
      border-right: none;
    }
  }

  .listing-address-row{
    .ide-document-form-label{
      width: 150px;
    }

    .listing-street-address{
      width: 191px;
    }

    .listing-unit-number{
      width: 100px;
      input {
        width: 100px;
      }
    }
  }

  .listing-city-state-zip-row{
    .ide-document-form-label{
      width: 150px;
    }

    .listing-city{
      width: 190px;
    }

    .listing-state{
      width: 70px;
    }

    .listing-zip{
      width: 70px;
    }
  }

  .listing-beds-baths-row{
    padding-top: 4px;

    .beds-label{
      width: 150px;
    }

    .baths-label{
      width: 66px;
    }

    .listing-beds{
      width: 120px;
    }

    .listing-baths{
      width: 120px;
    }
  }

  .listing-property-type-square-feet-row{
    .property-type-label{
      width: 150px;
    }

    .square-feet-label{
      width: 66px;
    }

    .listing-property-type{
      width: 120px;
    }

    .listing-square-feet{
      width: 120px;
    }
  }

  .listing-pets-row{
    padding-top: 8px;

    .ide-document-form-label{
      width: 150px;
    }

    .listing-pets-checkboxes{
      width: 200px;
    }

    .ide-document-form-link{
      font-style: italic;
    }
  }

  .listing-description-row, .listing-pet-notes-row{
    .ide-document-form-label{
      padding-top: 8px;
      width: 150px;
    }

    .ide-document-form-column{
      vertical-align: top;

      .url-validation {
        color: $danger-color;
      }

      .video-url-input {
        width: 340px;
      }
    }

    textarea{
      width: 340px;
      height: 100px;
      border: none;
      resize: none;
      margin-top: 8px;
    }
  }

}
