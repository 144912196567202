.slideout-nav-container{
  $slideout-width: 310px;
  border-left: 2px solid $border-color;
  position: fixed;
  width: $slideout-width;
  top: 50px;
  bottom: 0;
  background-color: $inverted-text-color;
  overflow-y: auto;

  .slideout-nav-content{
    height: 600px;
  }

  .close-btn{
    position: absolute;
    right: 10px;
    font-size: 22px;
  }

  &.nav-right{
    right: -$slideout-width;

    @include transition(0.2s right);

    &.active{
      right: 0;

      @include transition(0.2s right);
    }
  }

  &.nav-left{
    left: -$slideout-width;

    @include transition(0.2s left);

    &.active{
      left: 0;

      @include transition(0.2s left);
    }
  }
}