.stats-module {
  .section-title {
    font-weight: bold;
    width: 120px;
  }

  .section-value {
    font-weight: bold;
  }

  .great {
    color: #2EB88F;
  }

  .okay {
    color: #FBB03B;
  }

  .bad {
    color: #F44336;
  }
}
