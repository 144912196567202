.lead-contact-form-container {
  text-align: left;
  max-width: 320px;
  padding-top: 20px;

  .scheduling-offered-by-container {
    margin-left: 0;
    margin-top: 0;
  }

  .md-subhead {
    margin-top: 20px;

    p {
      font-size: 16px;
      color: $medium-text-color;
      margin: 10px 0 0 0;
    }

    &.pending {
      color: $alternate-highlight-color;
    }
  }

  button.md-primary {
    margin-top: 20px;
  }

  .pending-display {
     color: $accent-color-bright;
  }

  .lead-create-response {
    margin-top: 20px;
    font-weight: bold;
  }
}
