.prime-times-modal-container{
  .time-selection-btn{
    margin: 5px;
    width: 90px;

    &.selected-time{
      cursor: default;
    }
  }

  p {
    margin-top: 10px;
    font-size: 14px;
  }

  .advanced-settings-container{
    margin-bottom: 10px;
  }

  .advanced-button{
    .fa {
      font-size: 24px;
      display: inline-block;
    }
  }

  .allow-other-requests-toggle{
    margin-bottom: 20px;
    > div{
      margin-top: 5px;

      .checkbox-label{
        padding: 5px;
        margin-right: 10px;
      }
    }
  }
  
  .prime-times-modal-header-content{
    border-bottom: 1px solid $border-color;
    margin-bottom: 20px;
  }

}