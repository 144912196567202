.payment-edit-card-container{
  padding: 75px 10px 20px 10px;
  max-width: 700px;
  margin: auto;

  .edit-card-form-container{
    background-color: $inverted-text-color;
    padding: 20px;
    border-radius: 6px;
  }

  .add-card-button-container{
    text-align: center;
    margin-top: 20px;
    .btn{
      width: 200px;
    }
  }
}