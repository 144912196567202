/* TODO: see about importing colors */

.duplicate-alert-container {
  padding: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.duplicate-alert-title {
  font-size: 16px;
  font-weight: 500;
}

.duplicate-alert-description {
  font-size: 12px;
}

.duplicate-alert-action {
  background: transparent;
  border: none;
  color: #2eb88f;
  text-transform: uppercase;

  & + & {
    margin-left: 20px;
  }
}

.duplicate-alert-action-container {
  text-align: right;
}
