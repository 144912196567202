.attachments-picker-container {
  .attachment-uploader-drop-field {
    width: 100%;
    height: 100px;
    background-color: $inverted-text-color;
    border: 1px solid $highlight-color;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: $background-color;
    }

    &.drag-over {
      border: 2px dashed $highlight-color;
    }

    .attachment-uploader-text {
      width: 100%;
      vertical-align: middle;
      display: inline-block;
      line-height: 100px;

      color: $medium-text-color;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .add-attachment-dialog-preview {
    padding: 0 0 10px;
  }

  .add-attachment-dialog-add-url {
    input {
      margin-right: 8px;
    }
    .md-button {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .add-attachment-dialog-preview {
    .md-button {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .add-attachment-dialog-preview-image {
    width: 75px;
    height: 50px;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 6px;
    text-align: center;
  }
}

.add-attachment-dialog-container {
  .dialog-content {
    padding-bottom: 7px;
  }
  .md-actions {
    padding-right: 16px;
    padding-bottom: 7px;
  }
}
