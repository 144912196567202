app-loading {
  z-index: $z-top;
}

.app-loading-content {
  position: fixed;
  z-index: $z-top;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  @include transition(opacity 1s);
  opacity: 1;

  &.fade {
    opacity: 0;

    @include transition(opacity 1s);
  }
}
