$collapse-width: 815px;

.non-collapsed-nav{
  @media screen and (max-width: $collapse-width){
    display: none;
  }
}

.collapsed-nav{
  display: none;

  @media screen and (max-width: $collapse-width){
    display: block;
  }
}

.navigation-hamburger-container{
  display: none;

  @media screen and (max-width: $collapse-width){
    display: block;
  }
}

.navigation.unauthenticated.no-margin{
  .hamburger-menu{
    position: relative;
    left: 12px;
  }
}

.hamburger-link-un{
  font-size: 16px;
}
