.properties-index-container {
  .community-section {
    margin-top: 10px;

    .fa-building {
      color: $highlight-color;
    }

    .community-image {
      width: 110px;
      height: 80px;
      margin-right: 20px;
      background: no-repeat;
      background-size: cover;
    }

    .location-warning {
      color: $danger-color
    }

    .md-subhead.community {
      margin-right: 10px;
      font-size: 25px;
    }
  }

  .floorplan-header {
    margin-top: 10px;
  }

  .floorplan-row {
    .post-button {
      margin-left: 5px;
    }
  }

  .floorplan-row, .inactive-listing-row {
    margin-top: 10px;
    margin-bottom: 10px;

    .external-feed-logo {
      margin-left: 15px;
    }

    .location-warning {
      color: $danger-color;
    }

    .md-subhead {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 250px;
      overflow: hidden;

      @media screen and (max-width: 600px) {
        width: 175px;
      }
    }
  }

  md-fab-actions {
    position: absolute;
    right: 0;
    z-index: -1;

    &.is-open {
      z-index: 10000;
    }

    md-content {
      width: 100%;
      text-align: center;
    }

    md-divider {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .md-fab-fa {
    position: relative;
    top: 6px;
    left: 1px;
  }

  .knock-form-header {
    text-align: left;
  }

  /* The !important is needed to override how the MD library applies CSS classes */
  .leasing-binder-button {
    background-color: #4A61E8 !important;
  }

  .property-action-icon {
    margin-bottom: 2px;
    margin-right: 5px;
  }
}