@import 'src/LegacyAngularApp/legacy-angular-app/core/styles/_colors.scss';

.view-connected-profiles-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 5px 0;

    .guestcard-icon {
        vertical-align: middle;
        margin-right: 8px;

     
        path {
            stroke: $slate-600;
        }
    }
}
