md-dialog.application-notification-modal {
  background-image: url('https://s3.amazonaws.com/stage-knock-webapp-images/background_tile_420x420.png');
  background-position-y: 50px;
  width: 600px;

  .md-toolbar-tools {
    background-color: transparent;
  }

  md-dialog-content {
    font-size: 16px;
    padding: 50px 60px;
  }

  .md-actions {
    background-color: transparent;

    div {
      background-color: transparent;
    }
  }

  .fa-info-circle {
    color: $alternate-highlight-color;
  }

  .fa-exclamation-circle {
    color: $bright-highlight-color;
  }

  .fa-exclamation-triangle {
    color: $danger-color;
  }

  .application-notification-messages {
    padding: 20px;
    border: 1px solid #E6E6E6;
    background-color: white;
    border-radius: 4px;
  }

  .application-notification-title {
    &.info {
      color: $alternate-highlight-color;
    }

    &.warning {
      color: $bright-highlight-color;
    }

    &.error {
      color: $danger-color;
    }
  }

  .refresh-btn {
    margin: 20px 0;
    width: 500px;
    height: 50px;
    font-size: 18px;
  }
}
