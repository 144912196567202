.scheduling-container-signup{
  .btn-social{
    width: 150px;

    &:hover, &:focus{
      color: #fff;
    }
  }

  .scheduling-container-signup-row{
    margin-top: 10px;
  }

  .flat-input.name-input{
    max-width: 123px;
  }

  .full-width-input, .date-selector-input{
    width: 250px;
    @media screen and (max-width: 326px){
      width: 100%;
    }
  }

  .social-row{
    margin-bottom: 10px;
  }

  .requirements-row{
    max-width: 326px;
    > div {
      display: inline-block;
      vertical-align: top;
    }
  }

  .requirements-row-icon{
    width: 34px;
    height: 34px;
    border-radius: 50%;
    line-height: 34px;
    color: $inverted-text-color;
    font-size: 20px;
    text-align: center;
    background-color: $inactive-color;
    @include transition(0.5s background-color);

    &.field-valid{
      background-color: $highlight-color;
      @include transition(0.5s background-color);
    }

    &.pet-description-icon{
      background-color: transparent;
    }
  }

  .requirements-rows{
    text-align: left;
    @media screen and (min-width: 326px) {
      margin-left: 32px;
    }
  }

  .requirements-row-title{
    margin-bottom: 5px;
    max-width: 275px;
  }

  .requirements-row-content{
    font-size: 14px;
    max-width: 290px;
    text-align: left;
    margin-left: 10px;
  }

  .requirement-radio-selection{
    margin-left: 10px;
    input{
      margin-right: 5px;
    }
  }

  .book-tour-button{
    margin-top: 15px;
    width: 150px;
  }

  .error-messages-row{
    margin-left: 32px;
    .error-messages-container {
      .knock-subheading{
        color: $danger-color;
      }
      text-align: left;
    }
  }
}