.listing-info-rows{
  margin-top: 40px;

  .listing-info-row{
    margin-bottom: 20px;
  }

  .listing-info-row-title{
    font-size: 18px;
    font-weight: bold;

    .fa-video-camera{
      color: $youtube-red-lightest;
    }

    .fa-align-left{
      color: #5cb8e6;
    }

    .fa-bell{
      color: #9D73C7;
    }

    .fa-paw{
      color: #8C6239;
    }

    .fa-car{
      color: #5BD4C0;
    }

    .fa-tint{
      color: #809AE5;
    }

    .fa-lightbulb-o{
      color: #EB982C;
    }

    .fa-check-circle-o{
      color: #69D169;
    }
  }

  .listing-info-row-content{
    color: $lighter-text-color;
    font-size: 13px;

    .application-note{
      font-size: 10px;
    }

    .video-url{
      color: $lighter-text-color;
    }

    button{
      width: 100%;
    }

    p {
      white-space: pre-line;
    }
  }
}
