.active-ads-index-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .active-ads-listings-container {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 60px;
    overflow-y: auto;
  }

  .active-ads-listing-container {
    padding: 0 20px 10px 20px;
  }

  .active-ads-pagination-container {
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 20px;
  }

  md-fab-actions {
    position: absolute;
    right: 0;
    z-index: -1;

    &.is-open {
      z-index: 10000;
    }

    md-content {
      width: 100%;
      text-align: center;
    }

    md-divider {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .md-fab-fa {
    position: relative;
    top: 5px;
    left: 1px;
  }

  .pagination > li.active > a {
    color: $inverted-text-color;
    background-color: $highlight-color;
    border-color: $highlight-color;
  }

  .pagination > li > a, .pagination > li > span {
    color: $highlight-color;
  }
}