.todo-list-container {
  margin: 0 20px;
  overflow-x: hidden;
  padding-bottom: 50px;

  .todo-list-header {
    text-align: center;
  }

  .knock-form-subheader {
    text-align: center;
    color: $lighter-text-color;
    margin-bottom: 20px;
  }

  .todo-list-items {
    margin: 20px auto;

    .todo-list-item {
      margin: 5px 0;
    }
  }

  .todo-list-items-header-label {
    font-size: 15px;
    width: 150px;
  }

  .todo-list-caught-up {
    text-align: center;

    .todo-list-caught-up-text {
      font-size: 20px;
    }

    .fa {
      color: $highlight-color;
    }

    a {
      font-size: 15px;
    }
  }
}
