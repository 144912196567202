.new-listing-modal{
  .modal-header{
    text-align: center;
    h4{
      color: $highlight-color;
    }
  }

  .modal-body{
    text-align: center;
    .btn{
      width: 275px;
      margin-bottom: 10px;
    }
  }
}