.manager-slideout-container{
  font-size: 13px;
  color: $medium-text-color;

  .manager-slideout-row{
    border-bottom: 1px solid $border-color;
  }

  .manager-slideout-row-header{
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 10px;

    .fa{
      margin-right: 4px;
    }

    .manager-switch-user-container{
       padding-top: 5px;
       padding-bottom: 10px;

       a.switch-user {
           font-size: 12px;
           font-weight: normal;
           margin-left: 5px;
       }
    }

    a.edit{
      font-size: 12px;
      font-weight: normal;
      margin-left: 20px;
    }

    a.darker-link{
      color: $medium-text-color;
    }
  }

  .manager-slideout-row-content{
    margin-top: 5px;
    margin-left: 15px;
    margin-bottom: 10px;

    > div{
      display: inline-block;
      vertical-align: middle;
    }
  }

  .manager-slideout-profile-image{
    width: 45px;
    height: 45px;
    margin-right: 5px;

    background-position: center;
    background-size: cover;
    border-radius: 50%;

    border: 1px solid $border-color;
  }

  .manager-slideout-links{
    margin-left: 30px;
    a{
      font-size: 13px;
    }

    .manager-slideout-link{
      margin-top: 1px;
    }
  }
}
