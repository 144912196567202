.notification-banner {
  color: $inverted-text-color;
  height: 55px;
  width: 100%;

  font-size: 14px;
  padding: 2px 15px;

  &.top-border {
    border-top: 1px solid $inverted-text-color;
  }

  .conversation-button {
    color: $inverted-text-color;
    background-color: transparent;
    border: 1px solid $inverted-text-color;
  }

  .close-button {
    border: none;
    min-width: 50px;
    color: $inverted-text-color;
    background-color: transparent;
  }

  .new-badge {
    background-color: $inverted-text-color;
    color: $accent-color-bright;
    border-radius: 6px;
    font-size: 20px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: bold;
    margin-right: 15px;

    &::after {
      content: 'NEW'
    }
  }

  .resident-badge {
    background-color: $inverted-text-color;
    color: $accent-color-bright;
    border-radius: 6px;
    font-size: 20px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: bold;
    margin-right: 15px;

    &::after {
      content: 'RESIDENT'
    }
  }

  .live-chat-badge {
    background-color: $inverted-text-color;
    color: $alternate-highlight-color;
    border-radius: 6px;
    font-size: 20px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: bold;
    margin-right: 15px;

    &::after {
      content: 'LIVE CHAT'
    }
  }

  .appointment-badge {
    background-color: $inverted-text-color;
    color: $alternate-highlight-color;
    border-radius: 6px;
    font-size: 20px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: bold;
    margin-right: 15px;

    &::after {
      content: 'BOOKED'
    }
  }

  .batch-message-badge {
    background-color: $inverted-text-color;
    color: $highlight-color;
    border-radius: 6px;
    font-size: 20px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: bold;
    margin-right: 15px;

    &::after {
      content: 'SENDING'
    }
  }

  .notification-type-icon {
    margin-right: 15px;
  }

  &.notification-banner-call {
    background-color: $accent-color;
  }

  &.notification-banner-chat {
    background-color: $alternate-highlight-color;
  }

  &.notification-banner-appointment {
    background-color: $alternate-highlight-color;
  }

  &.notification-banner-batch-message {
    background-color: $highlight-color;

    a {
      color: $inverted-text-color;
      text-decoration: underline;
    }
  }
}
