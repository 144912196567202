.resident-report-kanban-container {
    .kanban-header {
        .status-select {
            right: 8px;
        }
        .column-subheading {
            div {
                display: inline-block;
            }
        
            .resident-status-count {
                margin-right: 5px;
            }
        }
    }
}