.conversation-row-container{
  color: $medium-text-color;
  margin-left: 0;
  margin-right: 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  height: 70px;
  cursor: pointer;

  &.unread{
    background-color: $background-color;
  }

  &:hover{
    background-color: $background-color;
  }

  .them-info-container{
    padding: 10px;
    > div {
      display: inline-block;
      vertical-align: middle;
      padding: 5px;
    }
  }

  .from-time{
    color: darken($fade-color, 10%);
    position: absolute;
    right: 8px;
    bottom: 2px;
    font-size: 0.85em;
  }

  .user-image{
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;

    .unread-dot{
      width: 20px;
      height: 20px;
      position: relative;
      right: -32px;
      top: 10px;
      background: url('/images/unread_dot.png') center;
      background-size: cover;
    }
  }
  .user-info{
    .user-name{
      text-align: left;
      font-weight: bold;
      a {
        margin-left: 0;
      }
    }
  }

  .col-xs-4, .col-xs-8{
    padding: 0;
  }
}
