.star-rating-container{
 
  width: 100%;
  
  .star-rating-star{
    display: inline-block;
    vertical-align: middle;
    background-color: $fade-color;
    color: $inverted-text-color;
    font-size: 20px;
    width: 28px;
    height: 28px;
    text-align: center;
    margin-right: 10px;
    border-radius: 5px;

    cursor: pointer;

    &.highlighted{
      background-color: $highlight-color;
    }

    &.small{
      border-radius: 3px;
      font-size: 10px;
      width: 14px;
      height: 14px;
      text-align: center;
      margin-right: 2px;
    }
  }
}