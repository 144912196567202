.resident-follow-up-list {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
    background-color: $inverted-text-color;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 15px;
    margin: 10px 0;
    border-radius: 4px;
    min-width: 600px;
  
    &:hover {
      cursor: pointer;
    }
  
    .icon-text {
      min-width: 86px;
      color: $dark-text-color;
      margin-right: 15px;
      font-weight: bold;
    }
  
    .resident-list {
      font-weight: bold;
      color: $dark-text-color;
  
      @media screen and (max-width: 1220px) {
        max-width: 230px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  
    .resident-follow-up-list-nav {
      button.md-button {
        height: 28px;
        max-height: 28px;
        min-height: 28px;
        line-height: 24px;
        font-size: 11px;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  