.resident-report-kanban-container {
    .kanban-section {
        &:last-child {
            margin-bottom: 20px;
        }
        
        .kanban-section-inner {
            background-color: $trello-gray;
        }
    }
}