@import '../colors';

$email-overflow-menu-tier-1-width: 1275;
$email-overflow-menu-tier-2-width: 1175;
$email-overflow-menu-tier-3-width: 1075;
$sms-overflow-menu-tier-1-width: 1300;
$sms-overflow-menu-tier-2-width: 1175;
$sms-overflow-menu-tier-3-width: 1075;

:export {
  emailOverflowMenuTier1Width: $email-overflow-menu-tier-1-width;
  emailOverflowMenuTier2Width: $email-overflow-menu-tier-2-width;
  emailOverflowMenuTier3Width: $email-overflow-menu-tier-3-width;
  smsOverflowMenuTier1Width: $sms-overflow-menu-tier-1-width;
  smsOverflowMenuTier2Width: $sms-overflow-menu-tier-2-width;
  smsOverflowMenuTier3Width: $sms-overflow-menu-tier-3-width;
}
.conversation-text-area-container {
  background-color: $inverted-text-color;
  height: 100%;
  border-left: 1px solid $border-color;

  .fr-toolbar,
  .fr-wrapper {
    border: none !important;
  }

  .second-toolbar {
    border-top: none !important;
    border-left: none !important;
    border-right:  none !important;
    border-radius: 0 !important;
  }

  md-pagination-wrapper {
    max-height: 35px;
  }

  md-ink-bar {
    color: $alternate-highlight-color;
    background-color: $alternate-highlight-color;
  }

  .md-button {
    max-height: 30px;
    min-height: 30px;
    line-height: 30px;
    height: 30px;
    margin: 0 0 0 5px;
    font-size: 12px;
  }

  .conversation-text-area-tabs-overlay {
    position: absolute;
    top: 0;
    height: 45px;
    width: 250px;
    left: 0;
    pointer-events: none;

    .conversation-text-area-tab-tooltip {
      pointer-events: all;
    }

    .call-tab-overlay {
      left: 5px;
      width: 70px;
      height: 45px;
      position: absolute;
      top: 0;
    }

    .phone-tab-overlay{
      left: 75px;
      width: 70px;
      height: 45px;
      position: absolute;
    }

    .email-tab-overlay {
      position: absolute;
      left: 150px;
      height: 45px;
      width: 70px;
      top: 0;
    }

    .live-chat-tab-overlay {
      position: absolute;
      left: 225px;
      height: 45px;
      width: 80px;
      top: 0;
    }
  }

  #formatting-options {
    margin-left: 8px;
    .btn-group {
      margin-left: -4px;
      & > .btn {
        color: $medium-dark-text-color;
        cursor: pointer;
        &.active {
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.125);
        }
      }
    }
  }

  .conversation-text-area-tools {
    height: 45px;
    min-height: 45px;
    padding: 5px;
    border-bottom: 1px solid $border-color;

    & > button {
      min-width: 65px;
      margin-right: 5px;

      @media screen and (max-width: 1250px) {
        &.md-button {
          min-width: 0;
        }
      }

      @media screen and (min-width: 1175px) and (max-width: 1250px) {
        &.md-button {
          padding: 0 12px;
        }
        .fa-fw {
          display: none;
        }
      }

      @media screen and (max-width: 1174px) {
        &.md-button {
          padding: 0 8px;
        }
        span {
          display: none;
        }
      }
    }

    button.md-button.md-flat.md-button-round {
      background: $white;
      color: $black;
      padding: 0 5px !important;
      width: 20px;
      height: 20px;
      min-width: 20px;
      max-width: 20px;
      min-height: 20px;
      max-height: 20px;
      line-height: 20px;
      font-size: 10px;
      border: 1px solid $border-color;
      margin-right: 3px;
    }

    button.md-button.md-flat.md-button-round.mark-unread-button {
      margin-right: 5px;
    }
  }

  .conversation-text-area-body {
    border: 0;
    outline: none;
    box-shadow: none;
    overflow-y: visible;

    &.conversation-text-area-body-medium {
      max-height: 80px;
    }

    &.conversation-text-area-body-small {
      max-height: 60px;
    }

    .emojiPickerIconWrap {
      height: 100%;
    }

    .msg-textarea {
      width: 100%;
    }

    .placeholder-text {
      color: $placeholder-text-color;
    }

    textarea {
      width: 100%;
      height: 100%;
      border: none;
      padding: 10px;
      resize: none;
      font-size: 13px;

      &.drag-over {
        border: 1px dashed $highlight-color;
      }

      &::-webkit-input-placeholder {
        color: $placeholder-text-color;
      }
    }
  }

  .emoji-picker-button {
    display: inline-block;
    width: 22px;
    position: absolute;
    left: 10px;
    bottom: 10px;

    .fa {
      font-size: 18px;
    }
  }

  md-tabs {
    max-height: 35px;
    min-height: 35px;
  }

  .md-tab {
    padding: 2px 10px 0 10px;
    text-transform: none;
    font-size: 12px;

    &.md-active {
      color: $alternate-highlight-color;
    }

    .fa-mobile {
      position: relative;
      left: -2px;
    }
  }

  .conversation-text-area-sms-override {
    font-size: 11px;
    md-checkbox {
      margin: 0 0 0 10px;
    }
  }
}

.new-conversation-text-area-container {
  justify-content: space-between;
}

.conversation-text-area-footer {
  padding: 0 10px 10px 28px;
  font-size: 12px;
  min-height: 43px;

  .knock-alert.knock-alert-error {
    font-size: 12px;
  }

  .formatting {
    -khtml-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .md-button {
    min-width: 88px;
    margin-left: 10px;
  }

  .conversation-text-area-footer-characters {
    margin-left: 10px;
  }

  .new-conversation-text-area-footer-characters {
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: auto;
    margin-right: 10px;

    div {
      margin-left: 10px;
      padding-top: 4px;
    }

    .sms-character-count {
      margin-left: 10px;
    }
  }

  .conversation-text-area-footer-subject {
    margin-left: 20px;

    .fa-edit {
      position: relative;
      left: 1px;
    }

    input {
      width: 200px;
    }

    .conversation-text-area-footer-subject-preview {
      position: relative;
      display: inline-block;
      width: 200px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .conversation-text-area-footer-subject-label {
      margin-right: 5px;

      @media screen and (max-width: 1030px) {
        display: none;
      }
    }
  }

  .conversation-text-area-footer-icon {
    margin-left: 5px;
    cursor: pointer;
    .fa {
      font-size: 18px;
    }
  }

  .conversation-text-area-footer-icon.disable {
    opacity: 0.5;
    cursor: default;
  }

  .tooltip {
    right: 0;
    left: auto !important;

    .tooltip-arrow {
      left: auto !important;
      right: 20px !important;
    }

    .tooltip-inner {
      max-width: 300px;
    }
  }
  .md-primary{
    text-transform: capitalize;
    font-size: 14px;
    font-family: 'Open Sans';
    border-radius: 4px;
    min-height: 45px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .phone-call-button {
    color: $denim-500;
    border: 1px solid rgba(90, 114, 246, 0.5);
  }

  .md-primary.browser-call-button {
    background-color: rgba(105, 127, 247, 1);
    color: #fff;

    &:hover {
      background-color: rgba(105, 127, 247, 1);
    }
  }

  .browser-call-button.disable {
    background-color: #E2E3E9;
  }

  .phone-call-button.disable {
    color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(41, 43, 61, 0.08);
    md-icon svg path {
      stroke: rgb(203, 205, 216);
    }
  }

  .call-buttons {
    padding-right: 4px;
    padding-bottom: 4px;
  }

  .md-primary.mui-button {
    font-size: 14px;
    font-weight: 600;
    margin-left: 0;
    min-height: 36px;
    padding-left: 14px;
    padding-right: 14px;
  }

  .md-primary.phone-call-button-mui {
    background-color: white;
    border: 1px solid #4A61E8;
    color: #4A61E8;
    margin-right: 12px;

    &:hover {
      background-color: #EBEEFE;
    }

    md-icon {
      margin-right: 4px;
    }
  }

  .md-primary.browser-call-button-mui {
    background-color: #4A61E8;
    color: white;

    &:hover {
      background-color: #3649BA;
    }

    md-icon {
      height: 20px;
      margin-right: 4px;
      width: 20px;
    }
  }

  .md-primary.end-call-button-mui {
    background-color: #D21A32;
    color: white;

    &:hover {
      background-color: #B61327;
    }

    md-icon {
      margin-right: 4px;
    }
  }

  button.md-primary md-progress-circular {
    position: absolute;
    height: 15px;
    right: 108px;
  }

  .md-primary md-progress-circular.in-call-progress-bar {
    right: 76px;
  }
}

.new-conversation-text-area-footer {
  padding-left: 5px;

  @media screen and (max-width: $email-overflow-menu-tier-1-width + 0px) {
    .email-overflow-menu-tier-1 {
      display: none;
    }
  }

  @media screen and (max-width: $email-overflow-menu-tier-2-width + 0px) {
    .email-overflow-menu-tier-2 {
      display: none;
    }
  }

  @media screen and (max-width: $email-overflow-menu-tier-3-width + 0px) {
    .email-overflow-menu-tier-3 {
      display: none;
    }
  }

  @media screen and (max-width: $sms-overflow-menu-tier-1-width + 0px) {
    .sms-overflow-menu-tier-1 {
      display: none;
    }
  }

  @media screen and (max-width: $sms-overflow-menu-tier-2-width + 0px) {
    .sms-overflow-menu-tier-2 {
      display: none;
    }
  }

  @media screen and (max-width: $sms-overflow-menu-tier-3-width + 0px) {
    .sms-overflow-menu-tier-3 {
      display: none;
    }
  }
}

.conversation-text-area-footer-attachments {
  .attachment-wrapper {
    margin: 0 0 4px 4px;
  }
  .active-attachment {
    background-color: $background-color;
    padding: 5px 45px 5px 15px;
    border: 1px solid $border-color;
    border-radius: 1px;
    position: relative;
    min-width: 300px;
    display: inline-block;
    .disable-attachment {
      display: inline-block;
      padding: 2px;
      position: absolute;
      top: 3px;
      right: 3px;
      cursor: pointer;
    }
  }
}
