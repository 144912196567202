.upgrade-account-modal-container{
  .modal-header{
    text-align: center;

    > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .modal-body{
    padding: 15px;
    text-align: center;
  }
}
