.fixed-nav-panel-container {
  -webkit-transform: translate3d(0, 0, 0);

  position: absolute;
  left: 0;
  width: 150px;
  top: $global-nav-height;
  bottom: 0;

  background-color: #2EA683;

  z-index: 1004;

  color: $inverted-text-color;
  font-size: 13px;

  overflow-y: auto;
  overflow-x: hidden;

  &.offset-for-multiteam {
    left: 65px;
  }

  &.offset-for-new-nav {
    top: 58px;
  }


  .nav-top {
    background-color: $highlight-color;
    padding-bottom: 10px;

    .nav-section:nth-child(1) {
      padding: 10px 10px 0;
    }
  }

  .notifications {
    position: absolute;
    top: 15px;
    left: 15px;
    &.audio {
      top: 50px;
    }
    button {
      color: white;
      line-height: 20px;
      height: 20px;
      min-height: 20px;
      font-size: 20px;
      box-sizing: border-box;
      padding: 0;
      min-width: auto;
      margin: 0;
      width: 24px;
      &.disabled {
        opacity: .75;
      }
      &.unavailable {
        opacity: .5;
      }
    }
  }

  .user-preferences-menu {
    width: 150px;
    height: 32px;

    .nav-link-label {
      color: #D6F1E9;

      span {
        margin-right: 5px;
      }

      i {
        font-size: 20px;
        font-weight: bold;
      }

      &:hover {
        background-color: #71CCB1;
        color: $inverted-text-color;
        cursor: pointer;

        span {
          text-decoration: underline;
        }
      }
    }
  }

  .profile-text {
    margin-top: 5px;
    font-size: 13px;
    font-weight: bold;
    color: $inverted-text-color;
    text-decoration: none;

    .profile-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .user-image.small-user-image {
    width: 50px;
    height: 50px;

    min-width: 50px;
    min-height: 50px;

    margin: 0;
  }

  .header-section {
    padding-top: 0;
    padding-left: 15px;
  }

  .nav-separator {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
  }

  .nav-section {
    margin-bottom: 10px;

    &.nav-section-buttons {
      padding-left: 15px;
      margin-bottom: 15px;

      > div {
        width: 50%;
        margin-right: 15px;
      }

      .md-button {
        line-height: 30px;
        height: 30px;
        min-height: 30px;
        box-sizing: border-box;
        padding: 0;
        min-width: 100%;
        margin: 0;
      }
    }
  }

  .nav-section-header {
    font-size: 12px;
    font-weight: bold;
    padding-left: 15px;

    &.nav-section-header-link {
      font-weight: bold;
      height: 28px;
      width: 150px;

      a {
        position: relative;
        top: 3px;
        color: #fff;
        vertical-align: middle;
        height: 28px;

        &:active, &:focus {
          text-decoration: none;
        }
      }

      &.selected {
        background-color: #2EA683;

        a {
          color: $inverted-text-color;
        }
      }

      &:hover {
        background-color: #71CCB1;
      }
    }
  }

  .nav-link {
    font-size: 13px;
    font-weight: bold;
    padding-left: 32px;
    height: 28px;
    width: 150px;

    a {
      color: #D6F1E9;

      &:active, &:focus {
        text-decoration: none;
      }
    }

    &.selected {
      background-color: #2EA683;

      a {
        color: $inverted-text-color;
      }
    }

    &:hover {
      background-color: #71CCB1;

      a {
        color: $inverted-text-color;
      }
    }

    &.messages {
      margin-bottom: 3px;
      height: 27px;

      .nav-link-label {
        font-weight: bold;
      }
    }

    .google {
      color: $youtube-red-light;
    }
  }

  .nav-link-dropdown {
    margin-right: 5px;
  }

  .nav-bottom {
    background-color: #2EA683;
    padding-top: 10px;

    .nav-link.selected {
      background-color: $highlight-color;
    }
  }
}

.nav-link-badge {
  background-color: $accent-color-bright;
  width: 30px;
  height: 20px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  margin-right: 15px;
  color: $inverted-text-color;
  min-width: 30px;

  &.success {
    background-color: $dark-highlight-color;
  }
}

.viewport-content {
  position: absolute;
  right: 0;
  left: 0;
  &.offset-for-nav {
    top: $global-nav-height;
  }

  &.offset-for-new-nav {
    top: 58px;
  }

  &.offset-for-sidenav {
    left: 150px;
  }

  &.offset-for-sidenav-and-multiteam {
    left: 215px;
  }

  &.offset-for-globalnav-and-multiteam {
    max-height: calc(100% - #{$global-nav-height});
    left: 150px;
  }

  &.offset-new-sidenav{
    left: 240px;
    top: 65px;
  }
  &.offset-new-sidenav-drawer{
    left:0;
    top:58
  }
}

@media print {
  .viewport-content {
    position: unset;
    right: unset;
    left: unset;
  }
}

$small-preferences-row-height: 32px;

.small-preferences {
  md-icon {
    height: 16px;
  }

  md-menu-item {
    min-height: $small-preferences-row-height;
    height: $small-preferences-row-height;
    max-height: $small-preferences-row-height;
  }

  md-menu-item > .md-button {
    font-size: 12px;
    height: $small-preferences-row-height;
    line-height: $small-preferences-row-height;
    max-height: $small-preferences-row-height;
    min-height: $small-preferences-row-height;
  }
}

.user-preferences-menu-content, .small-preferences {

  .md-button:not([disabled]).md-focused {
    background-color: white;
  }

  .fa-power-off {
    position: relative;
    top: 5px;
  }

  .nav-link-label {
    i {
      font-size: 14px;
    }

    &.account-settings {
      a {
        color: $medium-text-color;
      }
    }
  }

  .md-button:not([disabled]).md-focused:hover {
    background-color: rgba(158, 158, 158, 0.2);
  }
}
