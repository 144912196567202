.prospect-send-message-modal-container {
  .fa {
    font-size: 18px;
  }
  .textarea-wrapper {
    textarea.chat-textarea {
      background-color: $background-color;
      border-radius: 6px;
      width: 100% !important;
      padding: 10px;
      margin-top: 26px;
    }

    .emojiPickerIconWrap {
      width: 100%;
    }

    .emoji-picker-button {
      left: 10px;
      bottom: -36px;
      position: absolute;
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .char-count {
    &.is-over-max {
      color: $danger-color;
    }
  }

  share-listing-menu,
  quick-replies, .conversation-text-area-footer-icon {
    margin-left: 5px;
    &:hover {
      color: $highlight-color;
      cursor: pointer;
    }
  }

  .send-message-icons {
    padding-left: 25px;
  }

  .cant-send-alert {
    font-size: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
  }

  md-input-container {
    padding-bottom: 0;
  }

  .attachments-picker-container {
    margin-top: 20px;
  }
}
