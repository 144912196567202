.community-mailer-preview-container {
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .manager-photo{
    img {
      border-radius: 50%;
      width:50px;
      height:50px;
    }
  }

  .community-image {
    img {
      width:300px
    }
  }

  .community-name {
    font-size: 18px;
  }

  .available-units {
    margin-top: 15px;
    text-decoration: underline;
  }
}

