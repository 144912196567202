.booked-appointment-display-container{
  hr{
    margin-top: 10px;
    margin-bottom: 0;
  }

  .appointment-functions{
    margin-top: 5px;
    > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .booked-appointment-login{
    margin-top: 10px;
    font-size: 16px;
  }
}