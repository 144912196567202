.visitor-history-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .visitor-history-tables {
    overflow-y: auto;

    position: absolute;
    top: 75px;
    bottom: 0;
    left: 0;
    right: 0;

    td {
      width: 110px;

      &.checkbox-td {
        width: 45px;
      }

      &.indicator-td {
        width: 60px;
      }
    }
  }
}
