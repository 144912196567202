.prospect-export-status-container {

  .appfolio {

    .tooltip-inner {
      max-width: 400px;
    }

    .appfolio-title {
        margin-right: 5px
    }

    .md-button.md-icon-button.small {
        line-height: 22px;
        margin: 0;
        max-height: 20px;
        min-height: 20px;
        width: 20px;
    }

    .truncated {
        display:inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        width: 110px;
    }
  }
}
