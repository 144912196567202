.message-editor-container {

  .char-count {
    padding: 10px 0 10px 150px;
    &.is-over-max {
      color: $danger-color;
    }
  }

  .conversation-input-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: $inverted-text-color;

    .conversation-tools {
      height: 40px;

      md-select {
        position: relative;
        top: -5px;
      }

      md-select .md-select-value {
        border: none;
      }

      border-bottom: 1px solid $border-color;

      button {
        position: relative;
        left: 10px;
      }

      .share-listing-menu,
      .canned-responses,
      .attachment-selector {
        font-size: 11px;

        &:hover {
          color: $highlight-color;
          cursor: pointer;
        }
      }

      .canned-responses {
        position: absolute;
        left: 180px;
      }

      .share-listing-menu {
        position: absolute;
        left: 200px;
      }

      .attachment-selector {
        position: absolute;
        left: 240px;
      }

      .share-listing-menu {
        padding-left: 12px;
      }
    }
  }

  .conversation-textarea-wrapper {
    textarea {
      padding: 10px;
      width: 100%;
      height: 95px;
      resize: none;
      border: none;
      font-size: 14px;
      margin-top: 15px;
      border-radius: 6px;
      border: 1px solid $border-color;
    }

    .emoji-picker-button {
      left: 10px;
      top: -30px;
      position: absolute;
      &:hover {
        color: $highlight-color;
      }
    }
  }
}
