.price-dropdown-content{
  text-align: center;
  padding: 5px;

  input.form-control{
    width: 60px;
    margin-top: 0 !important;

    &:first-child{
      float: left;
    }
  }

  .price-to-label{
    text-align: center;
    padding: 0 5px 0 5px;
    float: left;
    margin: 5px 0 0 2px;
  }
}

.room-count-selector{
  .btn-link{
    padding-top: 0;
  }
}

.neighborhood-dropdown{
  max-height: 400px;
  overflow-y: scroll;
}

.neighborhood-dropdown, .more-filters-dropdown{
  color: #777;

  label{
    padding-left: 10px;
    margin-bottom: 2px;
  }
  div.checkbox{
    padding: 0 2px 0 20px;
  }
  input[type="checkbox"]{
    margin-right: 3px;
  }
}

.auto-suggest-form{
  margin-right: 10px;
}

.auto-suggest{
  top: -9px;
  position: relative;

  .auto-suggest-results{
    position: absolute;
    padding: 4px;
    border: 1px solid $fade-color;
    background: $inverted-text-color;
    min-width: 240px;
    left: 14px;
  }

  .auto-suggest-result{
    padding: 1px;
    &:hover{
      background-color: $highlight-color;
      color: $inverted-text-color;
    }
  }

  input.search{
    padding-left: 25px;
    min-width: 180px;
    background-color: #F2F2F2;
    border: none;
    box-shadow: none;

    &:hover, &:active, &:focus{
      border: none;
    }
  }
}

.header .search-filter-dropdown{
  height: 100%;
  .search{
    max-height: 500px;
  }
}

form.search{
  background-color: $inverted-text-color;

  button{
    color: $medium-text-color;
    font-weight: bold;
  }

  > div{
    display: inline-table;
    vertical-align: middle;
  }

  @media screen and (max-width: 991px){
    > div{
      display: block;
    }
  }

  .form-control, .dropdown-toggle{
    margin-top: 4px;
  }

  .dropdown-toggle{
    border-radius: 0;
    border: none;
    height: 35px;
    font-size: 13px;
  }

  .auto-suggest i.fa-search {
    position: relative;
    top: 24px;
    left: 8px;
    color: $fade-color;
  }

  div.form-group{
    margin-left: 0;
  }

  button.btn-search{
    &.btn-search-lg{
      width: 100%;
      margin-top: 5px;
    }
    &.btn-search-sm{
      width: 30px;
      height: 24px;
      padding: 0 0 6px 0;
    }
    color: $inverted-text-color;
    position: relative;
    top: -5px;
    background-color: $highlight-color;
    font-size: 15px;
    border-radius: 3px;
  }
}