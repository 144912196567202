.new-appointment-modal-container{
  .modal-header{
    text-align: center;

    h4{
      color: $highlight-color;
    }
  }

  h2{
    margin: auto;
  }

  .flat-input{
    width: 100%;
  }

  .manual-appointment-instructions{
    margin: 0 auto 20px auto;
    text-align: center;
    font-weight: bold;
    color: $medium-text-color;
  }

  .modal-footer-buttons{
    max-width: 425px;
    margin: auto;
  }

  .modal-footer-message {
    textarea {
      width: 100%;
      margin: auto;
    }
  }

  .manual-appointment-error-messages{
    max-width: 425px;
    margin: 8px auto;
  }

  .manual-appointment-row{
    > div {
      display: inline-block;
      vertical-align: middle;
    }

    max-width: 425px;
    margin: 8px auto;
    height: 35px;

    position: relative;

    .manual-appointment-row-label{
      width: 100px;
      font-weight: bold;
      font-size: 14px;
      color: $medium-text-color;
    }

    .manual-appointment-row-input{
      position: absolute;
      left: 95px;
      right: 20px;

      input, select{
        width: 100%;
        height: 35px;
        line-height: 35px;

        &.invalid {
          border: 1px solid $danger-color;
        }
      }
    }
  }
}