md-dialog.manager-auth-modal-container {
  max-width: 415px;
  max-height: 100%;

  md-dialog-content {
    padding: 20px 35px;
  }

  .sign-in-button-container {
    text-align: center;

    button {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .forgot-password {
    margin-bottom: 20px;
  }
}