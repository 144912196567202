.leasing-team-navbar {
  position: absolute;
  background-color: #3B8671;
  width: 65px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1004;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-margin-left: 5px;

  .nav-section {
    margin-bottom: 10px;

    &.nav-section-buttons {
      padding: 0;

      > div {
        width: 50%;
        margin-right: 28px;
      }

      .md-button {
        line-height: 40px;
        height: 40px;
        min-height: 40px;
        box-sizing: border-box;
        background-color: #2F6C5B;
        border-radius: 10px ;
        color: $inverted-text-color;
        padding: 0;
        margin-top: 30px;
        width: 40px;
        min-width: 40px;
        font-weight: bold;
        position: relative;
        overflow: visible;

        .nav-team-link-badge{
          position: absolute;
          top: -10px;
          right: -15px;

          color: $inverted-text-color;
          background-color: $accent-color-bright;
          width: auto;
          min-width: 25px;
          height: 20px;
          border-radius: 8px;
          text-align: center;
          font-size: 12px;
          line-height: 18px;
          margin-right: 5px;
        }
      }

      .transparent-bordered {
        background-color: #3B8671;
        border: 1px solid white;
      }

      .md-button[disabled] {
        border: 2px solid white;
      }

      .md-button:not([disabled]):hover {
        border: 2px solid #2EB88F;
      }
    }
  }
}


