.old-visitor-history-container{
  padding: 20px 20px 50px 20px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .visitor-history-wrapper {
    max-width: 1400px;
    margin: auto;
  }

  .visitor-history-filters{
    text-align: left;
    padding-left: 40px;

    md-input-container {
      padding-bottom: 0;
    }

    h3 {
      margin-right: 20px;
    }
  }

  .visitor-history-no-results{
    text-align: center;
    position: absolute;
    font-size: 18px;
    width: 100%;
    z-index: 0;
    top: 100px;
  }

  .fa-building{
    color: $icon-color;
  }

  .datepicker-button, .dropdown-selector-container > button{
    font-size: 14px;
    font-weight: bold;
    background-color: $inverted-text-color;
    border: 1px solid $border-color;
    width: 150px;
    height: 26px;
    text-align: left;

    &:focus{
      outline: none;
    }

    &:active{
      border: 1px solid $dark-highlight-color;
    }

    &:hover{
      background-color: $border-color;
    }
  }

  .dropdown-selector-container > button{
    padding: 2px 5px 2px 5px;
  }

  .visitor-history-rows{
    //margin-top: 20px;
    z-index: 1;
    position: relative;
  }

  .visitor-history-row{
    background-color: $inverted-text-color;
    min-height: 100px;
    margin-top: 20px;

    .visitor-history-row-content{
      td {
        text-align: left;
      }

      .no-visitors{
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }

  .visitor-history{
    text-align: left;

    .history-name{
      font-weight: bold;
    }
  }

  .leads-tab {
    text-align: left;
  }

  .add-lead {
    position: relative;
    top: 10px;
  }
}

.visitor-history-row-header{
  text-align: left;
  padding: 10px;
  font-size: 14px;

  > div {
    display: inline-block;
    vertical-align: middle;
  }

  .row-header-image{
    background-position: center;
    background-size: cover;
    width: 75px;
    height: 50px;
    margin-right: 10px;
  }

  .row-header-info-name{
    font-weight: bold;
  }
}