.renter-card{
  margin-bottom: 10px;

  > div {
    display: inline-block;
    vertical-align: middle;
  }

  .profile-component{
    display: inline-block;
  }

  .profile-image{
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }

  .renter-info{
    vertical-align: middle;
    margin-left: 15px;
  }
}
