.confirmation-modal-container{
  .modal-header{
    border-bottom: none;
    padding-bottom: 5px;
  }

  .modal-body{
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .alert{
    border: none;
    padding: 5px;
  }
}