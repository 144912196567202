.resident-todo-card {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 5px;
  background-color: $inverted-text-color;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 15px;
  margin: 10px 0;
  border-radius: 4px;
  min-width: 600px;

  &:hover {
    cursor: pointer;
  }

  .icon-text {
    min-width: 86px;
    color: $dark-text-color;
    margin-right: 15px;
    font-weight: bold;
  }

  .resident-list {
    font-weight: bold;
    color: $dark-text-color;
    flex: 1;
    padding-right: 10px;

    @media screen and (max-width: 1220px) {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .resident-todo-card-nav {
    button.md-button {
      height: 28px;
      max-height: 28px;
      min-height: 28px;
      line-height: 24px;
      font-size: 11px;
      margin-right: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .resident-todo-unit {
    width: 60px;
    height: 20px;
    border-radius: 8px;
    text-align: left;
    font-size: 12px;
    line-height: 22px;
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }

  .resident-todo-date {
    height: 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    padding-left: 5px;
    margin-right: 150px;
    font-weight: bold;
  }
}
