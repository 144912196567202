.resident-history-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .resident-history-tables {
    overflow-y: auto;

    position: absolute;
    top: 75px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
