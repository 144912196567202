.leasing-team-sms-consent-container {
  text-align: center;
  font-size: 16px;

  .community-name {
    color: $dark-text-color;
  }

  .sms-consent-message {
    margin: 0 auto 20px auto;
    padding-left: 20px;
    padding-right: 20px;
    color: #000;
    max-width: 400px;

    input {
      width: 100%;
      max-width: 360px;
      height: 48px;
      border-radius: 6px;
      border: 2px solid $border-color;
      background-color: $inverted-text-color;
    }

    @media screen and (max-width: 600px) {
      margin: 0 auto 10px auto;
    }
  }

  .sms-consent-header {
    background-color: $alternate-highlight-color;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      color: $inverted-text-color;
      margin-top: 0;
    }

    height: 90px;

    @media screen and (max-width: 600px) {
      height: 70px;
    }
  }

  .sms-consent-icon {
    margin-top: 20px;
    margin-bottom: 20px;

    img {
      width: 90px;
    }

    @media screen and (max-width: 600px) {
      img {
        width: 60px;
      }

      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .sms-consent-grant-button {
    width: 100%;
    max-width: 400px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto 20px auto;

    button.md-primary-alt {
      width: 100%;
      height: 48px;
      margin: 0;
      border-radius: 6px;

      &[disabled] {
        background-color: darken($background-color, 10%);
      }
    }
  }

  .sms-consent-footer {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 400px;
    margin: auto;
    font-size: 11px;
    display: flex;
    text-align: left;
    color: #000;

    md-checkbox {
      margin: 0;
    }
  }

  .sms-consent-no-thanks {
    margin-top: 20px;
    font-size: 12px;
    color: #000;

    &:hover {
      cursor: pointer;
      color: $text-color;
    }
  }
}

.community-sms-consent-modal-container {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  z-index: $z-top;

  md-dialog-content {
    padding: 0;
  }
}
