.integration {
    .service {
        padding: 10px;
    }

    img {
        height: 105px;
        width: 105px;
    }
}

