.header {
  z-index: 1003;
  background-color: $inverted-text-color;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  &.has-border{
    border-bottom: 1px solid $border-color;
  }

  .navbar-search-link{
    position: absolute;
    top: 15px;
    left: 100px;

    a{
      font-size: 14px;
      color: $medium-text-color;
    }
  }

  .header-text-link{
    position: relative;
    top: -4px;
    font-size: 1.1em;
    margin-right: 5px;
  }

  .user-dropdown{
    padding: 10px 10px 6px 6px;
    border: none;
    box-shadow: none;
    font-size: 1.1em;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    top: 6px;
    margin-left: 2px;
    &:hover{
      text-decoration: none;
      color: $highlight-color;
    }
  }

  .user-icon{
    position: relative;
    top: -5px;
    width: 28px;
    height: 28px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    background-position: center;
    background-size: contain;
  }

  .message-icon-container{
    position: relative;

    .alert-badge{
      top: -10px;
      right: 0;
    }
  }

  notifications-dropdown{
    position: relative;

    .alert-badge{
      top: -10px;
      right: -6px;
    }
  }

  .alert-badge{
    position: absolute;
    right: -2px;
    top: -4px;
    background-color: $icon-color;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    color: $inverted-text-color;
    text-align: center;
    z-index: 1000;

    .alert-badge-text{
      margin: auto;
      font-weight: bold;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      line-height: 15px;
      text-align: center;
    }
  }

  .search-bar-container{
    .search-dropdown-toggle{
      margin-left: 15px;
      margin-top: 12px;
      font-size: 13px;
      font-weight: bold;

      border: none;
      box-shadow: none;
      color: $dark-text-color;
    }
  }

  .collapsed-search-bar{
    form.search{
      padding: 5px;
    }
    .form-group{
      margin-bottom: 0;
    }
    button.dropdown-toggle{
      border: none;
      margin: 0;
    }
    .auto-suggest{
      top: -15px;
    }
  }

  .search{
    max-height: 50px;
    padding-left: 25px;
    padding-right: 10px;
    border-bottom: 1px solid $border-color;
  }

  .right-nav{
    float: right;
    height: 50px;

    .navigation{
      display: inline-block;
    }

    .navigation{
      position: relative;
      top: 14px;

      &.no-margin {
        top: 0;
      }

      &.unauthenticated{
        padding-right: 15px;
      }

      .fa-list{
        position: relative;
        top: 2px;
      }

      a{
        margin-left: 8px;

        &:hover, &.active{
          text-decoration: none;
          color: $highlight-color;
        }

        &.guest-link{
          margin-left: 0;
          font-size: 1.2em;
          color: $lighter-text-color;
          font-weight: 600;

          &:hover{
            color: $medium-text-color;
            text-decoration: underline;
          }
        }

        &.watch-demo-link{
          margin-right: 25px;
          color: $highlight-color;
          &:hover {
            color: $dark-highlight-color;
            text-decoration: underline;
          }
        }
      }

      .guest-link-divider{
        margin-left: 0;
        font-size: 1.2em;
        color: $lighter-text-color;
        font-weight: 600;
      }
    }

    a.dropdown-toggle{
      border: none;
      box-shadow: none;
      font-size: 1.1em;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      position: relative;
      top: 6px;
      margin-left: 2px;
      &:hover{
        text-decoration: none;
        color: $highlight-color;
      }
    }

    ul.dropdown-menu{
      z-index: 1003;
      top: 40px;
      a {
        color: $medium-text-color;

        &:hover{
          background-color: transparent;
          color: $highlight-color;
        }
      }
    }

    li.divider{
      margin: 5px 0 5px 0;
    }
  }

  .logo{
    position: relative;
    bottom: 6px;
    background: url('/images/logo_nav.png') no-repeat;
    background-size: contain;
    width: 190px;
    height: 37px;
    margin-left: 10px;
    margin-top: 12px;
    margin-right: 20px;
    float: left;
  }

  .external-logo{
    height: 35px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .logo-tagline {
    white-space: nowrap;
    position: absolute;
    bottom: 5px;
    color: $text-color;
    left: 80px;
    font-size: 9px;
  }
}

.links-not-logged-in-container{
  a {
    margin: 0 10px 0 10px;
    color: $dark-text-color;
    font-size: 14px;

    &:hover, &:focus {
      color: $highlight-color;
      text-decoration: none;
    }
  }

  a.ng-click-active {
    &:hover, &:focus {
      color: $highlight-color;
      text-decoration: none;
    }
  }
}

.link-stack-container.hamburger {
  width: 320px;
  font-size: 20px;

  .link-stack-row {
    padding: 8px;
  }
}
