@import '../../core/styles/_colors.scss';

.batch-select-checkbox {
    position: relative;
    padding-left: 4px;
    margin-left: -4px;

    &:hover {
        background-color: $action-active;
    }

    .MuiCheckbox-colorSecondary.MuiCheckbox-indeterminate,
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $denim-500;
    }

    .batch-select {
        position: absolute;
        z-index: 1;
        left: 14px;
        top: 40px;
    }
}
