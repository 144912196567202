md-dialog.view-review-dialog-container{
  width: 350px;

  .review-radio-label {
    font-weight: bold;
  }

  .review-radios {
    font-size: 14px;
  }

  .review-radio {
    text-align: center;

    .fa {
      font-size: 24px;
    }

    .fa-dot-circle-o {
      color: $highlight-color;
    }

    .fa-circle-o {
      color: $border-color;
    }
  }

  .review-radio-row {
    margin-bottom: 10px;
  }

  .review-radio-headers {
    margin-bottom: 10px;
    text-align: center;
  }

  hr {
    border-width: 3px;
  }

  .review-toured-with, .review-prospect {
    padding: 5px;

    .small-user-image {
      margin-right: 10px;
    }

    star-rating {
      margin-top: 5px;
    }
  }
}