.prospect-appointment-modal-container {
  .warn {
    color: $danger-color;
  }

  .duplicate-header {
    font-size: 14px;
    font-weight: bold;
    color: $highlight-color;
    margin-bottom: 15px;
  }

  .duplicate-section {
    margin: 5px;
    padding-top: 5px;
  }

  .small-btn {
    min-width: 50px;
    font-size: 12px;
    font-weight: bold;
  }

  .use-btn {
    background-color: $alternate-highlight-color;
    color: whitesmoke;
  }

  .duplicate-name {
    font-weight: bold;
  }

}