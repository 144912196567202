.renewals-module {
  .renewal-lease-end {
    font-weight: bold;
    font-size: 12px;
  }

  .renewal-resident-name {
    font-size: 12px;
    font-weight: bold;
  }
}
