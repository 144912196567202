.appointment-card-container {
  border-radius: 6px;
  background-color: rgba($highlight-color, 0.05);
  border: 1px solid rgba($highlight-color, 0.1);

  width: 280px;
  margin-bottom: 10px;

  .appointment-time {
    color: $highlight-color;
  }

  .appointment-function {
    padding: 2px;
    font-size: 16px;
  }
}
