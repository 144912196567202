.marketing-tools-responder-container {
  .responder-row {
    font-size: 14px;

    .responder-container {
      margin-bottom: 40px;
      margin-left: 20px;

      .editing-forwarding-email {
        width: 300px;
        position: relative;
        left: 20px;
      }

      .responder-address {
        color: $highlight-color;
      }

      .save-forward-email-button {
         position: relative;
         left: 65px;
         bottom: 20px;
      }

      .greeting-container {
        width: 350px;

        upload-recording {
          position: relative;
          bottom: 2px;
          margin-left: 5px;

          &:hover {
            color: $highlight-color;
          }
        }

        a {
          padding-right: 5px;
          text-decoration: none;

          span {
            color: $medium-text-color;
          }
        }

        label {
          cursor: pointer;
        }
      }
    }

    .forward-to,
    .knock-voice-source{
      padding-left: 18px;
      margin-bottom: 10px;
    }

    .edit-template-container {
      width: 95%;
      padding: 15px 0 10px 18px;

      md-button {
        min-width: 10px;
        width: 45px;
      }

      .target {
        display: none;

        .save-button {
          margin-top: 20px;

          &.preferences {
            position: relative;
            bottom: 80px;
            left: 20px;
          }
        }
      }

      .signature-form {
        width: 300px;

        p {
          position: relative;
          top: 10px;
        }
      }

      .duration-dropdown {
        width: 300px;
      }

      .expand-toggle {
        cursor: pointer;

        h5 {
          color: $highlight-color;
          margin: 0;
        }

        i {
          font-size: 18px;
          position: relative;
          top: 2px;
        }
      }

      .sub-header {
        margin-top: 30px;
      }

      .preview-link,
      .character-counter {
        font-size: 10px;
        color: $lighter-text-color;
        float: right;
      }

      .preview-link {
        color: $highlight-color;
        margin-right: 5px;
        cursor: pointer;
      }

      .static-text-container,
      .radio-button-container {
        border: $border-color 1px solid;
      }

      .static-text-container {
        background-color: #F2F2F2;
        color: $lighter-text-color;
        font-size: 14px;

        .static-text {
          margin: 10px;
          line-height: 23px;

          .static-variable {
            border-radius: 7px;
            background-color: $fade-color;
            padding: 2px 4px 2px 4px;
          }
        }
      }

      textarea {
        overflow: auto;
        color: $lighter-text-color;
        font-size: 14px;
        resize: none;
        padding: 10px;
        width:100%;;
        border: $border-color 1px solid;
        font-family: sans-serif;
        @include transition(all 0.25s ease-in-out);

        &:focus {
          color: $lighter-text-color;
          outline: none;
          border: $highlight-color 1px solid;
          font-family: sans-serif;
          box-shadow: 0 0 5px $highlight-color;
        }
      }

      .update-template-button {
        position: relative;
        top: 20px;
      }

      &.preferences {
        height: 50px;
        padding-left: 0;
        padding-top: 0;
      }
    }
  }
}

.relay-phone-dialog-container {
  .short-row {
    width: 200px;
  }

  .md-button {
    margin: 10px 0 0 0;
  }

  .phone-number-results-container {
    h3 {
      text-align: center;
    }
  }

  .phone-number-results {
    font-size: 14px;
  }
}
