.four-oh-four-container{
  background-size: cover;
  height: 100%;

  margin: 0 auto;
  text-align: center;

  h2{
    color: $inverted-text-color;
  }

  .four-oh-four-text{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 15px;

    height: 130px;

    margin: auto;

    background-color: rgba(0, 0, 0, 0.5);
  }
}
