.kanban-card-content {
    position: relative;
    cursor: pointer;

    .kanban-select {
        md-checkbox {
            cursor: default;
        }
    }

    &:hover {
        background-color: $background-color;
    }
    
    &:before {
        content: '';
        width: 3px;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }
    &.current {
        &:before {
            background-color: $purple-current;
        }
    }
    &.undecided {
        &:before {
            background-color: $alternate-highlight-color;;
        }
    }
    &.renewing {
        &:before {
            background-color: $highlight-color;
        }
    }
    &.onnotice {
        &:before {
            background-color: $danger-color;
        }
    }
}

.kanban-card-details {
    p {
        font-size: 13px;
        margin: 0;
    }
}

.kanban-select {
    text-align: right;
    md-checkbox {
        display: inline-block;
        padding-left: 0;
        margin: 0;
    }
}