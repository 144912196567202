div.info-window{
  .info-pane{
    font-size: 11px;
    .info-price{
      font-size: 14px;
    }
  }

  .image-pane{
    position: relative;
    width: 320px;
    height: 250px;

    img{
      width: 320px;
      max-height: 250px;
      position: absolute;
      bottom: 0;
    }
  }
  .image-controls{
    padding: 1px;
    background-color: rgba(10, 10, 10, 0.5);
    color: $inverted-text-color;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;

    a{
      cursor: pointer;
      color: $inverted-text-color;
      font-size: 1.8em;
      i{
        position: relative;
        top: -8px;
      }
    }
    .count{
      padding-left: 4px;
      padding-right: 4px;
      position: relative;
      top: -10px;
    }
  }
}
