.kanban-card-content {
    position: relative;
    
    &:before {
        content: '';
        width: 3px;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }
    &.red {
        &:before {
            background-color: $danger-color;
        }
    }
    &.green {
        &:before {
            background-color: $highlight-color;
        }
    }
    &.yellow {
        &:before {
            background-color: $subtle-highlight-color;
        }
    }
    &.blue {
        &:before {
            background-color: $leased-blue;
        }
    }
}

.kanban-card-details {
    p {
        font-size: 13px;
        margin: 0;
    }
}

.kanban-select {
    text-align: right;
    md-checkbox {
        display: inline-block;
        padding-left: 0;
        margin: 0;
    }
}