.prospect-history-view-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  md-checkbox {
    margin: 0;
  }

  md-tabs {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    div[md-tabs-template] {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.icon-button.icon-button {
  &.download {
    background-color: $accent-color-dark;

    &:hover {
      background-color: darken($accent-color-dark, 5%);
    }
  }

  &.edit {
    background-color: #D4145A;

    &:hover {
      background-color: darken(#D4145A, 5%);
    }
  }

  &.mobile {
    background-color: #D4145A;

    &:hover {
      background-color: darken(#D4145A, 5%);
    }
    i {
      font-size: 1.5em;
      vertical-align: middle;
    }
  }
}

.md-select-menu-container, .visitor-history-table-container, .lead-history-table-container {

  .community-name {
    padding-right: 6px;
  }

  .fa-building, .fa-bullseye, .fa-home {
    color: $highlight-color;
    padding-right: 4px;
  }

  .fa-dot-circle-o {
    &.active {
      color: $highlight-color;
    }

    &.pending {
      color: $bright-highlight-color;
    }

    &.inactive {
      color: $danger-color;
    }
  }

  .sub-header-container {
    background-color: $highlight-color;
    color: $inverted-text-color;
    font-size: 12px;

    .fa-building, .fa-bullseye, .fa-home {
      color: $inverted-text-color;
    }

    &.inactive {
      background-color: $lighter-text-color;
    }

    &.pending {
      background-color: $bright-highlight-color;
    }

    .sub-header {
      padding: 8px 0px 8px 12px;
    }
  }

  .no-results-container {
    text-align: center;
    font-size: 11px;
  }
}

@import "visitorHistory";
@import "leadHistory";
@import "residentHistory";
@import "prospectSendMessage";
