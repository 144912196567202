$main-text-color: #000;
$inverted-text-color: invert($main-text-color);
$dark-text-color: #2c2d30;
$medium-dark-text-color: #444;
$medium-text-color: #666;
$lighter-text-color: #808080;
$placeholder-text-color: #D0D2D3;
$light-highlight-color: #70B7AF;
$alternate-highlight-color: #3FA9F5;
$highlight-color: #2EB88F;
$dark-highlight-color: #2E988D;
$lighter-highlight-color: #23bf9c;
$icon-color: #E9314D;
$fade-color: #ccc;
$background-color: #F4F8F9;
$inactive-color: #B3B3B3;
$border-color: #e6e6e6;
$darker-border-color: #c3c3c3;
$danger-color: #F44336;
$danger-color-soft: #FF7BAC;
$close-button-color: #B3B3B3;
$bright-highlight-color: #FBB03B;
$subtle-highlight-color: #FFCC66;
$success-color: #91C07D;
$dark-button-color: #808080;
$primary-text-color: #5B6269;
$accent-color: #93278F;
$accent-color-bright: #ED1E79;
$accent-color-dark: #662D91;
$landing-page-font-color: #333333;
$very-light-gray: #F4F4F4;
$leased-blue:#45AAF2;
$white: #FFFFFF;
$black: #000000;
$trello-gray: #e2e4e6;
$trello-gray-hover: #cbcdcf;
$purple-current: #652D90;

/* Colors for non-Knock logos/brands */
$yelp-red: #bc212b;
$youtube-red-lightest: #CE1F21;
$youtube-red-light: #e52d27;
$youtube-red-dark: #b31217;
$twitter-blue: #50ABF1;
$linkedin-blue: #007bb6;
$facebook-blue: #3B5998;

$action-active: rgba(90, 114, 246, 0.08);
$action-disabled: #BDBDBD;
$action-focus: rgba(0, 0, 0, .12);

$denim-50: #F5F6FF;
$denim-100: #EBEEFE;
$denim-200: #D6DCFF;
$denim-300: #BDC7FE;
$denim-400: #97A6FC;
$denim-500: #697FF7;
$denim-600: #4A61E8;
$denim-700: #3649BA;
$denim-800: #263273;
$denim-900: #19204C;

$egyptian-50: #f0f2ff;
$egyptian-100: #dbe1ff;
$egyptian-200: #b8c2ff;
$egyptian-300: #95a5fe;
$egyptian-400: #788cfc;
$egyptian-500: #5a72f6;
$egyptian-600: #435cef;
$egyptian-700: #2e46d3;
$egyptian-800: #2237b2;
$egyptian-900: #1c2d94;

$slate-50: #f9f9f9;
$slate-100: #f1f1f4;
$slate-200: #e0e1e6;
$slate-300: #c6c8d2;
$slate-400: #a9acbc;
$slate-500: #8d90a5;
$slate-600: #70758f;
$slate-700: #585c74;
$slate-800: #404459;
$slate-900: #2b2d3b;

$tangerine-50: #fff4f0;
$tangerine-200: #ffe3d7;
$tangerine-400: #ff7a3a;
$tangerine-500: #ff5804;

$sky-50: #f0f9ff;
$sky-200: #dbf0ff;
$sky-400: 0a93f5;
$sky-500: #0882da;

$grasshopper-50: #e8fcf2;
$grasshopper-200: #cef7e2;
$grasshopper-400: #0daa57;
$grasshopper-500: #0da554;

$text-secondary: rgba(0,0,0, 0.64);

$violet-50: #f4f0ff;
$violet-200: #ece5ff;
$violet-400: #9d7efc;
$violet-500: #906ef7;

$watermelon-50: #fff0f6;
$watermelon-200: #ffe0ee;
$watermelon-400: #ff529d;
$watermelon-500: #ff338b;