.conversation-inbox-row-container {
  border-top: 1px solid $border-color;

  &.is-open {
    background-color: darken($background-color, 5%);
  }

  overflow: hidden;
  padding-left: 10px;

  cursor: pointer;

  background-color: $very-light-gray;

  @include transition(background-color 0.1s);

  &:hover {
    @include transition(background-color 0.1s);
    background-color: $background-color;
  }

  &:active {
    @include transition(background-color 0.1s);
    background-color: darken($background-color, 5%);
  }

  .conversation-inbox-row-checkbox-container {
    md-checkbox {
      margin-right: 0;
      margin-left: 0;

      .md-icon {
        transform: scale(0.8);
      }
    }
  }

  .conversation-info {
    position: relative;
    padding: 5px 0;
  }

  .unread-dot-container {
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 13px;
    left: 25px;

    .fa {
      color: $highlight-color;
    }
  }

  .listing-address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
    color: $medium-text-color;
  }

  .last-message-field {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
    text-align: left;
    min-width: 100px;
  }

  .message-details {
    text-align: right;
    min-width: 80px;
    max-width: 120px;
  }

  .last-message-time {
    font-weight: bold;
    margin: 0 20px 0 0;
    min-width: 75px;
  }

  .user-name {
    min-width: 150px;
    max-width: 269px;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name-field {
    font-size: 13px;
    padding: 0 10px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline;
    min-width: 150px;
    max-width: 264px;
  }

  .user-status {
    font-size: 10px;
    min-width: 80px;
    max-width: 100px;
    i {
      margin-right: 4px;
    }
  }

  .listing-column {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .listing-label {
      padding-left: 4px;
      background-color: rgba(0, 0, 0, 0.4);
      height: 20px;
      overflow: hidden;
    }
  }

  .user-image {
    margin-right: 4px;
  }

  &.unread {
    background-color: $white;
    &:hover {
      @include transition(background-color 0.1s);
      background-color: $background-color;
    }
    .name-field {
      font-weight: bold;
    }
  }
}
