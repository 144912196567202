@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'), url(https://s3.amazonaws.com/prod-knockphotos/webapp/Montserrat-Light.otf) format('otf');
}

$global-nav-height: 74px;

$footer-height: 34px;
$font-family: 'Montserrat', sans-serif;

$z-top: 2147483000;

.browsehappy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: $danger-color-soft;
  color: $main-text-color;
  padding: 15px;
  z-index: $z-top;

  a {
    color: $inverted-text-color;
  }
}
/*

~`~ Notes on z-index ~`~

* Slideouts are on top (1001)
* Navbar is next (999)
* Everything else < 999

*/

.clay-frame {
  min-width: 640px;
  min-height: 480px;

  @media screen and (max-width: 640px) {
    min-width: 100vw;
    max-width: 100vw;
  }
}

.viewport-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  -webkit-overflow-scrolling: touch;

  @include transition(top 0.5s);
}

@media print {
  .viewport-container {
    position: unset;
    top: unset;
    left: unset;
    right: unset;
    -webkit-overflow-scrolling: unset;
    bottom: unset;
  }
}

html, body {
  background-image: none;
  width: 100%;
  height: 100%;
  font-family: $font-family;
  font-size: 12px;
  background-color: $inverted-text-color;
  cursor: default;
  color: $medium-text-color;

  input, textarea {
    font-family: $font-family;
  }
}

p {
  font-size: 14px;
}

body .emojiPicker {
  position: fixed;
  bottom: 0;
  right: 0;
  top: auto;
  background: white;
}

#loading-bar .bar {
  background-color: $highlight-color;
}

#loading-bar .peg {
  -moz-box-shadow: $highlight-color 1px 0 6px 1px;
  -ms-box-shadow: $highlight-color 1px 0 6px 1px;
  -webkit-box-shadow: $highlight-color 1px 0 6px 1px;
  box-shadow: $highlight-color 1px 0 6px 1px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.sp-replacer {
  border: none;
  background-color: $inverted-text-color;
}

:focus {
  outline: none;
}

hr{
  border-color: $border-color;
}

.knock-large-heading{
  font-size: 22px;
  color: $dark-text-color;
}

.knock-heading{
  font-size: 18px;
  color: $dark-text-color;
}

.knock-subheading{
  font-size: 16px;
  color: $medium-text-color;
}

.knock-strong{
  color: $dark-text-color;
}

md-dialog.knock-form {
  border-radius: 0;
}

.knock-form {
  .knock-form-header {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: $text-color;
    width: 100%;
    font-weight: bold;

    &.knock-form-header-fixed {
      height: 60px;
      background-color: $inverted-text-color;
      border-bottom: 1px solid $border-color;

      button {
        height: 38px;
        min-height: 38px;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }
    }
  }

  .knock-form-content {
    &.knock-form-content-fixed {
      padding-bottom: 20px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 59px;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .knock-form-subheader {
    font-size: 14px;
    color: $highlight-color;

    &.danger {
      color: $danger-color;
    }
  }

  .knock-form-section-header {
    font-size: 12px;
    text-transform: uppercase;
    color: #45AAF2;
    margin-bottom: 10px;
    padding: 15px 15px 0 15px;
    border-top: 1px solid $border-color;

    &.striped {
      font-size: 18px;
      color: $highlight-color;
      width: 100%;
      padding: 10px 20px;
      text-align: left;
      background-color: $background-color;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      text-transform: none;
      margin-bottom: 0;

      &.striped-short {
        font-size: 14px;
        padding: 5px 15px;
      }
    }
  }

  md-progress-circular {
    margin: auto;
  }
}

.knock-form-small-text {
    font-size: 10px;
}

.knock-form-input-container {
  margin-bottom: 10px;
  box-sizing: border-box;

  .knock-form-input-label {
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  input {
    width: 100%;
    box-sizing: border-box;
  }

  select {
    width: 100%;
    height: 33px;
  }

  &[flex='50'] {
    margin-right: 5px;

    input, select {
      width: calc(100% - 5px)
    }
  }
}

a{
  color: $highlight-color;
  text-decoration: none;

  &:focus {
    outline: 0;
  }

  &:hover{
    color: $dark-highlight-color;
    text-decoration: underline;
  }

  &:active{
    position: relative;
    top: 1px;
  }

  &.danger-link{
    color: $danger-color;

    &:hover{
      color: $danger-color-soft;
    }
  }

  &.dark-link{
    color: $medium-text-color;
    text-decoration: underline;

    &:hover{
      color: $dark-text-color;
    }
  }

  &.darker-link{
    color: $dark-text-color;
    text-decoration: none;
    font-weight: bold;

    &:hover{
      text-decoration: underline;
    }
  }

  &.link-underlined {
    text-decoration: underline;
  }
}

.popover{
  max-width: none;
}

.alert{
  background-color: $inverted-text-color;
  border: 1px solid $border-color;
  &.alert-success{
    color: $highlight-color;
  }
  &.alert-info{
    color: $medium-text-color;
  }
  &.alert-warning{
    color: $bright-highlight-color;
  }
  &.alert-danger{
    color: $danger-color;
  }
}

.white-background-fill{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $inverted-text-color;
  z-index: 0;
}

.close-btn{
  color: $close-button-color;

  &:hover{
    color: $medium-text-color;
  }
}

.btn-link{
  color: $highlight-color;

  &:hover{
    color: $dark-highlight-color;
  }
}

.btn-dark{
  font-weight: bold;
  border: none;
  color: $inverted-text-color;
  background-color: $dark-button-color;

  &:hover, &:active, &:focus{
    color: $inverted-text-color;
    border: none;
    background-color: darken($dark-button-color, 10%);
  }
}

.btn-primary{
  font-weight: bold;
  border: none;
  color: $inverted-text-color;
  background-color: $highlight-color;
  &:hover, &:active, &:focus{
    border: none;
    background-color: $dark-highlight-color;
  }

  &.btn-alt {
    background-color: $alternate-highlight-color;

    &:hover, &:active, &:focus{
      border: none;
      background-color: darken($alternate-highlight-color, 10%);
    }
  }
}

.btn-success{
  background-color: $inverted-text-color;
  border: 1px solid $highlight-color;
  color: $highlight-color;

  &:hover, &:active, &:focus{
    color: $highlight-color;
    background-color: $background-color;
    border: 1px solid $dark-highlight-color;
  }
}

.btn-warning{
  background-color: $inverted-text-color;
  border: 1px solid $border-color;
  color: $danger-color;
  &:hover, &:active, &:focus{
    color: $danger-color;
    background-color: $inverted-text-color;
    border: 1px solid $danger-color-soft;
  }
}

.btn-primary, .btn-success, .btn-warning, .btn-dark{
  -webkit-font-smoothing: antialiased;
}

.btn-social {
  border: 0;
  &:hover {
    color: $inverted-text-color;
  }
}

.btn-social-icon{
  color: $inverted-text-color;
  &:hover{
    color: $inverted-text-color;
  }
}

.btn-yelp{
  background-color: $yelp-red;
  &:hover{
    background-color: darken($yelp-red, 7%);
  }
}

.btn-youtube{
  background-color: $youtube-red-light;
  &:hover{
    background-color: $youtube-red-dark;
  }
}

.form-control{
  color: $medium-text-color;
  ::-webkit-input-placeholder {
    color: $placeholder-text-color;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: $placeholder-text-color;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: $placeholder-text-color;
  }

  :-ms-input-placeholder {
    color: $placeholder-text-color;
  }
}

.flat-input{
  border: none;
  border-radius: 6px;
  background-color: $border-color;
  padding: 8px;
  outline: none;

  &:focus{
    box-shadow: inset 0 0 0 2px $highlight-color;
  }
}

.full-width-input { width: 100%; };

.search-input-container {
  position: relative;

  .fa-search {
    position: absolute;
    top: 11px;
    left: 5px;
    color: $placeholder-text-color;
  }

  input {
    padding-left: 22px;
    width: 100%;
  }
}

input:not([type="checkbox"]).form-control, .dropdown-toggle{
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 12px;

  &:hover, &:active, &:focus{
    border: 1px solid #ccc;
  }
}

ul.dropdown-menu.ng-valid-date-disabled {
  z-index: $z-top;
}

.knock-alert{
  font-size: 14px;
}

.knock-alert-error{
  color: $danger-color;
}

.knock-input-error {
  box-shadow: inset 0 0 0 1px $danger-color;

  &:focus{
    box-shadow: inset 0 0 0 2px $danger-color;
  }
}

.page-loading-container{
  padding-top: 60px;
  text-align: center;
  h3{
    margin-bottom: 0;
  }
  .loading-bar-container{
    background-color: transparent;
    margin: auto;
  }
}

.slider.slider-horizontal{
  .slider-track{
    background-image: linear-gradient(rgb(179, 179, 179) 0%, rgb(175, 175, 175) 100%);
    height: 7px;
  }
  .slider-selection{
    background-image: linear-gradient(#2E8985 0%, #2E988D 100%);
  }
  .slider-handle{
    margin-top: -5px;
    width: 17px;
    height: 17px;
    box-shadow: none;
    background-color: $highlight-color;
    opacity: 1;
    background-image: none;
  }
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4{
  font-weight: 400;
  line-height: 130%;
  margin-top: 10px;
}

h1, .h1 {
  font-size: 29px;
  color: $dark-text-color;
}

h2, .h2 {
  font-size: 24px;
  color: $dark-text-color;
}

h3, .h3 {
  font-size: 18px;
  color: $medium-text-color;
}

h4, .h4 {
  font-size: 16px;
  color: $medium-text-color;
}

h5, .h5 {
  font-size: 14px;
  color: $medium-text-color;
}

.hide-when-loading{
  &.show{
    opacity: 1;
    @include transition(1s);
  }
  &.transparent{
    opacity: 0;
  }
}

.container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.page-container, div[ng-view]{
  height: calc(100% - #{$global-nav-height});
}

div[ng-view] {
  overflow-y: auto;
}

.col-no-padding{
  padding: 0;
}


.modal-dialog {
  @media screen and (max-width: 400px){
    margin: 0;
  }
}

.modal-sm{
  @media screen and (min-width: 400px){
    width: 400px;
  }

  @media screen and (max-width: 400px){
    width: 100%;
    min-width: 300px;
  }
}

.modal-content{
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0;
  border: 1px solid $border-color;
}

a.navbar-brand{
  padding-right: 0;
}

a.accordion-toggle{
  cursor: pointer;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: $highlight-color;
}

ul.nav-pills.rounded-edges{
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  li {
    margin: 0;
    background: $inverted-text-color;
    display: inline-block;
    float: none;
    text-align: center;
    width: 85px;
    height: 30px;

    &.active{
      a {
        background-color: $highlight-color;
        color: $inverted-text-color;
      }
    }

    a{
      background: $inverted-text-color;
      font-size: 14px;
      margin-left: -4px;
      padding: 4px;
      border-radius: 0;
      height: 30px;
      border: $highlight-color 1px solid;
      color: $highlight-color;

      &.left {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-right: none;
      }
      &.right {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
        border-left: none;
      }
    }
  }
}

.help-hover {
  font-size: 18px;
  vertical-align: middle;
  margin: 10px;
}

.date-picker :hover {
  cursor: pointer;
}

.main-content {
  padding: 60px 20px 50px 20px;
}

.ta-scroll-window > .ta-bind {
  padding: 10px;
  font-size: 13px;
  p {
    font-size: 13px;
  }
}

.ta-resizer-handle-overlay {
  display: none !important;
}

.ta-editor.ta-html, .ta-scroll-window.form-control, .ta-root.focussed > .ta-scroll-window.form-control, .ta-scroll-window > .ta-bind {
  border: 0;
  min-height: 80px;
  border: 0;
  outline: none;
  box-shadow: none;
  height: auto;
  font-size: 13px;
  p, ul, ol, blockquote {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Ensure tooltips appear for modal controls
md-tooltip {
  z-index: 15001;
}

.tt-multiline {
  .md-content {
    height: auto;
    line-height: auto;
    font-size: 12px;
    padding: 16px;
    max-width: 350px;
    white-space: normal;
    p, ol {
      font-size: 12px;
      margin-bottom: 10px;
    }
    ol {
      margin-left: 12px;
      padding: 0;
    }
  }
}

.new-emoji-picker-button {
  .MuiButton-textPrimary {
    color: #353950;
  }

  svg {
    margin-right: 8px;

    path {
      stroke: #353950;
    }
  };

  button {
    :focus {
      color: #353950;
    }
  }
}

