.branded-navbar-container {
  position: fixed;
  height: 60px;
  background-color: $inverted-text-color;
  top: 0;
  left: 0;
  right: 0;

  z-index: 1003;

  border-bottom: 1px solid $border-color;

  .branded-navbar-logo {
    padding: 15px;

    img {
      height: 50px;
    }

    &.manager-photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      margin: 0 10px;
    }
  }

  .company-name {
    font-size: 18px;
    color: $dark-text-color;
  }

  @media screen and (max-width: 500px) {
    .company-name {
      font-size: 14px;
    }
  }
}
