.user-interactions-panel-container {
  overflow-x: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: $inverted-text-color;

  .back-to-inbox {
    @media screen and (min-device-width: 600px) {
      display: none;
    }
  }

  .user-interactions-header {
    background-color: $highlight-color;
    border-bottom: 1px solid $border-color;
    height: 60px;
    padding: 0 5px;

    .todo-icon-container {
      margin-left: 24px;

      .todo-icon {
        box-sizing: content-box;
      }
    }

    lead-functions {
      margin-left: 3px;
    }

    .header-select {
      position: relative;
      bottom: 15px;
      margin-left: 5px;

      .md-select-value {
        border: none;
      }
    }

    .user-info-container {
      margin-left: 15px;
      height: 100%;
    }

    .phone-call-button {
      .fa-volume-control-phone{
        color: $accent-color;

        &.is-active {
          color: $inverted-text-color;
        }
      }
    }

    .function-buttons {
      margin-right: 10px;
    }

    .other-user-info {
      color: $inverted-text-color;
      font-size: 13px;
      font-weight: bold;

      a {
        font-weight: normal;
        color: $inverted-text-color;
      }

      .header-container {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .activity-buttons {
      margin-left: 5px;

      .change-status-menu {
        padding: 0;

        button {
          margin: 0 0 0 5px;
          font-size: 13px;
        }

        .menu-header {
          color: $highlight-color;
          font-size: 13px;
        }
      }

      .add-reminder-menu {
        padding: 0;

        button {
          margin: 0 0 0 5px;
          font-size: 13px;
          color: $inverted-text-color;
          background-color: $bright-highlight-color;
        }
      }

      .add-tour-button {
        button {
          margin: 0 0 0 5px;
          font-size: 13px;
          color: $inverted-text-color;
          background-color: $alternate-highlight-color;
        }
      }
    }
  }

  md-tab-item {
    max-width: none !important;
  }

  md-tabs {
    height: 100%;

    md-content, div[md-tabs-template] {
      height: 100%;
      position: relative;
    }
  }

  .user-interactions-tabs {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;

    &.is-renter,
    &.is-team-message{
      top: 60px;
    }

    @media screen and (max-device-width: 600px) {
      top: 100px;
    }
  }
}
