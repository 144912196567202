.small-listing-preview-container{
  position: relative;
  height: 100%;
  width: 100%;
  top: -1px;
  cursor: pointer;

  .listing-image{
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 70px;
  }
  .listing-info{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: $inverted-text-color;
    font-size: 0.85em;
    padding: 1px 2px 1px 2px;
    -webkit-font-smoothing: antialiased;
  }

  .listing-image-open-icon {
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: right;
    color: $inverted-text-color;
    padding-right: 2px;
  }
}