.leasing-team-view-container {
  height: 100%;

  &.renter-view{
    .header-container {
      .filter-container {

      }
    }
  }

  .header-container {
    height: 145px;
  }

  .profile-section {
    height: 100%;
    position: relative;
    bottom: 5px;
  }

  .angular-google-map {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .social-icons {
    margin-top: 5px;
  }

  .md-toolbar-tools {
    h2 {
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .md-subhead {
      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  .listing-count {
    height: 25px;
    width: 40px;
    border-radius: 20%;
    margin-top: 10px;
    margin-left: 15px;
    position: absolute;
    color: white;
    z-index: 10;
    text-align: center;
    line-height: 25px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .filter-container {
    width: 100%;
    max-width: 900px;
    margin: auto;

    .map-filters {
      margin: auto;
      padding: 5px;
      height: 80px;
      background-color: $inverted-text-color;

      md-input-container {
        padding-bottom: 13px;
      }
    }
  }

  .map-container {
    position: relative;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .view-toggle-buttons {
    .md-button {
      min-width: 30px;
      margin-left: 0;
      margin-right: 0;
    }

    .map-view {
      &.on-map-view {
        i {
          color: $highlight-color;
        }
      }
    }

    .list-view {
      &.on-list-view {
        i {
          color: $highlight-color;
        }
      }
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .header-toolbar {
    margin-top: 5px;

    img {
      margin-left: 10px;
      margin-right: 20px;
      height: 60px;
    }

    .company-name {
      font-size: 20px;
    }
  }

  .listings-view-container {
    height: 100%;

    .listings-container {
      overflow-y: auto;
      overflow-x: hidden;

      hr {
        width: 100%;
        color: $border-color;
        margin: 5px;
      }

      .listing-container {
        position: relative;

        hr {
          max-width: 670px;
        }

        &:hover {
          .listing-count {
            background-color: $highlight-color;
          }
        }

        .listing-result {
          $tag-color: #ECC335;
          $schedule-now-color: $highlight-color;
          $open-house-color: #6284CF;

          .listing {
            cursor: pointer;
            margin-left: 10px;
            width: 300px;
            min-height: 176px;
            background-size: cover;
            background-color: $fade-color;
          }

          .tag-button {
            cursor: pointer;
            position: absolute;
            top: 5px;
            left: 5px;

            .fa-certificate {
              color: $tag-color;
              font-size: 2.5em;
            }
            .fa-tag {
              color: #fff;
              font-size: 1.5em;
              margin-top: 6px;
              margin-left: 1px;
            }
          }

          .listing-info {
            font-size: 0.9em;
            padding: 2px 10px 2px 10px;
            position: absolute;
            bottom: 10px;
            width: 300px;
            background: rgba(0, 0, 0, 0.4);
            color: $inverted-text-color;

            .info-price {
              font-size: 1.6em;
              font-weight: 600;
            }

            .info-status-tag {

              padding: 3px 4px 3px 4px;
              border-radius: 3px;
              margin-top: 1px;

              &.schedule-now {
                background: $schedule-now-color;

                &.pending {
                  background: $bright-highlight-color;
                }
              }

              &.open-house {
                background: $open-house-color;
              }
            }
          }
        }

        .listing-info-right {
          margin-left: 10px;
          color: $dark-text-color;
          width: 350px;

          button {
            width: 130px;
          }

          .monthly-price {
            font-size: 18px;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
              color: $highlight-color;
            }
          }

          .pending-button {
            button {
              background-color: $bright-highlight-color;
              width: 140px;

              &:hover {
                background-color: $bright-highlight-color;
              }
            }
          }

          @media screen and (max-width: 600px) {
            width: 300px;
          }
        }

      }

      @media screen and (max-width: 600px) {
        margin-top: 10px;
      }
    }

    .scheduling-manager-container {
      height: 55px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .see-listing-view-container {
    width: 100%;
  }

  .angular-google-map-container {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
