.post-floorplan-modal{
  margin: auto;

  .modal-header{
    text-align: center;

    h3{
      color: $highlight-color;
    }
  }

  .modal-footer{
    text-align: center;

    button{
      width: 160px;
    }
  }

  .modal-body {
    .form-group{
      margin-bottom: 10px;

      .url-validation {
        color: $danger-color;
      }
    }

    .flat-input{
      width: 200px;
    }

    .alert{
      text-align: center;
      max-width: 500px;
      margin: 10px auto 10px auto;
    }

    .col-sm-4{
      text-align: left;
      font-weight: normal;
    }

    .lease-months-group{
      margin-top: 10px;
    }

    .lease-months-other-container{
      margin-top: 10px;
    }

    .application-instructions-group{
      margin-top: 10px;
      textarea{
        height: 100px;
      }
    }
  }
}

