.choose-times-schedule-container{
  text-align: center;

  h5{
    font-weight: bold;
  }

  .timezone-message{
    font-style: italic;
  }

  .time-selection-btn{
    padding: 0;
    margin: 5px;
    width: 80px;
    height: 35px;
    text-align: center;
    opacity: 1;
    @include transition(0.2s);

    &.not-selected{
      opacity: 0.5;
      @include transition(0.2s);
    }
  }

  .choose-day-container{
    padding-top: 10px;
    position: relative;
    margin: auto;

    .close-btn{
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .submit-times-btn-container{
    margin: 10px;
    opacity: 0;
    @include transition(1s);
    height: 0;
    &.show{
      height: auto;
      opacity: 1;
      @include transition(1s);
    }
  }
}

.time-slot {
  text-align: center;
  width: 80px;
  height: 70px;
  border-radius: 6px;
  margin: 5px;

  @media screen and (max-width: 425px) {
    margin: 2px;
  }

  background-color: $inverted-text-color;
  color: $highlight-color;
  border: 1px solid $highlight-color;
  font-weight: bold;
  @include transition(0.2s);

  display: inline-block;
  vertical-align: middle;

  cursor: pointer;

  &:hover, &.has-selection {
    background-color: $highlight-color;
    color: $inverted-text-color;
    @include transition(0.2s);
  }

  .time-slot-label {
    display: inline-block;
    vertical-align: middle;
    line-height: 70px;
    font-size: 14px;

    cursor: pointer;

    span{
      display: block;
    }
  }

  .time-slot-label-time{
    font-size: 16px;
  }

  &.has-selection {
    .time-slot-label{
      line-height: 23px;
    }
  }
}