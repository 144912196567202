.landing-pricing-container{
  text-align: center;
  margin-bottom: 100px;

  .landing-pricing-plan{
    display: inline-block;
    vertical-align: top;
    padding: 15px 60px 15px 60px;

    .landing-pricing-content{
      height: 370px;
    }

    .landing-pricing-detail{
      line-height: 160%;
      font-size: 1.1em;
    }
  }

  .landing-pricing-cost{
    h2 {
      color: $highlight-color;
    }
  }

  .landing-pricing-button{
    .btn{
      width: 130px;
      height: 38px;

      &.selected-plan{
        background-color: $inverted-text-color;
        color: $highlight-color;
      }
    }
  }

  .landing-pricing-trial-length{
    margin-top: 15px;
  }
}