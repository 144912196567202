.renter-application-modal-container{
  .modal-header{
    h4 {
      text-align: center;
      color: $highlight-color;
    }

    border-bottom: none;
    padding: 10px;
  }

  .modal-body{
    padding-top: 0;
  }

  .renter-application-instructions{
    text-align: center;
    font-weight: bold;
    font-size: 12px;
  }

  .renter-application-send-button{
    margin-top: 20px;
    text-align: center;
  }
}