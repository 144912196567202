.template-tab-container {
  button{
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  p {
    margin-top: 10px;
  }

  h5{
    color: $highlight-color;
  }

  .sub-header {
    height: 45px;
    background-color: $background-color;
    color: $highlight-color;
    font-size: 18px;

    i {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  div.template-edit-container {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 100px;

    h5 {
      margin-top: 35px;
    }

    .target {
      display: none
    }

    .expand-toggle {
      cursor: pointer;

      i {
        font-size: 18px;
        position: relative;
        top: 2px;
      }
    }

    .sub-header {
      margin-top: 30px;
    }

    .preview-link,
    .character-counter {
      font-size: 10px;
      color: $lighter-text-color;
      float: right;
    }

    .preview-link {
      color: $highlight-color;
      margin-right: 5px;
      cursor: pointer;
    }

    .static-text-container,
    .radio-button-container{
      border: $border-color 1px solid;

      .select-container {
        width: 200px;
        margin-left: 10px;
        margin-bottom: 15px;

        md-select {
          margin: 0;
        }
      }
    }

    .static-text-container {
      background-color: #F2F2F2;
      color:$lighter-text-color;
      font-size:14px;

      .static-text {
        margin: 10px;
        line-height: 23px;

        .static-variable {
          border-radius: 7px;
          background-color: $fade-color;
          padding: 2px 4px 2px 4px;
        }
      }
    }

    textarea {
      overflow: auto;
      color:$lighter-text-color;
      font-size:14px;
      resize: none;
      padding:10px;
      width:100%;;
      border:$border-color 1px solid;
      font-family: sans-serif;
      @include transition(all 0.25s ease-in-out);
        &:focus{
          color: $lighter-text-color;
          outline: none;
          border: $highlight-color 1px solid;
          font-family: sans-serif;
          box-shadow: 0 0 5px $highlight-color;
        }
    }

    .update-template-button {
      margin-top: 20px;
    }
  }
}
