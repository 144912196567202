.user-info-container{
  font-size: 12px;
  margin-right: 0;

  > div {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
  }

  a{
    cursor: pointer;
  }

  .user-image{
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
  }

  .user-info{
    .user-name{
      font-weight: bold;
      font-size: 12px;
    }
  }

  .chat-box-container{
    textarea{
      resize: none;
      width: 180px;
      height: 60px
    }

    button{
      margin-top: 10px;
      width: 180px;
    }
  }
}