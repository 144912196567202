.stream-carousel-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: $z-top;

  background-color: rgba(0, 0, 0, 0.5);

  .stream-carousel-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .stream-carousel-position {
    width: 80px;
    height: 22px;
    color: $inverted-text-color;
    background-color: $alternate-highlight-color;
    border-radius: 6px;
    text-align: center;
    margin: 4px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stream-carousel-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 30px;
    text-align: center;
    color: $inverted-text-color;

    &:hover {
      color: darken($inverted-text-color, 10%);
      cursor: pointer;
    }
  }

  .stream-carousel-arrow {
    width: 45px;
    position: absolute;
    color: $inverted-text-color;
    font-size: 36px;
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.stream-carousel-arrow-left {
      left: 0;
      top: 0;
      bottom: 0;
    }

    &.stream-carousel-arrow-right {
      right: 0;
      top: 0;
      bottom: 0;
    }

    .fa {
      &:hover {
        color: darken($inverted-text-color, 10%);
        cursor: pointer;
      }
    }
  }

  .stream-carousel-transition-wrapper {
    @include transition(opacity .25s);
    opacity: 1;

    &.stream-carousel-transition {
      opacity: 0;
      @include transition(opacity .25s);
    }
  }

  .stream-carousel-wrapper {
    background-color: $inverted-text-color;
    position: absolute;
    top: 31px;
    left: 45px;
    bottom: 15px;
    right: 45px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.stream-carousel-wrapper-single-stream {
      top: 15px;
      left: 15px;
      bottom: 15px;
      right: 15px;
    }

    .stream-carousel-loading {
      font-size: 14px;
    }

    .stream-carousel-error {
      color: $danger-color;
    }

    .user-interactions-panel-container {
      left: 320px;
      border-left: 1px solid $border-color;
    }

    .user-details-form {
      position: absolute;
      top: 59px;
      left: 0;
      bottom: 0;
      width: 320px;
    }

    .stream-carousel-close-row {
      position: absolute;
      top: 0;
      left: 0;
      height: 60px;
      right: 320px;
      background-color: $inverted-text-color;
      border-bottom: 1px solid $border-color;

      a {
        color: $highlight-color;
        font-size: 16px;
        position: relative;
        top: 4px;

        .fa-times {
          font-size: 22px;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}
