.user-details-form {
  width: 325px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
  position: absolute;
  top: -1px;
  bottom: 0;
  left: 0;

  .short-row {
    padding-bottom: 0;
    padding-top: 0;
  }

  .layout-padding {
    padding: 5px 8px;
  }

  .md-button {
    min-height: 28px;
    max-height: 28px;
    line-height: 28px;
  }

  .user-details-row {
    padding-left: 15px;

    .link {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: #4A61E8;
      margin: 8px 5px;
      display: inline-block;
      vertical-align: middle;
    }

  }

  .user-details-row {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .caller-id-container {

    /* Selects every div child of this container except the last one. */
    div:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .export-failure-display {
    padding-left: 15px;

    .title {
      font-weight: bold;
      color: $danger-color;
    }

    .message {
      font-style: italic;
    }
  }

  /* accordion components */
  .panel-group {

    .panel {
      margin: 0;
      border-radius: 0;
      border-color: white;
      border-top: $slate-50 solid;
      &:last-child {
        border-bottom: $slate-50 solid;
      }
    }

    .panel-heading {
      background-color: $white;
      font-size: 14px;
      color: $slate-600;

      border-color: white;
      border-radius: 0;

      .panel-title {
        a { 
          text-decoration: none;
        }
        .accordion-toggle {
          display: flex;
          align-items: center;

          .arrow {
            margin-right: 10px;
            transition: all 0.25s ease-in-out;
            transform: rotateZ(-90deg);
            &.open {
              transform: rotateZ(0);
            }
          }

          .icon {
            margin-right: 8px;
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .panel-collapse {
      transition: all 0.35s ease-in-out;
      .panel-body {
        border-color: white;
      }
    }

  } /* panel-group */

  .input-warning {
    input {
      border-bottom-color: #FFBB33;
    }
  }

  .input-warning.md-input-focused {
    input {
      border-bottom-color: #FFBB33;
    }
    label {
      color: #FFBB33;
    }
  }

  .warning-text {
    color: #535353;
    font-size: 11px;
    padding-top: 4px;
    padding-bottom: 2px;
  }
}

.conversation-modal-wrapper {
  .user-details-form {
    top: 50px;
    width: 320px;
  }
}

.input-icon-right {
  margin-left: 4px;
  margin-bottom: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 8px;
  top: 5px;
  left: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #839AE3;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: -3px;
  background-color: #4A61E8;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #839AE3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #839AE3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.slider.round {
  border-radius: 24px;
}

.slider.inactive {
  background-color: grey;
}

.slider.disabled {
  cursor: not-allowed;
  background-color: #eeeeef;
}

.slider.inactive:before {
  background-color: rgb(158, 158, 158);
}

.slider.disabled:before {
  background-color: #bdbdbd;
}
.slider.round:before {
  border-radius: 50%;
}

.profile-view-prospect-link-container {
  padding: 19px 5px 0;
}
