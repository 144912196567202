.generate-unit-quote-modal-container{
  .quote-label-container{
    font-size: 14px;
    display: flex;

    .quote-label{
      min-width: 105px;
    }
  }
}
