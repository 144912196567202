.audio-player-container {
  width: 250px;

  .fa-play,
  .fa-pause {
    position: relative;
    bottom: 6px;
    right: 4px;
  }

  .fa-pause {
    right: 6px;
  }
}
