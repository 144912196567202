.listing-index-row {
  md-divider {
    margin-bottom: 10px;
  }

  .listing-index-row-details {
    .md-title {
      margin-right: 15px;
      font-size: 18px;
    }
    .md-subhead {
      color: $lighter-text-color;
      font-size: 16px;
    }

    .location-warning {
      color: $danger-color
    }
  }

  .listing-functions {
    max-height: 40px;
    position: relative;
    right: 13px;
  }

  .short-link {
    margin: 5px 0;
    a {
      text-decoration: none;

      &:hover {
        md-icon {
          color: $highlight-color;
        }
      }
    }

    span {
      color: $highlight-color;
      font-size: 14px;
    }

    md-icon {
      margin: 0 5px 0 5px;
      font-size: 16px;
      height: 16px;
    }

    .copy-links {
      margin-left: 10px;
      position: relative;
      top: 2px;

      .is-not-copy-link {
        cursor: default;

        &:hover {
          color: $highlight-color;
        }
      }

      .copied-message {
        font-size: 12px;
      }
    }
  }

  .property-image {
    position: relative;
    cursor: pointer;
  }

  .listing-button-container {
    position: relative;
  }

  .badges-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .listing-status-select {
    position: relative;
    top: 4px;
  }

  .button-badge {
    top: 2px;
    position: relative;

    &.prime-time-badge {
      background-color: #2EB88F;
    }

    &.occupant-badge {
      background-color: $bright-highlight-color;
    }

    &.notes-badge {
      background-color: $alternate-highlight-color;
    }

    &.pending-badge {
      background-color: #ECE821;
      cursor: default;
    }
   }
}

.listing-stats-container {
  background-color: $background-color;
  padding: 20px;
  border: 1px solid $border-color;
  margin-right: 10px;
}
